import { createAction } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';
import {
  ALERTS_REQUEST,
  ALERT_SET_ACTIVE,
  ALERT_CLEAR_ACTIVE,
  ACTIVITIES_REQUEST,
  AUTOMATED_ALERTS_REQUEST,
} from './constants';

export const alertsRequest = createFormAction(ALERTS_REQUEST);
export const automatedAlertsRequest = createFormAction(AUTOMATED_ALERTS_REQUEST);
export const activitiesRequest = createFormAction(ACTIVITIES_REQUEST);
export const alertSetActive = createAction(ALERT_SET_ACTIVE);
export const alertClearActive = createAction(ALERT_CLEAR_ACTIVE, page => ({ page }));
