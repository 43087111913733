import { fromJS } from 'immutable';
import jstz from 'jstimezonedetect';
import update from 'immutability-helper';
import { isPlainObject } from 'lodash-es';
import deepmerge from 'deepmerge';
import { handleActions, combineActions } from 'redux-actions';
import { DEFAULT_LOCALE } from 'Containers/LanguageProvider/constants';
import changeLanguage from 'Containers/LanguageProvider/actions';
import {
  filterClearActive,
  filtersClearActive,
  filtersSetActive,
} from 'Containers/FiltersContainer/actions';
import { pageState } from 'Containers/PageContainer/reducer';
import { mergeDeepOverwriteLists } from 'Utils/immutable';
import updateObject from 'Utils/updateObject';
import * as pageActions from 'Containers/PageContainer/actions';
import { appDataInfoSuccess, appDataInfoFail, appStateClear } from './actions';

export const initialState = {
  activeFilters: fromJS({}),
  userSettings: {
    timeZone: jstz.determine().name(),
    timeFormat: {
      // format: 'MMMM DD, YYYY @ HH:mm',
      hour12: false,
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    },
    language: DEFAULT_LOCALE,
  },
  page: pageState,
  dataInformation: [{
    countries: [],
    fromDate: '',
    toDate: '',
    dataSource: '',
    page:'',
    validationNotPassedMonths: [],
  }],
};

const appStateReducer = handleActions({
  /* App */
  [appStateClear]: () => initialState,
  /* Filters */
  [filtersSetActive]: (state, { payload: { filters } }) => updateObject(state, {
    activeFilters: mergeDeepOverwriteLists(state.activeFilters, fromJS(filters)),
  }),
  [filtersClearActive]: state => updateObject(state, { activeFilters: fromJS({}) }),
  [filterClearActive]: (state, { payload }) => updateObject(state, {
    activeFilters: state.activeFilters.deleteAll([].concat(payload)),
  }),
  /* Language */
  [changeLanguage]: (state, { payload: language }) => update(state, {
    userSettings: { $merge: { language } },
  }),
  // Page
  [pageActions.pageClear]: state => updateObject(state, { page: pageState }),
  [pageActions.pagePropsUpdate]: (state, { payload: { payload } }) => update(state, {
    page: { $set: deepmerge(state.page, payload, {
      isMergeableObject: isPlainObject,
    }) },
  }),
  [pageActions.pageParamsUpdate]: (state, { payload: { payload } }) => update(state, {
    page: { params: { $merge: updateObject(payload) } },
  }),
  [pageActions.pageParamsClear]: state => update(state, {
    page: { params: { $set: pageState.params } },
  }),
  [combineActions(pageActions.pageSettingsUpdate, pageActions.pageSettingsSave)]:
    (state, { payload: { data } }) => update(state, {
      page: { settings: { $set: data } },
    }),
  [pageActions.pageSettingsClear]: state => update(state, {
    page: { settings: { $set: pageState.settings } },
  }),
  // Info
  [appDataInfoSuccess]: (state, { payload: { data } }) => updateObject(state, {
    dataInformation: data,
  }),
  [appDataInfoFail]: state => updateObject(state, {
    dataInformation: initialState.dataInformation,
  }),
}, initialState);

export default appStateReducer;
