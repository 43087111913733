import { addLocaleData } from 'react-intl';
import { reduce } from 'lodash-es';
import enLocaleData from 'react-intl/locale-data/en';
import roLocaleData from 'react-intl/locale-data/ro';

import enTranslationMessages from './translations/en.json';
import roTranslationMessages from './translations/ro.json';

export const appLocales = [
  'en',
  'ro',
];

addLocaleData(enLocaleData);
addLocaleData(roLocaleData);

const flatten = (list = {}, path = '', flattenedAcc = {}) => reduce(list, (acc, value, key) => {
  if (value instanceof Object && !Array.isArray(value)) {
    return flatten(value, `${path}${key}.`, acc);
  }
  acc[path + key] = value;
  return acc;
}, flattenedAcc);

export const translationMessages = {
  en: flatten(enTranslationMessages),
  ro: flatten(roTranslationMessages),
};
