import { handleActions } from 'redux-actions';
import { GDPRModalClose, GDPRModalOpen } from './actions';

export const initialState = {
  open: false,
};

const GDPRModalReducer = handleActions({
  [GDPRModalOpen]: () => ({ open: true }),
  [GDPRModalClose]: () => ({ open: false }),
}, initialState);

export default GDPRModalReducer;
