import { createSelector } from 'reselect';
import {
  makeSelectActivePage,
  makeSelectPageActiveView,
  makeSelectCurrentLocation,
} from 'Containers/App/selectors';
import { pageState } from './reducer';

const selectPageContainerDomain = state => state.pagesData;

const makeSelectPage = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location] || pageState
);

export const makeSelectPageName = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.name || pageState.name
);

export const makeSelectPageTitle = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.title || pageState.title
);

export const makeSelectPageDataInformation = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.dataInformation || pageState.dataInformation
);

export const makeSelectPageCanCreateAlert = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.canCreateAlert || pageState.canCreateAlert
);


export const makeSelectPagePath = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.path || location
);

export const makeSelectPageFormaters = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.formaters || pageState.formaters
);

export const makeSelectPageData = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.data || pageState.data
);

export const makeSelectPageChartData = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.chartData || pageState.chartData
);

export const makeSelectTableData = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.tableData || pageState.tableData
);

export const makeSelectTopVisualData = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.topVisualData || pageState.topVisualData
);

export const makeSelectPageFilters = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.filters || pageState.filters
);

export const makeSelectLocalFilters = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.localFilters || pageState.localFilters
);

export const makeSelectPageFilter = key => createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.filters?.get(key, pageState.filters) || pageState.filters
);

export const makeSelectPageSettings = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.settings || pageState.settings
);

export const makeSelectPageBreadcrumbs = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.breadcrumbs || pageState.breadcrumbs
);

export const makeSelectPageParams = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.params || pageState.params
);

export const makeSelectPageViews = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.views || pageState.views
);

export const makeSelectPageScenarios = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.scenarios || pageState.scenarios
);

export const makeSelectActiveAlert = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.activeAlert || pageState.activeAlert
);

export const makeSelectActiveView = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  makeSelectPageActiveView,
  makeSelectActivePage,
  (location, page, lastActiveView, lastActivePage) => {
    if (!(location in page)) {
      return null;
    }
    const { activeView = pageState.activeView } = page[location];
    if (activeView) {
      return activeView;
    }
    if (
      lastActiveView
      && lastActivePage.key === page[location].key
      && lastActiveView?.data?.child === page[location].key
    ) {
      return lastActiveView;
    }
    return activeView;
  }
);

export const makeSelectActiveScenario = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.activeScenario || pageState.activeScenario
);

export const makeSelectPageLogicInfo = createSelector(
  makeSelectCurrentLocation,
  selectPageContainerDomain,
  (location, page) => page[location]?.pageLogic || pageState.pageLogic
);

export const makePendingRegisterUsers = createSelector(
  selectPageContainerDomain,
  state => {
    return state.pendingRegisterUsers
  }
);


export default makeSelectPage;
