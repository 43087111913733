export const API_PATH = '/api/data/getCharts';
export const API_EXPORT_CHART_PATH = '/api/data/getChartDownload';
export const API_EXPORT_PAGE_CHART_PATH_EXCEL = '/api/data/exportCharts';
export const API_EXPORT_PAGE_CHART_PATH_PDF = '/api/data/exportPdfCharts';

export const DATA_REQUEST = 'app/ChartContainer/CHART_DATA_REQUEST';
export const DATA_SUCCESS = 'app/ChartContainer/CHART_DATA_SUCCESS';
export const DATA_FAIL = 'app/ChartContainer/CHART_DATA_FAIL';

export const CHART_TYPE_DEFAULT = 'PIE_CHART';
export const CHART_TYPE_PIE = CHART_TYPE_DEFAULT;
export const CHART_TYPE_BAR_VERTICAL = 'VERTICAL_BAR_CHART';
export const CHART_TYPE_BAR_HORIZONTAL = 'HORIZONTAL_BAR_CHART';
export const CHART_TYPE_BAR_NEGATIVE_HORIZONTAL = 'NEGATIVE_HORIZONTAL_BAR_CHART';
export const CHART_TYPE_BUBBLE = 'BUBBLE_CHART';
export const CHART_TYPE_LINE = 'LINE_CHART';
export const CHART_TYPE_DOUBLE_DOUGHNUT = 'DOUBLE_DOUGHNUT_CHART';
export const CHART_TYPE_BAR_STACK = 'BAR_STACK_CHART';
export const CHART_TYPE_SUNBURST = 'SUNBURST_CHART';
export const CHART_TYPE_MIXBAR = 'MIX_BAR_CHART';

export const CHART_CLASS_NAMES = {
  [CHART_TYPE_PIE]: 'PieChart',
  [CHART_TYPE_BAR_VERTICAL]: 'BarChart BarChart--Vertical',
  [CHART_TYPE_BAR_HORIZONTAL]: 'BarChart BarChart--Horizontal',
  [CHART_TYPE_BAR_NEGATIVE_HORIZONTAL]: 'BarChart BarChart--Negative',
  [CHART_TYPE_BUBBLE]: 'BubbleChart',
  [CHART_TYPE_LINE]: 'LineChart',
  [CHART_TYPE_DOUBLE_DOUGHNUT]: 'DoubleDoughnutChart',
  [CHART_TYPE_BAR_STACK]: 'BarStackChart',
  [CHART_TYPE_SUNBURST]: 'SunburstChart',
  [CHART_TYPE_MIXBAR]: 'MixBarChart',
};
