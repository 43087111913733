import { defineMessages } from 'react-intl';

export default defineMessages({
  notifications: {
    data: {
      request: {
        error: {
          title: {
            id: 'app.components.Notifications.messages.ViewsComponent.data.request.error.title',
            defaultMessage: 'Views request failed',
          },
          body: {
            id: 'app.components.Notifications.messages.ViewsComponent.data.request.error.body',
            defaultMessage: `There was an error fetching the views for { page }. Please try again or 
            contact an administrator if the issue persists`,
          },
        },
      },
    },
    setActiveView: {
      title: {
        id: 'app.components.Notifications.messages.ViewsComponent.removeActiveView.title',
        defaultMessage: 'View { name } set as active.',
      },
    },
    removeActiveView: {
      title: {
        id: 'app.components.Notifications.messages.ViewsComponent.setActiveView.title',
        defaultMessage: 'De-activated view { name }.',
      },
    },
  },
});
