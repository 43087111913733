import React from 'react';
import { FormattedMessage } from 'react-intl';
import { stringify } from 'qs';
import { takeEvery, select, put, call, fork, takeLatest } from 'redux-saga/effects';
import fetch from 'Utils/fetch';
import { replacePageURLKey } from 'Utils/constants';
import logger from 'Utils/logger';
import { makeSelectPageName } from 'Containers/PageContainer/selectors';
import { makeSelectCurrentLocation } from 'Containers/App/selectors';
import showNotification from 'Components/ToastComponent/actions';
import { API_PATH, DATA_REQUEST, DATA_SUCCESS, DATA_FAIL } from './constants';
import messages from './messages';

function* fetchData(API_PATH, { params }, page) {
/* it used to send all data in query string and it turned out we got 414 error sometimes if we sent too long string
 * so we planned to 
 * 1. move request data from query string to request body
 * 2. request method from GET to POST
 * */
    const { filters, page:pg, scenario, ...rest } = params;
    const bodyData = { ...rest, page: replacePageURLKey(pg), filters: JSON.stringify(filters) ,scenario: JSON.stringify(scenario) };
    const data = yield call(
          fetch, 
          API_PATH, 
          { 
            method: 'POST', 
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'page': bodyData.page
            },
            body: stringify(bodyData)  
          });
    if (data) {
      yield put({ type: DATA_SUCCESS, payload: { page, data } });
    }
}

function* getComponentData({ payload: params }) {
  let { page } = params;
  if (!page) {
    page = yield select(makeSelectCurrentLocation);
  }
  try {
    yield fork(fetchData, API_PATH, { params }, page);
  } catch (error) {
    const name = yield select(makeSelectPageName);
    yield put(showNotification({
      title: <FormattedMessage { ...messages.notifications.dataRequest.error.title } />,
      body: (
        <FormattedMessage
          { ...messages.notifications.dataRequest.error.body }
          values={{
            page: <strong>{ name }</strong>,
          }}
        />
      ),
      timeout: 5000,
      kind: 'error',
    }));
    yield put({ type: DATA_FAIL, payload: { page, error } });
    logger.error(error);
  }
}

export default function* defaultSagas() {
  yield takeEvery(DATA_REQUEST, getComponentData);
}

export const topVisualSaga = [
  takeLatest(DATA_REQUEST, getComponentData),
];
