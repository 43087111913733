import '!style-loader!css-loader!carbon-components/css/carbon-components.min.css';
import '!style-loader!css-loader!Styles/components/tabs.css';
import '!style-loader!css-loader!Styles/components/loader.css';
import './global-styles';

import React from 'react';
import { render as ReactDOMRender } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import deepmerge from 'deepmerge';
import { isPlainObject } from 'lodash-es';
import createHistory from 'history/createBrowserHistory';
import FontFaceObserver from 'fontfaceobserver';
import formActionSaga from 'redux-form-saga';
import '@babel/polyfill';
import 'whatwg-fetch';

import App from 'containers/App';
import LanguageProvider from 'Containers/LanguageProvider';
import updateObject from 'Utils/updateObject';
import KeycloakService from './services/KeycloakService';
import { LOCAL_STORAGE_KEY } from 'Utils/constants';
import { translationMessages } from './i18n';
import configureStore from './store/configureStore';
import initialState from './store/initialState';
import rootSaga from './sagas';

// TODO: remove this in favour of webpack favicon loader and webpack manifest loader !
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import '!file-loader?name=[name].[ext]!./images/icon-72x72.png';
import '!file-loader?name=[name].[ext]!./images/icon-96x96.png';
import '!file-loader?name=[name].[ext]!./images/icon-128x128.png';
import '!file-loader?name=[name].[ext]!./images/icon-144x144.png';
import '!file-loader?name=[name].[ext]!./images/icon-152x152.png';
import '!file-loader?name=[name].[ext]!./images/icon-192x192.png';
import '!file-loader?name=[name].[ext]!./images/icon-384x384.png';
import '!file-loader?name=[name].[ext]!./images/icon-512x512.png';
import './manifest.json';
/* eslint-enable import/no-unresolved, import/extensions */

const IBMPlexFontObserver = new FontFaceObserver('ibm-plex-sans', {
  weight: 400,
  style: 'normal',
});

// When font ready, add a class to the body
IBMPlexFontObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
}, () => {
  console.warn('Cant load IBM Plex Sans fonts');
  document.body.classList.remove('fontLoaded');
});

// Create redux store with history, initial state and run the sagas
const history = createHistory();
const store = configureStore(
  deepmerge(
    initialState, updateObject(JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY))), {
      isMergeableObject: isPlainObject
    } // initial state
  ),
  history
);
store.runSaga(rootSaga);
store.runSaga(formActionSaga);

const render = messages => {
  ReactDOMRender(
    <Provider store={ store }>
      <LanguageProvider messages={ messages }>
        <ConnectedRouter history={ history }>
          <App />
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>,
    document.getElementById('app-root')
  );
};

if (module.hot) {
  // Hot reloadable translation json files
  module.hot.accept('./i18n', () => {
    KeycloakService.initKeycloak(()=>{
      render(require('./i18n').translationMessages)
      });
    //render(require('./i18n').translationMessages); // eslint-disable-line global-require
  });
}

// Chunked polyfill for browsers without Intl support
/* eslint-disable */
if (!window.Intl) {
  (new Promise(resolve => {
    resolve(require('intl'));
  }))
    .then(() => Promise.all([
      require('intl/locale-data/jsonp/en.js'),
      require('intl/locale-data/jsonp/ro.js'),
    ]))
    .then(() => KeycloakService.initKeycloak(()=>{
      render(translationMessages)
      }))
    .catch(err => {
      throw err;
    });
} else {
  //render(translationMessages);
  KeycloakService.initKeycloak(()=>{
    render(translationMessages)
    })
}
/* eslint-enable */
