import { createAction } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';
import {
  APP_DATA_INFO_REQUEST,
  APP_DATA_INFO_SUCCESS,
  APP_DATA_INFO_FAIL,
  APP_STATE_CLEAR,
} from './constants';

export const appDataInfoRequest = createAction(APP_DATA_INFO_REQUEST);
export const appDataInfoSuccess = createAction(APP_DATA_INFO_SUCCESS);
export const appDataInfoFail = createAction(APP_DATA_INFO_FAIL);
export const appStateClear = createAction(APP_STATE_CLEAR);
