import { createAction } from 'redux-actions';
import {
  MODAL_OPEN,
  MODAL_CLOSE,
  MODAL_STATE_UPDATE,
  MODAL_TYPE_SET,
  MODAL_STATE_CLEAR,
  MODAL_SCENARIO_UPDATE,
} from './constants';

export const scenarioModalUpdate = createAction(MODAL_SCENARIO_UPDATE);
export const scenarioModalOpen = createAction(MODAL_OPEN);
export const scenarioModalStateUpdate = createAction(MODAL_STATE_UPDATE);
export const scenarioModalStateClear = createAction(MODAL_STATE_CLEAR);
export const scenarioModalTypeSet = createAction(MODAL_TYPE_SET);
export const scenarioModalClose = createAction(MODAL_CLOSE);
