import { createStore, applyMiddleware } from 'redux';
import { connectRouter, routerMiddleware, LOCATION_CHANGE } from 'connected-react-router';
import { createMiddleware, createLoader } from 'redux-storage';
import createEngine from 'redux-storage-engine-localstorage';
import debounce from 'redux-storage-decorator-debounce';
import filter from 'redux-storage-decorator-filter';
import update from 'immutability-helper';
import { fromJS } from 'immutable';
import createSagaMiddleware, { END } from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import createReducer from 'Reducers';
import { LOCAL_STORAGE_KEY } from 'Utils/constants';
import updateObject from 'Utils/updateObject';
import logger from 'Utils/logger';

const sagaMiddleware = createSagaMiddleware({
  sagaMonitor: {
    actionDispatched(action) {
      if (
        process.env.NODE_ENV === 'production'
        || action.type === '@@react-redux-ui-tools/UNMOUNT_UI_STATE'
      ) return;
      logger.log(action);
    },
  },
  onError: error => {
    logger.error(`__SAGA__ ${error}`);
  },
});
const storageEngine = (() => {
  const engine = debounce(
    filter(
      createEngine(LOCAL_STORAGE_KEY),
      [ // whitelist
        ['auth', 'loggedIn'],
        ['user', 'email'],
        'ui',
        ['appState', 'userSettings'],
        ['appState', 'activeFilters'],
        ['appState', 'page', 'path'],
        ['appState', 'page', 'name'],
        ['appState', 'page', 'settings'],
        ['appState', 'page', 'params'],
      ],
    ),
    1000 // 1s debounce.
  );

  return {
    ...engine,

    load() {
      return engine.load().then(result => {
        if (!('appState' in result)) {
          return result;
        }
        return update(result, {
          appState: {
            activeFilters: { $set: fromJS(updateObject(result.appState.activeFilters)) },
          },
        });
      });
    },
  };
})();
const localStorageMiddleware = createMiddleware(storageEngine, [LOCATION_CHANGE]);

/**
 * This will create the enhanced Redux Store
 * @param initialState {Map|Object}
 * @param history {History}
 * @returns {Object} - Store<Reducer, State, Enhancer>
 */
export default function configureStore(initialState, history) {
  /** Redux middleware collection
   * 1. sagaMiddleware: Makes redux-sagas work
   * 2. storageMiddleware: Syncs store with local storage
   * 3. routerMiddleware: Syncs state with window.location
   */
  const middlewares = [
    sagaMiddleware,
    localStorageMiddleware,
    routerMiddleware(history),
  ];

  const enhancers = [
    applyMiddleware(...middlewares),
  ];

  const composeEnhancers = composeWithDevTools({
    shouldHotReload: false,
  });

  /**
   * App store contains:
   * a. App reducers (routerReducer, languageReducer, authReducer etc)
   * b. Initial application state
   * c. Other store enhancers: (ReduxDevtoolsExtension, Redux-Saga, routerMiddleware)
   */
  const store = createStore(
    connectRouter(history)(createReducer()),
    initialState,
    composeEnhancers(...enhancers),
  );

  // Load the Store with data from localStorage
  createLoader(storageEngine)(store);

  // Store Extensions. we need to call runSaga with our Sagas list in order to register them
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry
  store.closeSaga = () => store.dispatch(END); // Dispatches @@redux-saga/CHANNEL_END event

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('../reducers', () => {
      store.replaceReducer(connectRouter(history)(createReducer(store.injectedReducers)));
    });
  }

  return store;
}
