// User Roles
export const ADMIN_USER_ROLE = 'ADMIN';
export const ALL_USER_ROLE = 'ALL';
export const HOTEL_USER_ROLE = 'HOTEL';
export const HOTELMATCH_USER_ROLE = 'HMATCH';
export const AIR_USER_ROLE = 'AIR';
export const CAR_USER_ROLE = 'CAR';
export const GROUND_USER_ROLE = 'GT';
export const ADMIN_ALL_USER_ROLES = [ADMIN_USER_ROLE].concat([ALL_USER_ROLE]);
export const HOTELS_USER_ROLES = [HOTEL_USER_ROLE].concat([HOTELMATCH_USER_ROLE]);
export const ALL_ROLES = [].concat(
  ADMIN_ALL_USER_ROLES,
  HOTELS_USER_ROLES,
  AIR_USER_ROLE,
  CAR_USER_ROLE,
  GROUND_USER_ROLE
);

// Globals
export const DEFAULT_CURRENCY = 'USD';
export const currencyMap = [
  ['$', 'USD'],
  ['€', 'EUR'],
  ['£', 'GBP'],
  ['CNY¥', 'CNY'],
  ['JPY¥', 'JPY'],  
  ['A$', 'AUD'], 
  ['₹', 'INR'], 
];
export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

// UI
export const UI_KEY = 'APP_UI';
export const IS_NAVIGATION_OPEN = 'app/IS_NAVIGATION_OPEN';
export const APP_CONTAINER = 'app/APP_CONTAINER';
export const DRAWER_WIDTH = 200;

// STORAGE
export const LOCAL_STORAGE_KEY = 'IBM_TM';

//ISAM
export const ISAM_URL_KEY = '/itm';
export const ISAM_KEY = 'itm';

export const replacePageURLKey = (page) => page.replace(`${ISAM_URL_KEY}`, '');

export const redColors = ['#5C0011', '#820014', '#A8071A', '#CF1322', '#F5222D', '#FF4D4F', '#FF7875', '#FFA39E', '#FFCCC7', '#FFF1F0']

export const greenColors = ['#092b00', '#135200', '#237804', '#389E0D', '#52C41A', '#73D13D', '#95DE64', '#B7EB8F', '#D9F7BE', '#F6FFED']
