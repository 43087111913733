import React from 'react';
import { FormattedMessage } from 'react-intl';
import { all, select, takeLatest, call, put } from 'redux-saga/effects';
import fetch from 'Utils/fetch';
import logger from 'Utils/logger';
import showNotification from 'Components/ToastComponent/actions';
import { makeSelectCurrentLocation } from 'Containers/App/selectors';
import { makeSelectPageName } from 'Containers/PageContainer/selectors';
import { API_SCENARIO_PATH, API_OPTIONS_PATH } from './constants';
import { scenarioOptionsRequest, scenariosTypeRequest } from './actions';
import messages from './messages';

function* getScenariosByType({ payload: type }) {
  const page = yield select(makeSelectCurrentLocation);
  try {
    const data = yield call(fetch, API_SCENARIO_PATH, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'page': '/api/scenarios/getScenariosByType'
      },
      params: { type: type || page },
    });
    yield put(scenariosTypeRequest.success({ page: type || page, data }));
  } catch (error) {
    const name = yield select(makeSelectPageName);
    yield put(showNotification({
      title: <FormattedMessage { ...messages.notifications.dataRequest.error.title } />,
      body: (
        <FormattedMessage
          { ...messages.notifications.dataRequest.error.body }
          values={{ name: <strong>{ name }</strong> }}
        />
      ),
      kind: 'error',
    }));
    yield put(scenariosTypeRequest.failure({ page: type, error }));
    logger.error(`Error requesting scenarios for type ${type} due to ${error}`);
  }
}

function* getScenarioOptions({ payload: params }) {
  try {
    const data = yield call(fetch, API_OPTIONS_PATH, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'page': '/api/scenarios/getScenarioOptions'
      },
      params,
    });
    yield put(scenarioOptionsRequest.success(data));
  } catch (error) {
    const name = yield select(makeSelectPageName);
    yield put(scenarioOptionsRequest.failure(error));
    // yield put(showNotification({
    //   title: <FormattedMessage { ...messages.notifications.optionsRequest.error.title } />,
    //   body: (
    //     <FormattedMessage
    //       { ...messages.notifications.optionsRequest.error.body }
    //       values={{ name: <strong>{ name }</strong> }}
    //     />
    //   ),
    //   kind: 'error',
    // }));
    logger.error(`Scenario options failed for page ${name} due to ${error}`);
  }
}

export default function* scenariosSaga() {
  yield all([
    takeLatest(scenariosTypeRequest.REQUEST, getScenariosByType),
    takeLatest(scenarioOptionsRequest.REQUEST, getScenarioOptions),
  ]);
}

export const scenarioSagas = [
  takeLatest(scenariosTypeRequest.REQUEST, getScenariosByType),
  takeLatest(scenarioOptionsRequest.REQUEST, getScenarioOptions),
];
