import { defineMessages } from 'react-intl';

export const paginationMessages = defineMessages({
  itemsPerPage: {
    id: 'app.components.DataTable.Pagination.itemsPerPage',
    defaultMessage: 'Items per page',
  },
  of: {
    id: 'app.components.DataTable.Pagination.of',
    defaultMessage: 'of',
  },
  items: {
    id: 'app.components.DataTable.Pagination.items',
    defaultMessage: 'items',
  },
  pages: {
    id: 'app.components.DataTable.Pagination.pages',
    defaultMessage: 'pages',
  },
});

export default defineMessages({
  placeholder: {
    id: 'app.components.DataTable.placeholder',
    defaultMessage: '{message}',
  },
  notifications: {
    download: {
      success: {
        title: {
          id: 'app.components.Notifications.messages.DataTable.success.download.title',
          defaultMessage: 'Download Successful',
        },
        body: {
          id: 'app.components.Notifications.messages.DataTable.success.download.body',
          defaultMessage: `Table Data Downloaded Successfully. You can find your table xml file in
          the browsers downloads tab.`,
        },
      },
      error: {
        title: {
          id: 'app.components.Notifications.messages.DataTable.errors.download.title',
          defaultMessage: 'Download Failed',
        },
        body: {
          id: 'app.components.Notifications.messages.DataTable.errors.download.body',
          defaultMessage: `Table Data Download Failed. Please try again or contact an administrator
           if the issue persists`,
        },
      },
    },
    upload: {
      success: {
        title: {
          id: 'app.components.Notifications.messages.DataTable.success.upload.title',
          defaultMessage: 'Upload Successful',
        },
        body: {
          id: 'app.components.Notifications.messages.DataTable.success.upload.body',
          defaultMessage: `Table Data Upload Successfully.`,
        },
      },
      error: {
        title: {
          id: 'app.components.Notifications.messages.DataTable.errors.upload.title',
          defaultMessage: 'Upload Failed',
        },
        body: {
          id: 'app.components.Notifications.messages.DataTable.errors.upload.body',
          defaultMessage: `Table Data Upload Failed.`,
        },
      },
      fileSize: {
        title: {
          id: 'app.components.Notifications.messages.DataTable.fileSize.upload.title',
          defaultMessage: 'Upload Failed',
        },
        body: {
          id: 'app.components.Notifications.messages.DataTable.fileSize.upload.body',
          defaultMessage: `Limit file size { param } M`,
        },
      },
      fileType: {
        title: {
          id: 'app.components.Notifications.messages.DataTable.fileType.upload.title',
          defaultMessage: 'Upload Failed',
        },
        body: {
          id: 'app.components.Notifications.messages.DataTable.fileType.upload.body',
          defaultMessage: `Excel type need`,
        },
      },
    },
    dataRequest: {
      error: {
        title: {
          id: 'app.components.Notifications.messages.DataTable.errors.dataRequest.title',
          defaultMessage: 'Table Failed',
        },
        body: {
          id: 'app.components.Notifications.messages.DataTable.errors.dataRequest.body',
          defaultMessage: `Failed to fetch Table Data for page { page }. Please contact an
          administrator about this issue`,
        },
      },
    },
  },
  analysis: {
    air: {
      advanced: {
        common: {
          changeFee: {
            id: 'app.components.DataTable.analysis.air.advanced.changeFee',
            defaultMessage: 'Change Fee',
          },
          estimatedSpent: {
            id: 'app.components.DataTable.analysis.air.advanced.estimatedSpent',
            defaultMessage: 'Estimated Total Spend',
          },
          grossSavings: {
            id: 'app.components.DataTable.analysis.air.advanced.grossSavings',
            defaultMessage: 'Gross Savings',
          },
          averageTicketPrice: {
            id: 'app.components.DataTable.analysis.air.advanced.averageTicketPrice',
            defaultMessage: 'ATP',
          },
          spend: {
            id: 'app.components.DataTable.analysis.air.advanced.spend',
            defaultMessage: 'Spend',
          },
          tickets: {
            id: 'app.components.DataTable.analysis.air.advanced.tickets',
            defaultMessage: 'Tickets',
          },
          ticketsPercent: {
            id: 'app.components.DataTable.analysis.air.advanced.ticketsPercent',
            defaultMessage: 'Tickets',
          },
          name: {
            id: 'app.components.DataTable.analysis.air.advanced.name',
            defaultMessage: '{ name }',
          },
          netSavings: {
            id: 'app.components.DataTable.analysis.air.advanced.netSavings',
            defaultMessage: 'Net Savings',
          },
          totalSpend: {
            id: 'app.components.DataTable.analysis.air.advanced.totalSpend',
            defaultMessage: 'Total Spend',
          },
          scenario: {
            id: 'app.components.DataTable.analysis.air.advanced.scenario',
            defaultMessage: 'What-If',
          },
        },
      },
      agencyfee: {
        common: {
          countryISO: {
            id: 'app.components.DataTable.analysis.air.agency.countryISO',
            defaultMessage: 'Country ISO',
          },
          countryName: {
            id: 'app.components.DataTable.analysis.air.agency.countryName',
            defaultMessage: 'Country',
          },
          currency: {
            id: 'app.components.DataTable.analysis.air.agency.currency',
            defaultMessage: 'Currency',
          },
          domesticFee: {
            id: 'app.components.DataTable.analysis.air.agency.domesticFee',
            defaultMessage: 'Domestic Fee',
          },
          ONLINE: {
            id: 'app.components.DataTable.analysis.air.agency.ONLINE',
            defaultMessage: 'ONLINE',
          },
          OFFLINE: {
            id: 'app.components.DataTable.analysis.air.agency.OFFLINE',
            defaultMessage: 'OFFLINE',
          },
          domesticNoTouchFee: {
            id: 'app.components.DataTable.analysis.air.agency.domesticNoTouchFee',
            defaultMessage: 'Domestic No Touch Fee',
          },
          internationalFee: {
            id: 'app.components.DataTable.analysis.air.agency.internationalFee',
            defaultMessage: 'International Fee',
          },
          noTouchFee: {
            id: 'app.components.DataTable.analysis.air.agency.noTouchFee',
            defaultMessage: 'International No Touch Fee',
          },
          regionalFee: {
            id: 'app.components.DataTable.analysis.air.agency.regionalFee',
            defaultMessage: 'Regional Fee',
          },
          regionalNoTouchFee: {
            id: 'app.components.DataTable.analysis.air.agency.regionalNoTouchFee',
            defaultMessage: 'Regional No Touch Fee',
          },
          internationalOnlineTouchFee: {
            id: 'app.components.DataTable.analysis.air.agency.internationalOnlineTouchFee',
            defaultMessage: 'International Online Touch Fee',
          },
          domesticOnlineTouchFee: {
            id: 'app.components.DataTable.analysis.air.agency.domesticOnlineTouchFee',
            defaultMessage: 'Domestic Online Touch Fee',
          },
          regionalOnlineTouchFee: {
            id: 'app.components.DataTable.analysis.air.agency.regionalOnlineTouchFee',
            defaultMessage: 'Regional Online Touch Fee',
          },
        }
      },
      agency: {
        common: {
          name: {
            id: 'app.components.DataTable.analysis.air.agency.name',
            defaultMessage: '{ name }',
          },
          adoption: {
            id: 'app.components.DataTable.analysis.air.agency.adoption',
            defaultMessage: 'Adoption',
          },
          agencyLossSavings: {
            id: 'app.components.DataTable.analysis.air.agency.agencyLossSavings',
            defaultMessage: 'Agency Loss Savings',
          },
          agencySavings: {
            id: 'app.components.DataTable.analysis.air.agency.agencySavings',
            defaultMessage: 'Agency Savings',
          },
          fareLossSavings: {
            id: 'app.components.DataTable.analysis.air.agency.fareLossSavings',
            defaultMessage: 'Fare Loss Savings',
          },
          fareSavings: {
            id: 'app.components.DataTable.analysis.air.agency.fareSavings',
            defaultMessage: 'Fare Savings',
          },
          offlineSpend: {
            id: 'app.components.DataTable.analysis.air.agency.offlineSpend',
            defaultMessage: 'Offline Spend',
          },
          onlineSpend: {
            id: 'app.components.DataTable.analysis.air.agency.onlineSpend',
            defaultMessage: 'Online Spend',
          },
          offlineTickets: {
            id: 'app.components.DataTable.analysis.air.agency.offlineTickets',
            defaultMessage: 'Offline Tickets',
          },
          onlineTickets: {
            id: 'app.components.DataTable.analysis.air.agency.onlineTickets',
            defaultMessage: 'Online Tickets',
          },
          newAdoption: {
            id: 'app.components.DataTable.analysis.air.agency.newAdoption',
            defaultMessage: '{ type } Adoption',
          },
          scenarioAgencyLossSavings: {
            id: 'app.components.DataTable.analysis.air.agency.scenarioAgencyLossSavings',
            defaultMessage: 'Agency Loss Savings',
          },
          scenarioAgencySavings: {
            id: 'app.components.DataTable.analysis.air.agency.scenarioAgencySavings',
            defaultMessage: 'Agency Savings',
          },
          scenarioFareLossSavings: {
            id: 'app.components.DataTable.analysis.air.agency.scenarioFareLossSavings',
            defaultMessage: 'Fare Loss Savings',
          },
          scenarioFareSavings: {
            id: 'app.components.DataTable.analysis.air.agency.scenarioFareSavings',
            defaultMessage: 'Fare Savings',
          },
          totalSpend: {
            id: 'app.components.DataTable.analysis.air.agency.totalSpend',
            defaultMessage: 'Total Spend',
          },
          totalTickets: {
            id: 'app.components.DataTable.analysis.air.agency.totalTickets',
            defaultMessage: 'Total Tickets',
          },
          whatif: {
            id: 'app.components.DataTable.analysis.air.agency.scenario',
            defaultMessage: 'What-If Extra Savings',
          },
        },
      },
      citypair: {
        common: {
          name: {
            id: 'app.components.DataTable.analysis.air.citypair.common.name',
            defaultMessage: '{ name }',
          },
          averageCost: {
            id: 'app.components.DataTable.analysis.air.citypair.common.averageCost',
            defaultMessage: '{ name }',
          },
          fare: {
            id: 'app.components.DataTable.analysis.air.citypair.common.fare',
            defaultMessage: '{ name }',
          },
          volume: {
            id: 'app.components.DataTable.analysis.air.citypair.common.volume',
            defaultMessage: '{ name }',
          },
          volumeColor: {
            id: 'app.components.DataTable.analysis.air.citypair.common.volumeColor',
            defaultMessage: '{ name }',
          },
          marketShare: {
            id: 'app.components.DataTable.analysis.air.citypair.common.marketShare',
            defaultMessage: 'Market Share',
          },
          fms: {
            id: 'app.components.DataTable.analysis.air.citypair.common.fms',
            defaultMessage: 'FMS',
          },
          difference: {
            id: 'app.components.DataTable.analysis.air.citypair.common.difference',
            defaultMessage: 'Gap',
          },
          current: {
            id: 'app.components.DataTable.analysis.air.citypair.common.current',
            defaultMessage: '{ name }',
          },
          previous: {
            id: 'app.components.DataTable.analysis.air.citypair.common.previous',
            defaultMessage: '{ name }',
          },
          variance: {
            id: 'app.components.DataTable.analysis.air.citypair.common.variance',
            defaultMessage: 'Variance',
          },
          benchmarkNetTrips: {
            id: 'app.components.DataTable.analysis.air.citypair.common.benchmarkNetTrips',
            defaultMessage: 'Benchmark O-Ds',
          },
          benchmarkNetTripsColor: {
            id: 'app.components.DataTable.analysis.air.citypair.common.benchmarkNetTripsColor',
            defaultMessage: 'Benchmark O-Ds',
          },
          benchmarkAmount: {
            id: 'app.components.DataTable.analysis.air.citypair.common.benchmarkAmount',
            defaultMessage: 'Benchmark { name }',
          },
          benchmark: {
            id: 'app.components.DataTable.analysis.air.citypair.common.benchmark',
            defaultMessage: 'Benchmark Average',
          },
        },
      },
      corporatefare: {
        common: {
          name: {
            id: 'app.components.DataTable.analysis.air.corporatefare.common.name',
            defaultMessage: '{ name }',
          },
          atp: {
            id: 'app.components.DataTable.analysis.air.corporatefare.common.atp',
            defaultMessage: 'Average',
          },
          lossSavings: {
            id: 'app.components.DataTable.analysis.air.corporatefare.common.lossSavings',
            defaultMessage: 'Saving Loss/Opportunity',
          },
          spend: {
            id: 'app.components.DataTable.analysis.air.corporatefare.common.spend',
            defaultMessage: 'Base Amount',
          },
          tickets: {
            id: 'app.components.DataTable.analysis.air.corporatefare.common.tickets',
            defaultMessage: 'O-Ds',
          },
          totalLossSavings: {
            id: 'app.components.DataTable.analysis.air.corporatefare.common.totalLossSavings',
            defaultMessage: 'Total Saving Loss/Opportunity',
          },
          totalSpend: {
            id: 'app.components.DataTable.analysis.air.corporatefare.common.totalSpend',
            defaultMessage: 'Total Spend',
          },
          totalTickets: {
            id: 'app.components.DataTable.analysis.air.corporatefare.common.totalTickets',
            defaultMessage: 'Total Flights',
          },
        },
      },
      onlineadoption: {
        common: {
          ytd: {
            id: 'app.components.DataTable.analysis.air.onlineadoption.common.ytd',
            defaultMessage: 'YTD',
          },
          name: {
            id: 'app.components.DataTable.analysis.air.onlineadoption.common.name',
            defaultMessage: '{ name }',
          },
          current: {
            id: 'app.components.DataTable.analysis.air.onlineadoption.common.current',
            defaultMessage: '{ name }',
          },
          previous: {
            id: 'app.components.DataTable.analysis.air.onlineadoption.common.previous',
            defaultMessage: '{ name }',
          },
          adoption: {
            id: 'app.components.DataTable.analysis.air.onlineadoption.common.adoption',
            defaultMessage: 'Adoption',
          },
          offline: {
            id: 'app.components.DataTable.analysis.air.onlineadoption.common.offline',
            defaultMessage: 'Offline',
          },
          online: {
            id: 'app.components.DataTable.analysis.air.onlineadoption.common.online',
            defaultMessage: 'Online',
          },
        },
        transactions: {
          month: {
            id: 'app.components.DataTable.analysis.air.onlineadoption.transactions.month',
            defaultMessage: 'Invoice Month',
          },
          itinerary: {
            id: 'app.components.DataTable.analysis.air.onlineadoption.transactions.itinerary',
            defaultMessage: 'Itinerary',
          },
          routeType: {
            id: 'app.components.DataTable.analysis.air.onlineadoption.transactions.routeType',
            defaultMessage: 'Route Type',
          },
          flightType: {
            id: 'app.components.DataTable.analysis.air.onlineadoption.transactions.flightType',
            defaultMessage: 'Flight Type',
          },
          bookingType: {
            id: 'app.components.DataTable.analysis.air.onlineadoption.transactions.bookingType',
            defaultMessage: 'Booking Type',
          },
          carrier: {
            id: 'app.components.DataTable.analysis.air.onlineadoption.transactions.carrier',
            defaultMessage: 'Carrier',
          },
          compliance: {
            id: 'app.components.DataTable.analysis.air.onlineadoption.transactions.compliance',
            defaultMessage: 'Compliance',
          },
          advanceDays: {
            id: 'app.components.DataTable.analysis.air.onlineadoption.transactions.advanceDays',
            defaultMessage: 'Days Advance',
          },
          tickets: {
            id: 'app.components.DataTable.analysis.air.onlineadoption.transactions.tickets',
            defaultMessage: 'Tickets',
          },
          spend: {
            id: 'app.components.DataTable.analysis.air.onlineadoption.transactions.spend',
            defaultMessage: 'Air Spend',
          },
        },
      },
      compliance: {
        name: {
          id: 'app.components.DataTable.analysis.air.compliance.name',
          defaultMessage: '{ name }',
        },
        scenario: {
          id: 'app.components.DataTable.analysis.air.compliance.scenario',
          defaultMessage: 'What-If',
        },
        savings: {
          id: 'app.components.DataTable.analysis.air.compliance.savings',
          defaultMessage: 'Savings',
        },
        totalTickets: {
          id: 'app.components.DataTable.analysis.air.compliance.totalTickets',
          defaultMessage: 'Total Tickets',
        },
        nonCompliantCodes: {
          id: 'app.components.DataTable.analysis.air.compliance.nonCompliantCodes',
          defaultMessage: 'Non-compliant Codes',
        },
        nonCompliantSavings: {
          id: 'app.components.DataTable.analysis.air.compliance.nonCompliantSavings',
          defaultMessage: 'Top Non-compliant Savings',
        },
        nonCompliantVolume: {
          id: 'app.components.DataTable.analysis.air.compliance.nonCompliantVolume',
          defaultMessage: 'Highest Non-compliant Volume',
        },
        quantity: {
          id: 'app.components.DataTable.analysis.air.compliance.quantity',
          defaultMessage: 'Quantity',
        },
        lostSavings: {
          id: 'app.components.DataTable.analysis.air.compliance.lostSavings',
          defaultMessage: 'Lost Savings',
        },
        reasonCodes_B_quantity: {
          id: 'app.components.DataTable.analysis.air.compliance.reasonCodes.b.quantity',
          defaultMessage: 'B - Qty',
        },
        reasonCodes_B_lostSavings: {
          id: 'app.components.DataTable.analysis.air.compliance.reasonCodes.b.lostSavings',
          defaultMessage: 'B - Lost Savings',
        },
        reasonCodes_C_quantity: {
          id: 'app.components.DataTable.analysis.air.compliance.reasonCodes.c.quantity',
          defaultMessage: 'C - Qty',
        },
        reasonCodes_C_lostSavings: {
          id: 'app.components.DataTable.analysis.air.compliance.reasonCodes.c.lostSavings',
          defaultMessage: 'C - Lost Savings',
        },
        reasonCodes_Q_quantity: {
          id: 'app.components.DataTable.analysis.air.compliance.reasonCodes.q.quantity',
          defaultMessage: 'Q - Qty',
        },
        reasonCodes_Q_lostSavings: {
          id: 'app.components.DataTable.analysis.air.compliance.reasonCodes.q.lostSavings',
          defaultMessage: 'Q - Lost Savings',
        },
        reasonCodes_P_quantity: {
          id: 'app.components.DataTable.analysis.air.compliance.reasonCodes.p.quantity',
          defaultMessage: 'P - Qty',
        },
        reasonCodes_P_lostSavings: {
          id: 'app.components.DataTable.analysis.air.compliance.reasonCodes.p.lostSavings',
          defaultMessage: 'P - Lost Savings',
        },
        reasonCodes_L_quantity: {
          id: 'app.components.DataTable.analysis.air.compliance.reasonCodes.l.quantity',
          defaultMessage: 'L - Qty',
        },
        reasonCodes_L_lostSavings: {
          id: 'app.components.DataTable.analysis.air.compliance.reasonCodes.l.lostSavings',
          defaultMessage: 'L - Lost Savings',
        },
        reasonCodes_Z_quantity: {
          id: 'app.components.DataTable.analysis.air.compliance.reasonCodes.z.quantity',
          defaultMessage: 'Z - Qty',
        },
        reasonCodes_Z_lostSavings: {
          id: 'app.components.DataTable.analysis.air.compliance.reasonCodes.z.lostSavings',
          defaultMessage: 'Z - Lost Savings',
        },
      },
      ticketUtilize: {
        name: {
          id: 'app.components.DataTable.analysis.air.ticketUtilize.name',
          defaultMessage: '{ name }',
        },
        totalBookedTickets: {
          id: 'app.components.DataTable.analysis.air.ticketUtilize.totalBookedTickets',
          defaultMessage: 'Total Number of Ticket Booked',
        },
        totalBookedSpent: {
          id: 'app.components.DataTable.analysis.air.ticketUtilize.totalBookedSpent',
          defaultMessage: 'Total Spend of Ticket Booked',
        },
        totalUnutilizedTickets: {
          id: 'app.components.DataTable.analysis.air.ticketUtilize.totalUnutilizedTickets',
          defaultMessage: 'Total Number of Ticket Un-utilized',
        },
        totalUnutilizedTicketsSpent: {
          id: 'app.components.DataTable.analysis.air.ticketUtilize.totalUnutilizedTicketsSpent',
          defaultMessage: 'Total Spend of Ticket Un-utilized',
        },
      },
      trend: {
        name: {
          id: 'app.components.DataTable.analysis.air.trend.name',
          defaultMessage: '{ name }',
        },
        spend1: {
          id: 'app.components.DataTable.analysis.air.trend.spend1',
          defaultMessage: '{ name }',
        },
        spend2: {
          id: 'app.components.DataTable.analysis.air.trend.spend2',
          defaultMessage: '{ name }',
        },
        spend3: {
          id: 'app.components.DataTable.analysis.air.trend.spend3',
          defaultMessage: '{ name }',
        },
        spend4: {
          id: 'app.components.DataTable.analysis.air.trend.spend4',
          defaultMessage: '{ name }',
        },
        spend5: {
          id: 'app.components.DataTable.analysis.air.trend.spend5',
          defaultMessage: '{ name }',
        },

        verticalCompareSpend1: {
          id: 'app.components.DataTable.analysis.air.trend.verticalCompareSpend1',
          defaultMessage: '{ name }',
        },
        verticalCompareSpend2: {
          id: 'app.components.DataTable.analysis.air.trend.verticalCompareSpend2',
          defaultMessage: '{ name }',
        },
        verticalCompareSpend3: {
          id: 'app.components.DataTable.analysis.air.trend.verticalCompareSpend3',
          defaultMessage: '{ name }',
        },
        verticalCompareSpend4: {
          id: 'app.components.DataTable.analysis.air.trend.verticalCompareSpend4',
          defaultMessage: '{ name }',
        },
        verticalCompareSpend5: {
          id: 'app.components.DataTable.analysis.air.trend.verticalCompareSpend5',
          defaultMessage: '{ name }',
        },

        horizontalCompareSpend1: {
          id: 'app.components.DataTable.analysis.air.trend.horizontalCompareSpend1',
          defaultMessage: '{ name }',
        },
        horizontalCompareSpend2: {
          id: 'app.components.DataTable.analysis.air.trend.horizontalCompareSpend2',
          defaultMessage: '{ name }',
        },
        horizontalCompareSpend3: {
          id: 'app.components.DataTable.analysis.air.trend.horizontalCompareSpend3',
          defaultMessage: '{ name }',
        },
        horizontalCompareSpend4: {
          id: 'app.components.DataTable.analysis.air.trend.horizontalCompareSpend4',
          defaultMessage: '{ name }',
        },
        horizontalCompareSpend5: {
          id: 'app.components.DataTable.analysis.air.trend.horizontalCompareSpend5',
          defaultMessage: '{ name }',
        },
        horizontalCompareRecoveryPercent1: {
          id: 'app.components.DataTable.analysis.air.trend.horizontalCompareRecoveryPercent1',
          defaultMessage: '{ name }',
        },
        horizontalCompareRecoveryPercent2: {
          id: 'app.components.DataTable.analysis.air.trend.horizontalCompareRecoveryPercent2',
          defaultMessage: '{ name }',
        },
        horizontalCompareRecoveryPercent3: {
          id: 'app.components.DataTable.analysis.air.trend.horizontalCompareRecoveryPercent3',
          defaultMessage: '{ name }',
        },
        horizontalCompareRecoveryPercent4: {
          id: 'app.components.DataTable.analysis.air.trend.horizontalCompareRecoveryPercent4',
          defaultMessage: '{ name }',
        },
        horizontalCompareRecoveryPercent5: {
          id: 'app.components.DataTable.analysis.air.trend.horizontalCompareRecoveryPercent5',
          defaultMessage: '{ name }',
        },
      },
      contract: {
        name: {
          id: 'app.components.DataTable.analysis.air.contract.name',
          defaultMessage: '{name}',
        },
        goal: {
          id: 'app.components.DataTable.analysis.air.contract.goal',
          defaultMessage: 'Goal',
        },
        fms: {
          id: 'app.components.DataTable.analysis.air.contract.fms',
          defaultMessage: 'FMS',
        },
        shareGap: {
          id: 'app.components.DataTable.analysis.air.contract.shareGap',
          defaultMessage: 'Share Gap',
        },
        goalVarianceColored: {
          id: 'app.components.DataTable.analysis.air.contract.goalVariance',
          defaultMessage: 'Performance',
        },
        airportPairTotalRecords: {
          id: 'app.components.DataTable.analysis.air.contract.airportPairTotalRecords',
          defaultMessage: 'airportPairTotalRecords',
        },
        records: {
          id: 'app.components.DataTable.analysis.air.contract.records',
          defaultMessage: 'records',
        },
        
        currentAirline:{
          id: 'app.components.DataTable.analysis.air.contract.currentAirline',
          defaultMessage: '{name}',
        },
        currentAirlineOdCount: {
          id: 'app.components.DataTable.analysis.air.contract.currentAirlineOdCount',
          defaultMessage: '{airlineCode} O&D Count',
        },
        currentAirlineOdMarketShare: {
          id: 'app.components.DataTable.analysis.air.contract.currentAirlineOdMarketShare',
          defaultMessage: '{airlineCode} Share - O&D Count',
        },
        currentAirlineTotalSpend: {
          id: 'app.components.DataTable.analysis.air.contract.currentAirlineTotalSpend',
          defaultMessage: '{airlineCode} O&D Amt',
        },
        currentAirlineSpendMarketShare: {
          id: 'app.components.DataTable.analysis.air.contract.currentAirlineSpendMarketShare',
          defaultMessage: '{airlineCode} Share - O&D Amt',
        },
        currentAirlineAvgSpend: {
          id: 'app.components.DataTable.analysis.air.contract.currentAirlineAvgSpend',
          defaultMessage: '{airlineCode} Avg',
        },
        currentAirlineAvgSpendDiff: {
          id: 'app.components.DataTable.analysis.air.contract.currentAirlineAvgSpendDiff',
          defaultMessage: 'Variance',
        },
        totalOdCount: {
          id: 'app.components.DataTable.analysis.air.contract.totalOdCount',
          defaultMessage: 'Total O&Ds',
        },
        totalSpend: {
          id: 'app.components.DataTable.analysis.air.contract.totalSpend',
          defaultMessage: 'Total Amt',
        },
        totalAvgSpend: {
          id: 'app.components.DataTable.analysis.air.contract.totalAvgSpend',
          defaultMessage: 'Avg',
        },
        airportPair: {
          id: 'app.components.DataTable.analysis.air.contract.airportPair',
          defaultMessage: 'Market Pair',
        },
        odCount: {
          id: 'app.components.DataTable.analysis.air.contract.odCount',
          defaultMessage: 'O&D Count',
        },
        avgSpend: {
          id: 'app.components.DataTable.analysis.air.contract.avgSpend',
          defaultMessage: 'Avg',
        },
        odMarketShare: {
          id: 'app.components.DataTable.analysis.air.contract.odMarketShare',
          defaultMessage: 'Share - O&D Count',
        },
        spendMarketShare: {
          id: 'app.components.DataTable.analysis.air.contract.spendMarketShare',
          defaultMessage: 'Share - O&D Amt',
        },
        otherAirlines: {
          id: 'app.components.DataTable.analysis.air.contract.otherAirlines',
          defaultMessage: 'Other Airlines',
        },
        otherAirlineOdCount: {
          id: 'app.components.DataTable.analysis.air.contract.otherAirlineOdCount',
          defaultMessage: 'OA O&D Count',
        },
        otherAirlineOdMarketShare: {
          id: 'app.components.DataTable.analysis.air.contract.otherAirlineOdMarketShare',
          defaultMessage: 'OA Share - O&D Count',
        },
        otherAirlineTotalSpend: {
          id: 'app.components.DataTable.analysis.air.contract.otherAirlineTotalSpend',
          defaultMessage: 'O&D Amt',
        },
        otherAirlineSpendMarketShare: {
          id: 'app.components.DataTable.analysis.air.contract.otherAirlineSpendMarketShare',
          defaultMessage: 'OA Share - O&D Amt',
        },
        otherAirlineAvgSpend: {
          id: 'app.components.DataTable.analysis.air.contract.otherAirlineAvgSpend',
          defaultMessage: 'OA Avg',
        },
        airlineName: {
          id: 'app.components.DataTable.analysis.air.contract.airline',
          defaultMessage: 'Airline',
        },
        marketName: {
          id: 'app.components.DataTable.analysis.air.contract.marketName',
          defaultMessage: 'Market',
        },
        cabinTypeName: {
          id: 'app.components.DataTable.analysis.air.contract.cabinTypeName',
          defaultMessage: 'Cabin Type',
        },
        
        
      }
    },
    hotel: {
      marketshare: {
        common: {
          ContractStatus2022: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.ContractStatus2022',
            defaultMessage: ' '
          },
          ContractStatus2021: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.ContractStatus2021',
            defaultMessage: ' '
          },
          ContractStatus2020: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.ContractStatus2020',
            defaultMessage: ' '
          },
          ContractStatus2019: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.ContractStatus2019',
            defaultMessage: ' '
          },
          ContractStatus2018: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.ContractStatus2018',
            defaultMessage: ' '
          },
          ContractStatus2017: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.ContractStatus2017',
            defaultMessage: ' '
          },
          ContractStatus2016: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.ContractStatus2016',
            defaultMessage: ' '
          },
          CityCap2022: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.CityCap2022',
            defaultMessage: ' '
          },
          CityCap2021: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.CityCap2021',
            defaultMessage: ' '
          },
          CityCap2020: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.CityCap2020',
            defaultMessage: ' '
          },
          CityCap2019: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.CityCap2019',
            defaultMessage: ' '
          },
          CityCap2018: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.CityCap2018',
            defaultMessage: ' '
          },
          CityCap2017: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.CityCap2017',
            defaultMessage: ' '
          },
          CityCap2016: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.CityCap2016',
            defaultMessage: ' '
          },
          contractStatus: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.value',
            defaultMessage: '{value} Contract Status'
          },
          cityCap: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.value',
            defaultMessage: '{value} City Cap'
          },
          city: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.name',
            defaultMessage: 'City'
          },
          country: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.name',
            defaultMessage: 'Country'
          },
          state: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.name',
            defaultMessage: 'State'
          },
          name: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.name',
            defaultMessage: '{ name }'
          },
          hotelId: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.hotelId',
            defaultMessage: 'Country',
          },
          current: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.current',
            defaultMessage: '{ name }'
          },
          previous: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.previous',
            defaultMessage: '{ name }'
          },
          variance: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.variance',
            defaultMessage: 'Variance',
          },
          nights: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.nights',
            defaultMessage: 'Nights'
          },
          spend: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.spend',
            defaultMessage: 'Spend'
          },
          quarter: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.quarter',
            defaultMessage: 'Quarter'
          },
          averageRate: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.averageRate',
            defaultMessage: 'Avg. Rate'
          },
          averageStay: {
            id: 'app.components.DataTable.analysis.hotel.marketshare.averageStay',
            defaultMessage: 'Avg. Stay',
          },
        },
      },
      marketshift: {
        common: {
          city: {
            id: 'app.components.DataTable.analysis.hotel.marketshift.city',
            defaultMessage: 'City'
          },
          country: {
            id: 'app.components.DataTable.analysis.hotel.marketshift.country',
            defaultMessage: 'Country'
          },
          state: {
            id: 'app.components.DataTable.analysis.hotel.marketshift.state',
            defaultMessage: 'State'
          },
          name: {
            id: 'app.components.DataTable.analysis.hotel.marketshift.name',
            defaultMessage: '{ name }'
          },
          nights: {
            id: 'app.components.DataTable.analysis.hotel.marketshift.nights',
            defaultMessage: 'Nights'
          },
          spend: {
            id: 'app.components.DataTable.analysis.hotel.marketshift.spend',
            defaultMessage: 'Spend'
          },
          averageStay: {
            id: 'app.components.DataTable.analysis.hotel.marketshift.averageStay',
            defaultMessage: 'Avg. Stay'
          },
          averageOBTRate: {
            id: 'app.components.DataTable.analysis.hotel.marketshift.averageOBTRate',
            defaultMessage: 'Avg. OBT Rate'
          },
          averageDirectRate: {
            id: 'app.components.DataTable.analysis.hotel.marketshift.averageDirectRate',
            defaultMessage: 'Avg. Direct Rate'
          },
          estimatedSpend: {
            id: 'app.components.DataTable.analysis.hotel.marketshift.estimatedSpend',
            defaultMessage: 'Estimated Spend'
          },
          savings: {
            id: 'app.components.DataTable.analysis.hotel.marketshift.savings',
            defaultMessage: 'Estimated Savings'
          },
          loss: {
            id: 'app.components.DataTable.analysis.hotel.marketshift.loss',
            defaultMessage: 'Estimated Loss'
          },
        },
      },
      rate: {
        common: {
          city: {
            id: 'app.components.DataTable.analysis.hotel.rate.city',
            defaultMessage: 'City'
          },
          country: {
            id: 'app.components.DataTable.analysis.hotel.rate.country',
            defaultMessage: 'Country'
          },
          state: {
            id: 'app.components.DataTable.analysis.hotel.rate.state',
            defaultMessage: 'State'
          },
          name: {
            id: 'app.components.DataTable.analysis.hotel.rate.name',
            defaultMessage: '{ name }'
          },
          totalSpend: {
            id: 'app.components.DataTable.analysis.hotel.rate.totalSpend',
            defaultMessage: 'Total Spend'
          },
          unmatchedSpend: {
            id: 'app.components.DataTable.analysis.hotel.rate.unmatchedSpend',
            defaultMessage: 'Unmatched Spend'
          },
          fixedAndDynamicSpend: {
            id: 'app.components.DataTable.analysis.hotel.rate.fixedAndDynamicSpend',
            defaultMessage: 'In Contract Spend'
          },
          fixedAndDynamicNights: {
            id: 'app.components.DataTable.analysis.hotel.rate.fixedAndDynamicNights',
            defaultMessage: 'In Contract Nights'
          },
          fixedRate: {
            id: 'app.components.DataTable.analysis.hotel.rate.fixedRate',
            defaultMessage: 'Fixed Rate'
          },
          dynamicRate: {
            id: 'app.components.DataTable.analysis.hotel.rate.dynamicRate',
            defaultMessage: 'Dynamic Rate'
          },
          variance: {
            id: 'app.components.DataTable.analysis.hotel.rate.variance',
            defaultMessage: 'Variance'
          },
          estimatedSavings: {
            id: 'app.components.DataTable.analysis.hotel.rate.estimatedSavings',
            defaultMessage: 'Potential Savings'
          },
          spend: {
            id: 'app.components.DataTable.analysis.hotel.rate.spend',
            defaultMessage: 'Spend'
          },
          nights: {
            id: 'app.components.DataTable.analysis.hotel.rate.nights',
            defaultMessage: 'Nights',
          },
          averageRate: {
            id: 'app.components.DataTable.analysis.hotel.rate.averageRate',
            defaultMessage: 'Avg. Rate',
          },
          contractRate: {
            id: 'app.components.DataTable.analysis.hotel.rate.contractRate',
            defaultMessage: 'Contract Rate',
          },
          rateType: {
            id: 'app.components.DataTable.analysis.hotel.rate.rateType',
            defaultMessage: 'Rate Type',
          },
          avgBarFlexRNS: {
            id: 'app.components.DataTable.analysis.hotel.rate.avgBarFlexRNS',
            defaultMessage: 'Avg BAR (RN\'s)',
          },
          totalRoomNightCount: {
            id: 'app.components.DataTable.analysis.hotel.rate.totalRoomNightCount',
            defaultMessage: 'Total Room Night Count',
          },
          lowestBARFlex: {
            id: 'app.components.DataTable.analysis.hotel.rate.lowestBARFlex',
            defaultMessage: 'Lowest BAR',
          },
          avgBARNonFlex: {
            id: 'app.components.DataTable.analysis.hotel.rate.avgBARNonFlex',
            defaultMessage: 'Avg BAR non flex',
          },
          totalRoomNightCountNonFlex: {
            id: 'app.components.DataTable.analysis.hotel.rate.totalRoomNightCountNonFlex',
            defaultMessage: 'Total Room Night Count Non Flex',
          },
          avgBenchmarkNegRateRNS: {
            id: 'app.components.DataTable.analysis.hotel.rate.avgBenchmarkNegRateRNS',
            defaultMessage: 'Avg Benchmark Neg Rate (RN\'s)',
          },
          totalRoomNightNegCount: {
            id: 'app.components.DataTable.analysis.hotel.rate.totalRoomNightNegCount',
            defaultMessage: 'Total Room Night Neg Count',
          },
          stdDevBenchmarkNegRate: {
            id: 'app.components.DataTable.analysis.hotel.rate.stdDevBenchmarkNegRate',
            defaultMessage: 'Lowest Neg Rate in Hotel',
          },
          baroff: {
            id: 'app.components.DataTable.analysis.hotel.rate.baroff',
            defaultMessage: 'BAR % Off',
          },
          barflex: {
            id: 'app.components.DataTable.analysis.hotel.rate.barflex',
            defaultMessage: 'BAR',
          },
          barFlexSavingsLoss: {
            id: 'app.components.DataTable.analysis.hotel.rate.barFlexSavingsLoss',
            defaultMessage: 'BAR Savings Loss',
          },
          lowestBARFlexVariance: {
            id: 'app.components.DataTable.analysis.hotel.rate.lowestBARFlexVariance',
            defaultMessage: 'Lowest BAR',
          },
          barNonFlex: {
            id: 'app.components.DataTable.analysis.hotel.rate.barNonFlex',
            defaultMessage: 'BAR non flex',
          },
          benchmarkNegRate: {
            id: 'app.components.DataTable.analysis.hotel.rate.benchmarkNegRate',
            defaultMessage: 'Benchmark Neg Rate',
          },
          benchmarkNegRateSavingsLoss: {
            id: 'app.components.DataTable.analysis.hotel.rate.benchmarkNegRateSavingsLoss',
            defaultMessage: 'Benchmark Neg Rate Savings Loss',
          },
          barOffRate: {
            id: 'app.components.DataTable.analysis.hotel.rate.barOffRate',
            defaultMessage: 'BAR % Off',
          },
          barFlexRate: {
            id: 'app.components.DataTable.analysis.hotel.rate.barFlexRate',
            defaultMessage: 'BAR',
          },
          barFlexSavingsLossRate: {
            id: 'app.components.DataTable.analysis.hotel.rate.barFlexSavingsLossRate',
            defaultMessage: 'BAR Savings Loss',
          },
          barNonFlexRate: {
            id: 'app.components.DataTable.analysis.hotel.rate.barNonFlexRate',
            defaultMessage: 'BAR non flex',
          },
          benchmarkNegRateAnalysis: {
            id: 'app.components.DataTable.analysis.hotel.rate.benchmarkNegRateAnalysis',
            defaultMessage: 'Benchmark Neg Rate',
          },
          benchmarkNegRateSavingsLossRate: {
            id: 'app.components.DataTable.analysis.hotel.rate.benchmarkNegRateSavingsLossRate',
            defaultMessage: 'Benchmark Neg Rate Savings Loss',
          },
          barFlexSavingsLoss: {
            id: 'app.components.DataTable.analysis.hotel.rate.barFlexSavingsLoss',
            defaultMessage: 'BAR Savings Loss',
          },
          lowestBARFlexRate: {
            id: 'app.components.DataTable.analysis.hotel.rate.lowestBARFlexRate',
            defaultMessage: 'Lowest BAR',
          },
          barNonFlex: {
            id: 'app.components.DataTable.analysis.hotel.rate.barNonFlex',
            defaultMessage: 'BAR non flex',
          },
          // benchmarkNegRate: {
          //   id: 'app.components.DataTable.analysis.hotel.rate.benchmarkNegRate',
          //   defaultMessage: 'Benchmark Neg Rate Analysis',
          // },
          // benchmarkNegRateSavingsLoss: {
          //   id: 'app.components.DataTable.analysis.hotel.rate.benchmarkNegRateSavingsLoss',
          //   defaultMessage: 'Benchmark Neg Rate Savings Loss Rate',
          // },
          barOffDynamic: {
            id: 'app.components.DataTable.analysis.hotel.rate.barOffDynamic',
            defaultMessage: 'BAR % Off',
          },
          savingsLoss: {
            id: 'app.components.DataTable.analysis.hotel.rate.savingsLoss',
            defaultMessage: 'Savings Loss',
          },

        },
      },
      contract: {
        common: {
          city: {
            id: 'app.components.DataTable.analysis.hotel.contract.city',
            defaultMessage: 'City'
          },
          country: {
            id: 'app.components.DataTable.analysis.hotel.contract.country',
            defaultMessage: 'Country'
          },
          state: {
            id: 'app.components.DataTable.analysis.hotel.contract.state',
            defaultMessage: 'State'
          },
          name: {
            id: 'app.components.DataTable.analysis.hotel.contract.name',
            defaultMessage: '{ name }'
          },
          totalSpend: {
            id: 'app.components.DataTable.analysis.hotel.contract.totalSpend',
            defaultMessage: 'Total Spend'
          },
          totalNights: {
            id: 'app.components.DataTable.analysis.hotel.contract.totalNights',
            defaultMessage: 'Total Nights'
          },
          totalAverageRate: {
            id: 'app.components.DataTable.analysis.hotel.contract.totalAverageRate',
            defaultMessage: 'Total Avg. Rate'
          },
          inContract: {
            id: 'app.components.DataTable.analysis.hotel.contract.inContract',
            defaultMessage: 'In Contract'
          },
          outContract: {
            id: 'app.components.DataTable.analysis.hotel.contract.outContract',
            defaultMessage: 'Out of Contract'
          },
          unmatched: {
            id: 'app.components.DataTable.analysis.hotel.contract.unmatched',
            defaultMessage: 'Unmatched'
          },
          variance: {
            id: 'app.components.DataTable.analysis.hotel.contract.variance',
            defaultMessage: 'Variance'
          },
          spend: {
            id: 'app.components.DataTable.analysis.hotel.contract.spend',
            defaultMessage: 'Spend'
          },
          nights: {
            id: 'app.components.DataTable.analysis.hotel.contract.nights',
            defaultMessage: 'Nights',
          },
          averageRate: {
            id: 'app.components.DataTable.analysis.hotel.contract.averageRate',
            defaultMessage: 'Avg. Rate',
          },
        },
      },
      fixedrate: {
        common: {
          city: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.city',
            defaultMessage: 'City'
          },
          country: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.country',
            defaultMessage: 'Country'
          },
          state: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.state',
            defaultMessage: 'State'
          },
          name: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.name',
            defaultMessage: '{ name }'
          },
          fixedRate: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.totalSpend',
            defaultMessage: 'Fixed Rate'
          },
          totalSpend: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.totalSpend',
            defaultMessage: 'Total Spend'
          },
          totalNights: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.totalNights',
            defaultMessage: 'Total Nights'
          },
          contractSpend: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.contractSpend',
            defaultMessage: 'In Contract Spend (%)'
          },
          fixedSpend: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.fixedSpend',
            defaultMessage: 'Fixed Rate Spend (%)'
          },
          fixedNights: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.fixedNights',
            defaultMessage: 'Fixed Rate Nights (%)'
          },
          paidRate: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.paidRate',
            defaultMessage: 'Avg. Paid Rate { amount } Tax'
          },
          negociatedRate: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.negociatedRate',
            defaultMessage: 'Avg. Negotiated Rate'
          },
          bookedRate: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.bookedRate',
            defaultMessage: 'Avg. Booked Rate'
          },
          lossSavingsNights: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.lossSavingsNights',
            defaultMessage: 'Savings Loss Nights'
          },
          lossSavings: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.lossSavings',
            defaultMessage: 'Savings Loss'
          },
          opportunityNights: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.opportunityNights',
            defaultMessage: 'Opportunity Nights'
          },
          opportunity: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.opportunity',
            defaultMessage: 'Opportunity'
          },
        },
      },
      booking: {
        common: {
          city: {
            id: 'app.components.DataTable.analysis.hotel.bookingMethod.city',
            defaultMessage: 'City'
          },
          country: {
            id: 'app.components.DataTable.analysis.hotel.bookingMethod.country',
            defaultMessage: 'Country'
          },
          state: {
            id: 'app.components.DataTable.analysis.hotel.bookingMethod.state',
            defaultMessage: 'State'
          },
          name: {
            id: 'app.components.DataTable.analysis.hotel.bookingMethod.name',
            defaultMessage: '{ name }',
          },
          totalSpend: {
            id: 'app.components.DataTable.analysis.hotel.bookingMethod.totalSpend',
            defaultMessage: 'Total Spend { amount } Tax',
          },
          totalNights: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.totalNights',
            defaultMessage: 'Total Nights',
          },
          fixedRate: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.fixedRate',
            defaultMessage: 'Avg. Contracted Fixed Rate',
          },
          rate: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.rate',
            defaultMessage: 'Avg. Rate { amount } Tax',
          },
          onlineRate: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.onlineRate',
            defaultMessage: 'Online',
          },
          offlineRate: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.offlineRate',
            defaultMessage: 'Offline',
          },
          directRate: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.directRate',
            defaultMessage: 'Direct',
          },
          nights: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.nights',
            defaultMessage: 'Nights',
          },
          onlineNights: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.onlineNights',
            defaultMessage: 'Online',
          },
          offlineNights: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.offlineNights',
            defaultMessage: 'Offline',
          },
          directNights: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.directNights',
            defaultMessage: 'Direct',
          },
          spend: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.spend',
            defaultMessage: 'Spend { amount } Tax',
          },
          onlineSpend: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.onlineSpend',
            defaultMessage: 'Online Spend',
          },
          offlineSpend: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.offlineSpend',
            defaultMessage: 'Offline Spend',
          },
          directSpend: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.directSpend',
            defaultMessage: 'Direct Spend',
          },
          directVariance: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.directVariance',
            defaultMessage: 'Direct Variance',
          },
          offlineVariance: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.offlineVariance',
            defaultMessage: 'Offline Variance',
          },
          totalVariance: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.totalVariance',
            defaultMessage: 'Total Variance',
          },
          online: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.online',
            defaultMessage: 'Online',
          },
          offline: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.offline',
            defaultMessage: 'Offline',
          },
          direct: {
            id: 'app.components.DataTable.analysis.hotel.fixedRate.direct',
            defaultMessage: 'Direct',
          },
        },
      },
    },
    car: {
      rentalbooking: {
        common: {
          name: {
            id: 'app.components.DataTable.analysis.car.rentalbooking.name',
            defaultMessage: '{ name }',
          },
          totalCarRentals: {
            id: 'app.components.DataTable.analysis.car.rentalbooking.totalCarRentals',
            defaultMessage: 'Total Rentals',
          },
          totalCarRentalsSpend: {
            id: 'app.components.DataTable.analysis.car.rentalbooking.totalCarRentalsSpend',
            defaultMessage: 'Total Spend',
          },
          booked: {
            id: 'app.components.DataTable.analysis.car.rentalbooking.booked',
            defaultMessage: 'Booked',
          },
          bookedPaid: {
            id: 'app.components.DataTable.analysis.car.rentalbooking.bookedPaid',
            defaultMessage: 'Booked vs Paid',
          },
          bookedUnkown: {
            id: 'app.components.DataTable.analysis.car.rentalbooking.bookedUnkown',
            defaultMessage: 'Booked Unkown',
          },
          paid: {
            id: 'app.components.DataTable.analysis.car.rentalbooking.paid',
            defaultMessage: 'Paid',
          },
          paidUnkown: {
            id: 'app.components.DataTable.analysis.car.rentalbooking.paidUnkown',
            defaultMessage: 'Paid Unkown',
          },
          pickedUp: {
            id: 'app.components.DataTable.analysis.car.rentalbooking.pickedUp',
            defaultMessage: 'Picked-Up',
          },
          pickedUpUnkown: {
            id: 'app.components.DataTable.analysis.car.rentalbooking.pickedUpUnkown',
            defaultMessage: 'Picked-Up Unkown',
          },
          pickedUpPaid: {
            id: 'app.components.DataTable.analysis.car.rentalbooking.pickedUpPaid',
            defaultMessage: 'Picked-Up vs Paid',
          },
          withSavings: {
            id: 'app.components.DataTable.analysis.car.rentalbooking.withSavings',
            defaultMessage: 'Rentals With Savings (%)',
          },
          savings: {
            id: 'app.components.DataTable.analysis.car.rentalbooking.savings',
            defaultMessage: 'Savings',
          },
          withLoss: {
            id: 'app.components.DataTable.analysis.car.rentalbooking.withLoss',
            defaultMessage: 'Rentals With Loss (%)',
          },
          loss: {
            id: 'app.components.DataTable.analysis.car.rentalbooking.loss',
            defaultMessage: 'Loss',
          },
          unknown: {
            id: 'app.components.DataTable.analysis.car.rentalbooking.unknown',
            defaultMessage: 'Unknown Class (%)',
          },
          inPolicy: {
            id: 'app.components.DataTable.analysis.car.rentalbooking.inPolicy',
            defaultMessage: 'Class In Policy (%)',
          },
          outOfPolicy: {
            id: 'app.components.DataTable.analysis.car.rentalbooking.outOfPolicy',
            defaultMessage: 'Class Out Of Policy (%)',
          },
        },
        country: {},
        city: {},
      },

      benchmark: {
        common: {
          name: {
            id: 'app.components.DataTable.analysis.car.benchmark.name',
            defaultMessage: '{ name }',
          },
          Paid: {
            id: 'app.components.DataTable.analysis.car.benchmark.Paid',
            defaultMessage: 'Paid',
          },
          Benchmark: {
            id: 'app.components.DataTable.analysis.car.benchmark.Benchmark',
            defaultMessage: 'Benchmarking',
          },
          Variance: {
            id: 'app.components.DataTable.analysis.car.benchmark.Variance',
            defaultMessage: 'Variance',
          },
          spend: {
            id: 'app.components.DataTable.analysis.car.benchmark.spend',
            defaultMessage: 'Total Spend',
          },
          rentalDays: {
            id: 'app.components.DataTable.analysis.car.benchmark.rentalDays',
            defaultMessage: 'Total Rentals',
          },
          avgRate: {
            id: 'app.components.DataTable.analysis.car.benchmark.avgRate',
            defaultMessage: 'Average Daily Rate',
          },
          lowestRate: {
            id: 'app.components.DataTable.analysis.car.benchmark.lowestRate',
            defaultMessage: 'Lowest Rate',
          },
          lossSavings: {
            id: 'app.components.DataTable.analysis.car.benchmark.lossSavings',
            defaultMessage: 'Loss Savings',
          },
  
        },
        country: {},
        city: {},
      },

      rebate: {
        common: {
          name: {
            id: 'app.components.DataTable.analysis.car.rebate.name',
            defaultMessage: 'Location',
          },
          rentalGroup: {
            id: 'app.components.DataTable.analysis.car.rebate.rentalGroup',
            defaultMessage: 'Rental Group',
          },
          businessSpend: {
            id: 'app.components.DataTable.analysis.car.rebate.businessSpend',
            defaultMessage: 'Business Spend',
          },
          marketShare: {
            id: 'app.components.DataTable.analysis.car.rebate.marketShare',
            defaultMessage: 'Market Share',
          },
          totalSpendBasedRebateLevel: {
            id: 'app.components.DataTable.analysis.car.rebate.totalSpendBasedRebateLevel',
            defaultMessage: 'Total Spend Based Rebate Level',
          },
          shareRebateLevel: {
            id: 'app.components.DataTable.analysis.car.rebate.shareRebateLevel',
            defaultMessage: 'Share Rebate Level',
          },
          achievedRebateLevel: {
            id: 'app.components.DataTable.analysis.car.rebate.achievedRebateLevel',
            defaultMessage: 'Achieved Rebate Level',
          },
          rebateValue: {
            id: 'app.components.DataTable.analysis.car.rebate.rebateValue',
            defaultMessage: 'Rebate Value',
          },
          calculatedRebate: {
            id: 'app.components.DataTable.analysis.car.rebate.calculatedRebate',
            defaultMessage: 'Calculated Rebate',
          },
        },
        country: {},
        city: {},
      },
      compliance: {
        common: {
          name: {
            id: 'app.components.DataTable.analysis.car.compliance.name',
            defaultMessage: '{ name }',
          },
          totalCarRentals: {
            id: 'app.components.DataTable.analysis.car.compliance.totalCarRentals',
            defaultMessage: 'Total Rentals',
          },
          totalCarRentalsSpend: {
            id: 'app.components.DataTable.analysis.car.compliance.totalCarRentalsSpend',
            defaultMessage: 'Total Spend',
          },
          OutofPolicy: {
            id: 'app.components.DataTable.analysis.car.compliance.OutofPolicy',
            defaultMessage: 'Out-of-Policy',
          },
          InPolicy: {
            id: 'app.components.DataTable.analysis.car.compliance.InPolicy',
            defaultMessage: 'In-Policy',
          },
          VendorClarificationNeeded: {
            id: 'app.components.DataTable.analysis.car.compliance.VendorClarificationNeeded	',
            defaultMessage: 'Vendor Clarification Needed',
          },
          UNCATEGORIZED: {
            id: 'app.components.DataTable.analysis.car.compliance.UNCATEGORIZED	',
            defaultMessage: 'Uncategorized',
          },
          quantity: {
            id: 'app.components.DataTable.analysis.car.compliance.quantity',
            defaultMessage: 'Transactions',
          },
          spend: {
            id: 'app.components.DataTable.analysis.car.compliance.spend',
            defaultMessage: 'Spend',
          },
          savings: {
            id: 'app.components.DataTable.analysis.car.compliance.savings',
            defaultMessage: 'Lost Savings',
          },
        }
      },
    },
    card: {
      credit: {
        common: {
          name: {
            id: 'app.components.DataTable.analysis.card.credit.name',
            defaultMessage: '{ name }',
          },
          current: {
            id: 'app.components.DataTable.analysis.card.credit.current',
            defaultMessage: '{ name } { header }'
          },
          previous: {
            id: 'app.components.DataTable.analysis.card.credit.previous',
            defaultMessage: '{ name } { header }'
          },
          variance: {
            id: 'app.components.DataTable.analysis.card.credit.variance',
            defaultMessage: 'Variance',
          },
          totalCreditsAmount: {
            id: 'app.components.DataTable.analysis.card.credit.totalCreditsAmount',
            defaultMessage: 'Total Credits',
          },
          activeCardCreditsAmount: {
            id: 'app.components.DataTable.analysis.card.credit.activeCardCreditsAmount',
            defaultMessage: 'Active Card Credits',
          },
          cancelledCardCreditsWithActiveEmpAmount: {
            id: 'app.components.DataTable.analysis.card.credit.cancelledCardCreditsWithActiveEmpAmount',
            defaultMessage: 'Cancelled Cards Credits for Active Employees',
          },
          cancelledCardCreditsWithExitEmpAmount: {
            id: 'app.components.DataTable.analysis.card.credit.cancelledCardCreditsWithExitEmpAmount',
            defaultMessage: 'Cancelled Card Credits for Exit Employees',
          },
          totalDebitAmount: {
            id: 'app.components.DataTable.analysis.card.credit.totalDebitAmount',
            defaultMessage: 'Total Debit Amount',
          },
        }
      },
      compliance: {
        common: {
          name: {
            id: 'app.components.DataTable.analysis.card.compliance.name',
            defaultMessage: '{ name }',
          },
          current: {
            id: 'app.components.DataTable.analysis.card.compliance.current',
            defaultMessage: '{ name }'
          },
          previous: {
            id: 'app.components.DataTable.analysis.card.compliance.previous',
            defaultMessage: '{ name }'
          },
          variance: {
            id: 'app.components.DataTable.analysis.card.compliance.variance',
            defaultMessage: 'Variance',
          },
          delinquencyTotalAmount: {
            id: 'app.components.DataTable.analysis.card.compliance.delinquencyTotalAmount',
            defaultMessage: 'Delinquency',
          },
          day30Amount: {
            id: 'app.components.DataTable.analysis.card.compliance.day30Amount',
            defaultMessage: 'Day 30',
          },
          day60Amount: {
            id: 'app.components.DataTable.analysis.card.compliance.day60Amount',
            defaultMessage: 'Day 60',
          },
          day90Amount: {
            id: 'app.components.DataTable.analysis.card.compliance.day90Amount',
            defaultMessage: 'Day 90',
          },
          day120Amount: {
            id: 'app.components.DataTable.analysis.card.compliance.day120Amount',
            defaultMessage: 'Day 120',
          },
          day150Amount: {
            id: 'app.components.DataTable.analysis.card.compliance.day150Amount',
            defaultMessage: 'Day 150',
          },
          day180Amount: {
            id: 'app.components.DataTable.analysis.card.compliance.day180Amount',
            defaultMessage: 'Potential Credit Loss',
          },
        }
      },
      payment: {
        name: {
          id: 'app.components.DataTable.analysis.card.payment.name',
          defaultMessage: '{ name }',
        },
        totalAmount: {
          id: 'app.components.DataTable.analysis.card.payment.totalAmount',
          defaultMessage: 'Charge Volumes',
        },
        totalTranscations: {
          id: 'app.components.DataTable.analysis.card.payment.totalTranscations',
          defaultMessage: 'Transactions',
        },
        amount: {
          id: 'app.components.DataTable.analysis.card.payment.amount',
          defaultMessage: 'Charge Volumes',
        },
        transactions: {
          id: 'app.components.DataTable.analysis.card.payment.trans',
          defaultMessage: 'Transactions',
        },
        totalOwners: {
          id: 'app.components.DataTable.analysis.card.payment.totalOwners',
          defaultMessage: 'Cards in Force',
        },
        Cash: {
          id: 'app.components.DataTable.analysis.card.payment.Cash',
          defaultMessage: 'Non-preferred',
        }
      },
      incentive: {
        name: {
          id: 'app.components.DataTable.analysis.card.incentive.name',
          defaultMessage: '{ name }',
        },
        current: {
          id: 'app.components.DataTable.analysis.card.incentive.currentYear',
          defaultMessage: '{ name }',
        },
        grossChargeVolume: {
          id: 'app.components.DataTable.analysis.card.incentive.grossChargeVolume',
          defaultMessage: 'Gross Charge Volume',
        },
        cashVolume: {
          id: 'app.components.DataTable.analysis.card.incentive.cashVolume',
          defaultMessage: 'Cash Volume',
        },
        latePaymentFees: {
          id: 'app.components.DataTable.analysis.card.incentive.latePaymentFees',
          defaultMessage: 'Late Payment Fees',
        },
        netChargeVolume: {
          id: 'app.components.DataTable.analysis.card.incentive.netChargeVolume',
          defaultMessage: 'Net Charge Volume',
        },
        grossIncentive: {
          id: 'app.components.DataTable.analysis.card.incentive.grossIncentive',
          defaultMessage: 'Gross Incentive',
        },
        creditLoss: {
          id: 'app.components.DataTable.analysis.card.incentive.creditLoss',
          defaultMessage: 'Credit Loss',
        },
        recoveries: {
          id: 'app.components.DataTable.analysis.card.incentive.recoveries',
          defaultMessage: 'Recoveries',
        },
        netIncentive: {
          id: 'app.components.DataTable.analysis.card.incentive.netIncentive',
          defaultMessage: 'Net Incentive',
        },
        incentivePayment: {
          id: 'app.components.DataTable.analysis.card.incentive.incentivePayment',
          defaultMessage: 'Incentive Payment',
        },
        
      },
      leakage: {
        common: {
          name: {
            id: 'app.components.DataTable.analysis.card.leakage.name',
            defaultMessage: '{ name }',
          },
          current: {
            id: 'app.components.DataTable.analysis.card.leakage.current',
            defaultMessage: '{ name }'
          },
          previous: {
            id: 'app.components.DataTable.analysis.card.leakage.previous',
            defaultMessage: '{ name }'
          },
          variance: {
            id: 'app.components.DataTable.analysis.card.leakage.variance',
            defaultMessage: 'Variance',
          },
          nonPreferAmount: {
            id: 'app.components.DataTable.analysis.card.leakage.nonPreferAmount',
            defaultMessage: 'Non-preferred Charge Volume',
          },
          merchantNotAcceptAmount: {
            id: 'app.components.DataTable.analysis.card.leakage.merchantNotAcceptAmount',
            defaultMessage: 'Merchant non-acceptance of Corporate Card',
          },
          nonUsageWihtCardAmount: {
            id: 'app.components.DataTable.analysis.card.leakage.nonUsageWihtCardAmount',
            defaultMessage: 'Non-usage of Corporate Card with card',
          },
          nonUsageWihtOutCardAmount: {
            id: 'app.components.DataTable.analysis.card.leakage.nonUsageWihtOutCardAmount',
            defaultMessage: 'Non-usage of Corporate Card without card',
          },
          
        }
      },
    },
    meeting: {
      cventRFP: {
        common: {
          name: {
            id: 'app.components.DataTable.analyses.meeting.cvent.poCountry',
            defaultMessage: '{ name }',
          },
          totalSpend: {
            id: 'app.components.DataTable.analyses.meeting.cvent.totalSpend',
            defaultMessage: 'Total Spend',
          },
          totalEstimated: {
            id: 'app.components.DataTable.analyses.meeting.cvent.totalEstimated',
            defaultMessage: 'Total Estimated',
          },
          totalContracted: {
            id: 'app.components.DataTable.analyses.meeting.cvent.totalContracted',
            defaultMessage: 'Total Contracted',
          },
          variance: {
            id: 'app.components.DataTable.analyses.meeting.cvent.variance',
            defaultMessage: 'Estimated - Contracted',
          },
          totalEvents: {
            id: 'app.components.DataTable.analyses.meeting.cvent.totalEvents',
            defaultMessage: 'Total Events',
          },
          eventNumCancelledBefore: {
            id: 'app.components.DataTable.analyses.meeting.cvent.eventNumCancelledBefore',
            defaultMessage: 'Events (Cancelled before Contract)',
          },
          eventNmCancelledAfter: {
            id: 'app.components.DataTable.analyses.meeting.cvent.eventNmCancelledAfter',
            defaultMessage: 'Events (Cancelled after Contract)',
          },
          eventCompleted: {
            id: 'app.components.DataTable.analyses.meeting.cvent.eventNmCancelledAfter',
            defaultMessage: 'Events (Completed)',
          },
        }
      },
      cventSupplier: {
        common: {
          name: {
            id: 'app.components.DataTable.analyses.meeting.cventSupplier.name',
            defaultMessage: 'Supplier Chain',
          },
          nights: {
            id: 'app.components.DataTable.analyses.meeting.cventSupplier.nights',
            defaultMessage: 'Nights',
          },
          totalRFP: {
            id: 'app.components.DataTable.analyses.meeting.cventSupplier.totalRFP',
            defaultMessage: 'Total RFPs Sent',
          },
          numOfNoReply: {
            id: 'app.components.DataTable.analyses.meeting.cventSupplier.numOfNoReply',
            defaultMessage: 'No Reply',
          },
          numOfHotelDeclined: {
            id: 'app.components.DataTable.analyses.meeting.cventSupplier.numOfHotelDeclined',
            defaultMessage: 'Hotel declined',
          },
          numOfResponsedButNotContracted: {
            id: 'app.components.DataTable.analyses.meeting.cventSupplier.numOfResponsedButNotContracted',
            defaultMessage: 'Responded but not contracted',
          },
          numOfContracted: {
            id: 'app.components.DataTable.analyses.meeting.cventSupplier.numOfContracted',
            defaultMessage: 'Contracted',
          },
        }
      },
      meetingRate: {
        name: {
          id: 'app.components.DataTable.analysis.hotel.meetingRate.name',
          defaultMessage: '{ name }',
        },
        totalSpend: {
          id: 'app.components.DataTable.analysis.hotel.meetingRate.totalSpend',
          defaultMessage: 'Total Spend',
        },
        nights: {
          id: 'app.components.DataTable.analysis.hotel.meetingRate.nights',
          defaultMessage: 'Nights',
        },
        totalTrans: {
          id: 'app.components.DataTable.analysis.hotel.meetingRate.totalTrans',
          defaultMessage: 'Transactions',
        },
        avgRate: {
          id: 'app.components.DataTable.analysis.hotel.meetingRate.avgRate',
          defaultMessage: 'Avg. Rate',
        },
        avgStay: {
          id: 'app.components.DataTable.analysis.hotel.meetingRate.avgStay',
          defaultMessage: 'Avg. Stay',
        }
      }
    }
  },
  admin: {
    hotelmatching: {
      hotelName: {
        id: 'app.components.DataTable.admin.hotelmatching.hotelName',
        defaultMessage: '{ name }',
      },
      hotelChain: {
        id: 'app.components.DataTable.admin.hotelmatching.hotelChain',
        defaultMessage: 'Hotel Chain',
      },
      hotelGroup: {
        id: 'app.components.DataTable.admin.hotelmatching.hotelGroup',
        defaultMessage: 'Hotel Group',
      },
      address: {
        id: 'app.components.DataTable.admin.hotelmatching.address',
        defaultMessage: 'Hotel Address',
      },
      dataSource: {
        id: 'app.components.DataTable.admin.hotelmatching.dataSource',
        defaultMessage: 'Data Source',
      },
      spend: {
        id: 'app.components.DataTable.admin.hotelmatching.spend',
        defaultMessage: 'Spend',
      },
      paymentForm: {
        id: 'app.components.DataTable.admin.hotelmatching.paymentForm',
        defaultMessage: 'Payment Form',
      },
      transactions: {
        id: 'app.components.DataTable.admin.hotelmatching.transactions',
        defaultMessage: 'Transactions',
      },
      city: {
        id: 'app.components.DataTable.admin.hotelmatching.city',
        defaultMessage: 'Expense Hotel Location',
      },
      state: {
        id: 'app.components.DataTable.admin.hotelmatching.city.state',
        defaultMessage: 'City',
      },
      country: {
        id: 'app.components.DataTable.admin.hotelmatching.city.country',
        defaultMessage: 'Country',
      },
      cardHotelName: {
        id: 'app.components.DataTable.admin.hotelmatching.cardHotelName',
        defaultMessage: 'Card Hotel Name',
      },
      cardHotelCity: {
        id: 'app.components.DataTable.admin.hotelmatching.cardHotelCity',
        defaultMessage: 'Card Hotel Location',
      },
      cardHotelAddress: {
        id: 'app.components.DataTable.admin.hotelmatching.cardHotelAddress',
        defaultMessage: 'Card Hotel Address',
      },
      masterHotelName: {
        id: 'app.components.DataTable.admin.hotelmatching.masterHotelName',
        defaultMessage: 'Master Hotel Name',
      },
      masterHotelCity: {
        id: 'app.components.DataTable.admin.hotelmatching.masterHotelCity',
        defaultMessage: 'Master Hotel Location',
      },
      masterHotelState: {
        id: 'app.components.DataTable.admin.hotelmatching.masterHotelCity.state',
        defaultMessage: 'City',
      },
      masterHotelCountry: {
        id: 'app.components.DataTable.admin.hotelmatching.masterHotelCity.country',
        defaultMessage: 'Country',
      },
      cardHotelState: {
        id: 'app.components.DataTable.admin.hotelmatching.masterHotelCity.state',
        defaultMessage: 'City',
      },
      cardHotelCountry: {
        id: 'app.components.DataTable.admin.hotelmatching.masterHotelCity.country',
        defaultMessage: 'Country',
      },
      masterHotelAddress: {
        id: 'app.components.DataTable.admin.hotelmatching.masterHotelAddress',
        defaultMessage: 'Master Hotel Address',
      },
      cardHotelChain: {
        id: 'app.components.DataTable.admin.hotelmatching.cardHotelChain',
        defaultMessage: 'Card Hotel Chain',
      },
      cardHotelVendorNumber: {
        id: 'app.components.DataTable.admin.hotelmatching.cardHotelVendorNumber',
        defaultMessage: 'Card Hotel Vendor Number',
      },
      matchingRule: {
        id: 'app.components.DataTable.admin.hotelmatching.matchingRule',
        defaultMessage: 'Matching Criteria',
      },
      vendorNumber: {
        id: 'app.components.DataTable.admin.hotelmatching.vendorNumber',
        defaultMessage: 'Expense Vendor Number',
      },
      masterHotelChain: {
        id: 'app.components.DataTable.admin.hotelmatching.masterHotelChain',
        defaultMessage: 'Master Hotel Chain',
      },
      masterHotelLanyonId: {
        id: 'app.components.DataTable.admin.hotelmatching.masterHotelLanyonId',
        defaultMessage: 'Master Hotel Lanyon id',
      },
      matchingScore: {
        id: 'app.components.DataTable.admin.hotelmatching.matchingScore',
        defaultMessage: 'Matching Score',
      },
      matchingComment: {
        id: 'app.components.DataTable.admin.hotelmatching.matchingComment',
        defaultMessage: 'Matching Comment',
      },
      validated: {
        id: 'app.components.DataTable.admin.hotelmatching.validated',
        defaultMessage: 'Approved',
      },
      lanyonId: {
        id: 'app.components.DataTable.admin.hotelmatching.lanyonId',
        defaultMessage: 'Lanyon Id',
      },
      validatedBy: {
        id: 'app.components.DataTable.admin.hotelmatching.validatedBy',
        defaultMessage: 'Validated By',
      },
      validateDate: {
        id: 'app.components.DataTable.admin.hotelmatching.validateDate',
        defaultMessage: 'Validated Date',
      },
    },
    hotelalias: {
      masterHotelName: {
        id: 'app.components.DataTable.admin.hotelalias.masterHotelName',
        defaultMessage: 'Master Hotel Name',
      },
      masterHotelAddress: {
        id: 'app.components.DataTable.admin.hotelalias.masterHotelAddress',
        defaultMessage: 'Master Hotel Address',
      },
      masterHotelCity: {
        id: 'app.components.DataTable.admin.hotelalias.masterHotelCity',
        defaultMessage: 'Master Hotel City',
      },
      hotelAliasName: {
        id: 'app.components.DataTable.admin.hotelalias.hotelAliasName',
        defaultMessage: 'Hotel Alias Name',
      },
      hotelAliasAddress: {
        id: 'app.components.DataTable.admin.hotelalias.hotelAliasAddress',
        defaultMessage: 'Hotel Alias Address',
      },
      hotelAliasCity: {
        id: 'app.components.DataTable.admin.hotelalias.hotelAliasCity',
        defaultMessage: 'Hotel Alias City',
      },
      status: {
        id: 'app.components.DataTable.admin.hotelalias.hotelAliasStatus',
        defaultMessage: 'Status',
      },
      lastAppliedTime: {
        id: 'app.components.DataTable.admin.hotelalias.hotelAliasLastAppliedTime',
        defaultMessage: 'Updated Time',
      },
    },
  },
  validation:{
    dataValidation:{
      dataSource: {
        id: 'app.components.DataTable.admin.dataValidation.dataSource',
        defaultMessage: 'Data Source',
      },
      subCategory: {
        id: 'app.components.DataTable.admin.dataValidation.subCategory',
        defaultMessage: 'Sub Category',
      },
      totalCount: {
        id: 'app.components.DataTable.admin.dataValidation.totalCount',
        defaultMessage: 'Total Count',
      },
      totalSpend: {
        id: 'app.components.DataTable.admin.dataValidation.totalSpend',
        defaultMessage: 'Total Spend',
      },
      matchedTotalSpend: {
        id: 'app.components.DataTable.admin.dataValidation.matchedTotalSpend',
        defaultMessage: 'Matched Spend',
      },
      unmatchedTotalSpend: {
        id: 'app.components.DataTable.admin.dataValidation.unmatchedTotalSpend',
        defaultMessage: 'Unmatched Spend',
      },
      source: {
        id: 'app.components.DataTable.admin.dataValidation.source',
        defaultMessage: 'Source - Raw',
      },
      target: {
        id: 'app.components.DataTable.admin.dataValidation.target',
        defaultMessage: 'Target',
      },
      category: {
        id: 'app.components.DataTable.admin.dataValidation.category',
        defaultMessage: 'Category',
      },
      expenseAmount: {
        id: 'app.components.DataTable.admin.dataValidation.expenseAmount',
        defaultMessage: 'Expense',
      },
      cardAmount: {
        id: 'app.components.DataTable.admin.dataValidation.cardAmount',
        defaultMessage: 'Card',
      },
      bookingAmount: {
        id: 'app.components.DataTable.admin.dataValidation.bookingAmount',
        defaultMessage: 'Booking',
      },
      supplierAmount: {
        id: 'app.components.DataTable.admin.dataValidation.supplierAmount',
        defaultMessage: 'Supplier',
      },
      notPassedMonth: {
        id: 'app.components.DataTable.admin.dataValidation.notPassedMonth',
        defaultMessage: 'Month',
      },
      type: {
        id: 'app.components.DataTable.admin.dataValidation.type',
        defaultMessage: 'Failed Validation Type',
      },
      dataSource: {
        id: 'app.components.DataTable.admin.dataValidation.dataSource',
        defaultMessage: 'Data Source',
      },
    }
  }
});
