import { handleActions } from 'redux-actions';
import { saveScenarioModalClose, saveScenarioModalOpen } from './actions';

export const initialState = {
  open: false,
};

const saveScenarioModalReducer = handleActions({
  [saveScenarioModalOpen]: () => ({ open: true }),
  [saveScenarioModalClose]: () => ({ open: false }),
}, initialState);

export default saveScenarioModalReducer;
