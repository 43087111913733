import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { alertsRequest, automatedAlertsRequest, activitiesRequest } from './actions';

export const alertsState = {
  alerts: [],
  activities: [],
  automatedAlerts: [],
};

const alertsContainerReducer = handleActions({
  [alertsRequest.SUCCESS]: (state, { payload: data }) => update(state, {
    alerts: { $set: data },
  }),
  [automatedAlertsRequest.SUCCESS]: (state, { payload: data }) => update(state, {
    automatedAlerts: { $set: data },
  }),
  [activitiesRequest.SUCCESS]: (state, { payload: data }) => update(state, {
    activities: { $set: data },
  }),
}, alertsState);

export default alertsContainerReducer;
