import { handleActions } from 'redux-actions';
import { feedbackModalClose, feedbackModalOpen, npsModalOpen, npsModalClose } from './actions';

export const initialState = {
  open: false,
  npsOpen: false,
};

const feedbackModalReducer = handleActions({
  [feedbackModalOpen]: (state) => ({ ...state, open: true }),
  [feedbackModalClose]: (state) => ({ ...state, open: false }),
  [npsModalOpen]: (state) => ({ ...state, npsOpen: true }),
  [npsModalClose]: (state) => ({ ...state, npsOpen: false }),
}, initialState);

export default feedbackModalReducer;
