import React from 'react';
import { FormattedMessage } from 'react-intl';
import { takeEvery, select, call, put, fork, takeLatest } from 'redux-saga/effects';
import { stringify } from 'qs';
import { replacePageURLKey } from 'Utils/constants';
import fetch from 'Utils/fetch';
import { makeSelectPageName } from 'Containers/PageContainer/selectors';
import { makeSelectCurrentLocation } from 'Containers/App/selectors';
import showNotification from 'Components/ToastComponent/actions';
import { tableDataRequest } from './actions';
import { API_PATH } from './constants';
import messages from './messages';

function* fetchData(API_PATH, { params }, page) {
/* it used to send all data in query string and it turned out we got 414 error sometimes if we sent too long string
 * so we planned to
 * 1. move request data from query string to request body
 * 2. request method from GET to POST
 * */
    const { filters, pagination, page:pg, scenario, ...rest } = params;
    if (pagination.sortBy) {
      pagination.sortBy = window.encodeURIComponent(pagination.sortBy);
    }
    const bodyData = { ...rest,
                      page: replacePageURLKey(pg),
                      filters: JSON.stringify(filters),
                      scenario: JSON.stringify(scenario),
                      pagination: JSON.stringify(pagination) };    
    const data = yield call(
          fetch,
          API_PATH,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'page': bodyData.page
            },
            body: stringify(bodyData)
          });
    if (data) {
      if (data.totalTableRow) {
        const total = { ...data.totalTableRow };
        data.rows.unshift(total);
      }
      if (page.indexOf('/admin/hotelmatching/list') > 0) {
        data.rows.forEach(k => {
          if (k.validateDate && k.validateDate.length > 10) {
            k.validateDate = k.validateDate.substr(0,10);
          }
        })
      }
      yield put(tableDataRequest.success({ page, data }));
    }
}

function* getTableData({ payload: params }) {
  let { page } = params;
  if (!page) {
    page = yield select(makeSelectCurrentLocation);
  }
  try {
    yield fork(fetchData, API_PATH, { params }, page);
  } catch (error) {
    const name = yield select(makeSelectPageName);
    yield put(showNotification({
      title: <FormattedMessage { ...messages.notifications.dataRequest.error.title } />,
      body: (
        <FormattedMessage
          { ...messages.notifications.dataRequest.error.body }
          values={{ page: <strong>{ name }</strong> }}
        />
      ),
      timeout: 5000,
      kind: 'error',
    }));
    yield put(tableDataRequest.failure({ page, error }));
  }
}

// Individual exports for testing
export default function* defaultSaga() {
  yield takeEvery(tableDataRequest.REQUEST, getTableData);
}

/* eslint-disable redux-saga/yield-effects */
export const tableSagas = [
  takeLatest(tableDataRequest.REQUEST, getTableData),
];
/* eslint-enable redux-saga/yield-effects */
