import { defineMessages } from 'react-intl';

export default defineMessages({
  notifications: {
    dataRequest: {
      error: {
        title: {
          id: 'app.components.Notifications.messages.Scenarios.dataRequest.error.title',
          defaultMessage: 'Scenarios error',
        },
        body: {
          id: 'app.components.Notifications.messages.Scenarios.dataRequest.error.body',
          defaultMessage: `Failed to fetch scenarios for page { name }. Please contact an
          administrator about this issue.`,
        },
      },
    },
    optionsRequest: {
      error: {
        title: {
          id: 'app.components.Notifications.messages.Scenarios.optionsRequest.error.title',
          defaultMessage: 'Unable to create scenarios',
        },
        body: {
          id: 'app.components.Notifications.messages.Scenarios.optionsRequest.error.body',
          defaultMessage: `Scenario options failed to fetch for page { name }. Please contact an
          administrator about this issue.`,
        },
      },
    },
  },
});
