import { handleActions } from 'redux-actions';
import { exportPageModalClose, exportPageModalOpen } from './actions';

const initialState = {
  open: false,
  
};

const exportPageModalReducer = handleActions({
  [exportPageModalOpen]: () => ({ open: true }),
  [exportPageModalClose]: () => ({ open: false }),
}, initialState);

export default exportPageModalReducer;
