export const ALERT_OPTIONS_CLEAR = 'app/CreateAlertModal/ALERT_OPTIONS_CLEAR';
export const ALERT_MODAL_OPEN = 'app/CreateAlertModal/ALERT_MODAL_OPEN';
export const ALERT_MODAL_CLOSE = 'app/CreateAlertModal/ALERT_MODAL_CLOSE';
export const COMPONENT_OPTIONS_REQUEST = 'app/CreateAlertModal/COMPONENT_OPTIONS_REQUEST';
export const ALERT_FIELDS_MAP = {
  metrics: 'metric',
  targets: 'target',
  operators: 'operator',
  amount: 'value',
  dateRange: 'dateRange',
};
