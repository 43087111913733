import { defineMessages } from 'react-intl';

export const formMessages = defineMessages({
  username: {
    id: 'app.components.Login.username',
    defaultMessage: 'Email address',
  },
  password: {
    id: 'app.components.Login.password',
    defaultMessage: 'Password',
  },
  usernamePlaceholder: {
    id: 'app.components.Login.email.placeholder',
    defaultMessage: 'Enter your email address',
  },
  passwordPlaceholder: {
    id: 'app.components.Login.email.placeholder',
    defaultMessage: 'Enter your password',
  },
});

export const errorMessages = defineMessages({
  missing: {
    username: {
      id: 'app.components.Login.missing.username',
      defaultMessage: `Email field can't be empty`,
    },
    password: {
      id: 'app.components.Login.missing.username',
      defaultMessage: `Password field can't be empty`,
    },
  },
  invalid: {
    username: {
      id: 'app.components.Login.invalid.username',
      defaultMessage: `That is not a valid email address!`,
    },
  },
  credentials: {
    id: 'app.components.Login.errors.credentials',
    defaultMessage: `Invalid credentials. Please try again.`,
  },
  session: {
    id: 'app.components.Login.errors.session',
    defaultMessage: `Your session expired. Please login again.`,
  },
  server: {
    id: 'app.components.Login.errors.server',
    defaultMessage: `Server internal error. Please try logging in again and inform an
    administrator if the issue persists.`,
  },
});

export default defineMessages({
  title: {
    id: 'app.components.Login.title',
    defaultMessage: 'Login',
  },
  signin: {
    id: 'app.components.Login',
    defaultMessage: 'Sign In',
  },
});
