export const MULTIPLE_FILTERS_REQUEST = 'app/FiltersContainer/MULTIPLE_FILTERS_REQUEST';
export const FILTERS_REQUEST = 'app/FiltersContainer/FILTERS_REQUEST';
export const FILTERS_SUCCESS = 'app/FiltersContainer/FILTERS_SUCCESS';
export const FILTERS_UPDATE = 'app/FiltersContainer/FILTERS_UPDATE';
export const FILTERS_REMOVE = 'app/FiltersContainer/FILTERS_REMOVE';
export const FILTERS_FAIL = 'app/FiltersContainer/FILTERS_FAIL';
export const FILTER_REQUEST = 'app/FiltersContainer/FILTER_REQUEST';
export const FILTER_SUCCESS = 'app/FiltersContainer/FILTER_SUCCESS';
export const FILTER_FAIL = 'app/FiltersContainer/FILTER_FAIL';
export const FILTERS_SET_ACTIVE = 'app/FiltersContainer/FILTERS_SET_ACTIVE';
export const FILTER_CLEAR_ACTIVE = 'app/FiltersContainer/FILTER_CLEAR_ACTIVE';
export const FILTERS_CLEAR_ACTIVE = 'app/FiltersContainer/FILTERS_CLEAR_ACTIVE';

export const FILTER_TYPE_MULTI_SELECT = 'MULTI_SELECT';
export const FILTER_TYPE_SINGLE_SELECT = 'SINGLE_SELECT';
export const FILTER_TYPE_LOCATION = 'LOCATION';
export const FILTER_TYPE_DATE = 'DATE';
export const FILTER_TYPE_SELECT_DEFAULT_VALUE = 'ALL';
export const FILTER_TYPE_LOCATION_DEFAULT_VALUE = 'geo';
export const FILTER_TYPE_SIMPLE_DATE = 'SIMPLE_DATE';
export const FILTER_TYPE_COMPARATION_DATE = 'MULTI_DATE_COMPARATION';
export const FILTER_TYPE_CASCADE_SELECTION = 'CASCADE_SELECTION';
export const FILTER_TYPE_AIRLINE_DEFAULT_VALUE = 'DELTA AIR LINES INC';
export const API_PATH = '/api/filters';
