import { injectGlobal } from 'emotion';

/* eslint no-unused-expressions: 0 */
injectGlobal`
  html,
  body {
    min-height: 100%;
  }

  body {
    font-family: 'IBM Plex Sans', 'ibm-plex-sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1rem;
    background-color: #fff;
    min-height: 100vh;
    min-width: 1024px;
  }
`;
