import { handleActions } from 'redux-actions';
import {
  scenarioLibraryModalOpen,
  scenarioLibraryModalClose,
} from './actions';

export const initialState = {
  open: false,
};

const scenarioLibraryModalReducer = handleActions({
  [scenarioLibraryModalOpen]: () => ({ open: true }),
  [scenarioLibraryModalClose]: () => ({ open: false }),
}, initialState);

export default scenarioLibraryModalReducer;
