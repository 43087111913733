import { createAction } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';
import {
  SCENARIOS_TYPE_REQUEST,
  SCENARIOS_SET_ACTIVE,
  SCENARIOS_CLEAR_ACTIVE,
  SCENARIOS_OPTIONS_REQUEST,
  SCENARIOS_OPTIONS_CLEAR
} from './constants';

export const scenariosTypeRequest = createFormAction(SCENARIOS_TYPE_REQUEST);
export const scenarioOptionsRequest = createFormAction(SCENARIOS_OPTIONS_REQUEST);
export const scenarioOptionsClear = createAction(SCENARIOS_OPTIONS_CLEAR);
export const scenariosSetActive = createAction(SCENARIOS_SET_ACTIVE);
export const scenariosClearActive = createAction(SCENARIOS_CLEAR_ACTIVE, page => ({ page }));
