import { handleActions } from 'redux-actions';
import { pageLogicViewModalOpen, pageLogicViewModalClose } from './actions';

export const initialState = {
  open: false,
};

const pageLogicViewModalReducer = handleActions({
  [pageLogicViewModalOpen]: () => ({ open: true }),
  [pageLogicViewModalClose]: () => ({ open: false }),
}, initialState);

export default pageLogicViewModalReducer;
