import { defineMessages } from 'react-intl';

export default {
  notifications: {
    data: {
      warning: defineMessages({
        title: {
          id: 'app.components.Notifications.messages.pages.warnings.data.title',
          defaultMessage: `Page Data Empty Response`,
        },
        body: {
          id: 'app.components.Notifications.messages.pages.warnings.data.body',
          defaultMessage: `There is no data available for the input you provided. Please try
          changing the filters`,
        },
      }),
      error: defineMessages({
        title: {
          id: 'app.components.Notifications.messages.pages.errors.data.title',
          defaultMessage: `Page Data Request Error`,
        },
        body: {
          id: 'app.components.Notifications.messages.pages.errors.data.body',
          defaultMessage: `There was an error requesting data for page { page }. Please try again or 
          contact an administrator if the issue persists`,
        },
      }),
      success: defineMessages({
        title: {
          id: 'app.components.Notifications.messages.pages.success.data.title',
          defaultMessage: `Success`,
        },
        body: {
          id: 'app.components.Notifications.messages.pages.success.data.body',
          defaultMessage: `The monthly report mail send sucessfully!`,
        },
      }),
    },
    settings: {
      save: {
        error: defineMessages({
          title: {
            id: 'app.components.Notifications.messages.pages.settings.errors.save.title',
            defaultMessage: 'Page Settings Save Error',
          },
          body: {
            id: 'app.components.Notifications.messages.pages.settings.errors.save.body',
            defaultMessage: 'There was an error saving the settings for { page }. Please try again',
          },
        }),
      },
      request: {
        error: defineMessages({
          title: {
            id: 'app.components.Notifications.messages.pages.settings.errors.request.title',
            defaultMessage: 'Page Settings',
          },
          body: {
            id: 'app.components.Notifications.messages.pages.settings.errors.request.body',
            defaultMessage: 'There was an error requesting the settings for { page }. Please try again',
          },
        }),
      },
    },
    state: defineMessages({
      title: {
        id: 'app.components.Notifications.messages.pages.info.state.title',
        defaultMessage: 'State Applied',
      },
      body: {
        id: 'app.components.Notifications.messages.pages.info.state.body',
        defaultMessage: 'State from URL was successfully applied.',
      },
    }),
  },
};
