import { Map, fromJS, List } from 'immutable';
import update from 'immutability-helper';
import { isPlainObject, reduce } from 'lodash-es';
import deepmerge from 'deepmerge';
import merge from 'lodash/fp/merge';
import { LOCATION_CHANGE } from 'connected-react-router';
import { handleActions, combineActions } from 'redux-actions';
import {
  alertSetActive,
  alertClearActive,
} from 'Pages/AlertsPage/actions';
import {
  scenariosSetActive,
  scenariosClearActive,
  scenariosTypeRequest,
} from 'Containers/ScenariosContainer/actions';
import updateObject from 'Utils/updateObject';
import { mergeDeepOverwriteLists } from 'Utils/immutable';
import * as filterActions from 'Containers/FiltersContainer/actions';
import * as viewActions from 'Containers/ViewsContainer/actions';
import * as breadCrumbActions from 'Components/Breadcrumbs/actions';
import { tableDataRequest } from 'Containers/DataTableContainer/actions';
import * as chartActions from 'Containers/ChartContainer/actions';
import * as topVisualActions from 'Containers/TopVisualContainer/actions';
import * as pageActions from './actions';
import * as pageHeaderActions from 'Containers/PageHeaderContainer/actions';

export const pageState = {
  activeAlert: null,
  activeScenario: null,
  activeView: null,
  key: null,
  path: null,
  name: null,
  canCreateAlert: false,
  title: { id: 'app.pages.loading.title', defaultMessage: ' ' },
  data: {
    data: Map(),
    errored: false,
  },
  topVisualData: {
    data: Map(),
    noData: false,
    errored: false,
  },
  chartData: {
    data: Map(),
    noData: false,
    errored: false,
  },
  tableData: {
    totalRows: null,
    columns: {},
    rows: List(),
    noData: false,
    errored: false,
    isSearching: false,
    noFilters: false,
  },
  filters: Map(),
  localFilters: Map(),
  views: [],
  scenarios: {
    public: [],
    personal: [],
  },
  breadcrumbs: [],
  formaters: {
    // Proprietary page option helpers used in various parts of the apps
    scenarioMessage: msg => {
      console.error('Method not implemented');
      return msg;
    },
  },
  settings: {
    hasCountries: true,
    disabledFilters: [],
    charts: {},
    table: {},
  },
  params: {
    find: '',
  },
  pageLogic: [],
};

export const initialState = {
  pendingRegisterUsers: 0,
};

const pageContainerReducer = handleActions({
  [LOCATION_CHANGE]: (state, { payload: { location: { pathname: page } } }) => {
    if (!Reflect.has(state, page) && page.match(/dashboard\/|analysis\//)) {
      return update(state, { $set: { [page]: pageState } });
    }
    return state;
  },
  [pageActions.pagesClear]: (state, { payload: match = '' }) => {
    if (match !== '') {
      const pages = reduce(state, (acc, page, key) => {
        if (key.includes(match)) {
          acc.push(key);
        }
        return acc;
      }, []);
      if (pages.length) {
        return update(state, { $unset: pages });
      }
    }
    return initialState;
  },
  [pageActions.pageClear]: (state, { payload: { page } }) => update(state, { $unset: [page] }),
  // Props
  [pageActions.pagePropsUpdate]: (state, { payload: { page, payload } }) => {
    if (payload?.find !== undefined && state[page]?.params.find !== payload?.find) {
      Object.assign(payload, {
        tableData: update(
          state[page].tableData, {
            $merge: { isSearching: state[page]?.params.find !== payload?.find },
          }),
      });
    }
    return update(state, {
      [page]: {
        $set: deepmerge(
          merge(pageState, state[page]), payload, { isMergeableObject: isPlainObject }
        ),
      },
    });
  },
  // Page Data
  [pageActions.pageDataRequest.SUCCESS]: (state, { payload: { payload: { page, data } } }) => {
    if (!state[page]) {
      return update(state, {
        $merge: {
          [page]: { data: updateObject(pageState.data, { data: fromJS(data) }) },
        },
      });
    }
    if (!state[page]?.data) {
      return update(state, {
        [page]: {
          data: {
            $set: updateObject(pageState.data, { data: fromJS(updateObject(data || {})) })
          },
        },
      });
    }
    return update(state, {
      [page]: {
        data: {
          $merge: updateObject(pageState.data, { data: fromJS(updateObject(data || {})) })
        },
      },
    });
  },
  [pageActions.pageDataRequest.FAIL]: (state, { payload: { page } }) => {
    if (!state[page]) {
      update(state, {
        $merge: {
          [page]: { $set: updateObject(pageState.data, { errored: true }) },
        },
      });
    }
    return update(state, {
      [page]: { $set: updateObject(pageState.data, { errored: true }) },
    });
  },
  // Chart Data
  [chartActions.dataRequestSuccess]: (state, { payload: { page, data } }) => {
    const noData = Reflect.ownKeys(updateObject(data || {})).length === 0;
    if (!state[page]) {
      return update(state, {
        $merge: {
          [page]: {
            chartData:
              updateObject(pageState.chartData, { data: fromJS(updateObject(data)), noData }),
          },
        },
      });
    }
    return update(state, {
      [page]: {
        chartData: {
          $set: updateObject(pageState.chartData, { data: fromJS(updateObject(data)), noData }),
        },
      },
    });
  },
  [chartActions.dataRequestFail]: (state, { payload: { page } }) => {
    if (!state[page]) {
      return update(state, {
        $merge: {
          [page]: {
            chartData: { $set: updateObject(pageState.chartData, { errored: true }) },
          },
        },
      });
    }
    return update(state, {
      [page]: { chartData: { $set: updateObject(pageState.chartData, { errored: true }) } },
    });
  },
  // Top Visual Data
  [topVisualActions.dataRequestSuccess]: (state, { payload: { page, data } }) => {
    const noData = Reflect.ownKeys(updateObject(data || {})).length === 0;
    if (!state[page]) {
      return update(state, {
        $merge: {
          [page]: {
            topVisualData: updateObject(
              pageState.topVisualData,
              { data: fromJS(updateObject(data)), noData },
            ),
          },
        },
      });
    }
    return update(state, {
      [page]: {
        topVisualData: {
          $set: updateObject(pageState.topVisualData, { data: fromJS(updateObject(data)), noData }),
        },
      },
    });
  },
  [topVisualActions.dataRequestFail]: (state, { payload: { page } }) => {
    if (!state[page]) {
      return update(state, {
        $merge: {
          [page]: {
            topVisualData: { $set: updateObject(pageState.topVisualData, { errored: true }) },
          },
        },
      });
    }
    return update(state, {
      [page]: { topVisualData: { $set: updateObject(pageState.topVisualData, { errored: true }) } },
    });
  },
  // Table Data
  [tableDataRequest.SUCCESS]: (state, { payload: { page, data = {} } }) => {
    const { totalRows = 0, rows = [] } = data;
    const noData = rows.length === 0 && totalRows === 0;
    if (!state[page]) {
      return update(state, {
        $merge: {
          [page]: {
            tableData: updateObject(
              pageState.tableData,
              { ...data, totalRows, rows: fromJS(rows), noData, isSearching: false }
            ),
          },
        },
      });
    }
    return update(state, {
      [page]: {
        tableData: {
          $set: updateObject(
            pageState.tableData,
            { ...data, totalRows, rows: fromJS(rows), noData, isSearching: false }
          ),
        },
      },
    });
  },
  [tableDataRequest.FAILURE]: (state, { payload: { page } }) => {
    if (!state[page]) {
      return update(state, {
        $merge: {
          [page]: {
            tableData: {
              $set: updateObject(
                pageState.tableData, { errored: true, isSearching: false }
              ),
            },
          },
        },
      });
    }
    return update(state, {
      [page]: {
        tableData: {
          $set: updateObject(
            pageState.tableData, { errored: true, isSearching: false }
          ),
        },
      },
    });
  },
  // Params
  [pageActions.pageParamsUpdate]: (state, { payload: { page, payload: params } }) => {
    if (!state[page]) {
      return update(state, {
        $merge: {
          [page]: { params },
        },
      });
    }
    if (state[page]?.params.find !== params?.find) {
      return update(state, {
        [page]: {
          tableData: { $merge: { isSearching: state[page]?.params.find !== params?.find } },
          params: { $merge: updateObject(params || {}) },
        },
      });
    }
    return update(state, { [page]: { params: { $merge: updateObject(params || {}) } } });
  },
  [pageActions.pageParamsClear]: (state, page) => update(state, {
    [page]: { params: { $set: pageState.params } },
  }),
  // Settings
  [combineActions(pageActions.pageSettingsUpdate, pageActions.pageSettingsSave)]:
    (state, { payload: { page, data = {} } }) => {
      if (!state[page]) {
        return update(state, {
          $merge: {
            [page]: { settings: { $set: updateObject(data) } },
          },
        });
      }
      return update(state, {
        [page]: { settings: { $merge: updateObject(data) } },
      });
    },
  [pageActions.pageSettingsClear]: (state, page) => {
    if (!state[page]) {
      return update(state, {
        $merge: {
          [page]: { settings: { $set: pageState.settings } },
        },
      });
    }
    return update(state, {
      [page]: { settings: { $set: pageState.settings } },
    });
  },
  // Filters
  [combineActions(
    filterActions.filtersSuccess,
    filterActions.filterSuccess
  )]: (state, { payload: { page, data } }) => {
    if (!state[page]) {
      return update(state, {
        $merge: {
          [page]: { filters: mergeDeepOverwriteLists(state[page]?.localFilters || {}, fromJS(data)) },
        },
      });
    }
    if (!state[page].filters) {
      if (state[page]?.localFilters) {
        return update(state, {
          [page]: {
            filters: { $set: mergeDeepOverwriteLists(state[page]?.localFilters || {}, fromJS(data)) },
          },
        });
      }
      return update(state, {
        [page]: {
          filters: { $set: fromJS(data) },
        },
      });
    }
    if (state[page]?.localFilters) {
      const currentFilters = state[page].filters.merge(state[page]?.localFilters || {});
      return update(state, {
        [page]: {
          filters: { $set: mergeDeepOverwriteLists(currentFilters, fromJS(data)) },
        },
      });
    }
    return update(state, {
      [page]: {
        filters: { $set: fromJS(data) },
      },
    });
  },
  [filterActions.filtersUpdate]: (state, { payload: { page, data } }) => {
    if (!state[page]) {
      return update(state, {
        $merge: {
          [page]: { localFilters: updateObject(fromJS(data || {})) },
        },
      });
    }
    const currentFilters = state[page]?.localFilters || {};
    return update(state, {
      [page]: {
        $merge: {
          localFilters: mergeDeepOverwriteLists(currentFilters || Map(), fromJS(data) || Map())
        },
      },
    });
  },
  [filterActions.filtersRemove]: (state, { payload: { page, filters } }) => update(state, {
    [page]: { filters: { $set: state.filters.deleteAll([].concat(filters)) } },
  }),
  [filterActions.filtersFail]: (state, { payload: { page } }) => {
    if (!state[page]) {
      return update(state, {
        $merge: {
          [page]: { filters: { $set: pageState.filters } },
        },
      });
    }
    return update(state, {
      [page]: { filters: { $set: pageState.filters } },
    });
  },
  [filterActions.filterFail]: (state, { payload: { page, key } }) => {
    if (!state[page]) {
      return update(state, {
        $merge: {
          [page]: { filters: { $set: pageState.filters } },
        },
      });
    }
    return update(state, {
      [page]: { filters: { $set: state[page].filters.delete([key]) } },
    });
  },
  // Breadcrumbs
  [breadCrumbActions.breadcrumbSetActive]: (state, { payload: { page, breadcrumb } }) => {
    if (!state[page]) {
      return update(state, {
        $merge: {
          [page]: { breadcrumbs: breadcrumb },
        },
      });
    }
    return update(
      state, { [page]: { breadcrumbs: { $set: breadcrumb } } }
    );
  },
  [breadCrumbActions.breadcrumbRemoveActive]: (state, { payload: { page, idx } }) => update(state, {
    [page]: { breadcrumbs: { $splice: [[idx, state[page].breadcrumbs.length - idx]] } },
  }),
  [breadCrumbActions.breadcrumbClear]: (state, { payload: { page } }) => update(state, {
    [page]: { breadcrumbs: { $set: pageState.breadcrumbs } },
  }),
  // Alerts
  [alertSetActive]: (state, { payload: { page, data } }) => update(state, {
    [page]: {
      activeAlert: { $set: data },
      tableData: { $set: pageState.tableData },
      chartData: { $set: pageState.chartData },
      data: { $set: pageState.data },
      topVisualData: { $set: pageState.topVisualData },
    },
  }),
  [alertClearActive]: (state, { payload: { page } }) => update(state, {
    [page]: {
      activeAlert: { $set: pageState.activeAlert },
      tableData: { $set: pageState.tableData },
      chartData: { $set: pageState.chartData },
      data: { $set: pageState.data },
      topVisualData: { $set: pageState.topVisualData },
    },
  }),
  // Scenarios
  [scenariosTypeRequest.SUCCESS]: (state, { payload: { page, data } }) => {
    if (!state[page]) {
      return update(state, {
        $merge: { [page]: { scenarios: updateObject(data || {}) } },
      });
    }
    return update(state, { [page]: { scenarios: { $set: data } } });
  },
  [scenariosTypeRequest.FAIL]: (state, { payload: { page } }) => {
    if (!state[page]) {
      return update(state, {
        $merge: { [page]: { scenarios: { $set: pageState.scenarios } } },
      });
    }
    return update(state, {
      [page]: { scenarios: { $set: pageState.scenarios } },
    });
  },
  [scenariosSetActive]: (state, { payload: { page, data } }) => update(state, {
    [page]: {
      activeScenario: { $set: data },
      tableData: { $set: pageState.tableData },
      chartData: { $set: pageState.chartData },
      data: { $set: pageState.data },
      topVisualData: { $set: pageState.topVisualData },
    },
  }),
  [scenariosClearActive]: (state, { payload: { page } }) => update(state, {
    [page]: {
      activeScenario: { $set: pageState.activeScenario },
      tableData: { $set: pageState.tableData },
      chartData: { $set: pageState.chartData },
      data: { $set: pageState.data },
      topVisualData: { $set: pageState.topVisualData },
    },
  }),
  // Views
  [viewActions.viewsSuccess]: (state, { payload: { page, data } }) => {
    if (!state[page]) {
      return update(state, {
        $merge: { [page]: { views: { $set: data } } },
      });
    }
    return update(state, {
      [page]: { views: { $set: data } },
    });
  },
  [combineActions(
    viewActions.viewsClear,
    viewActions.viewsFail
  )]: (state, { payload: { page } }) => {
    if (!state[page]) {
      return update(state, {
        $merge: { [page]: { views: { $set: pageState.views } } },
      });
    }
    return update(state, {
      [page]: { views: { $set: pageState.views } },
    });
  },
  [viewActions.viewSetActive]: (state, { payload: { page, view } }) => update(state, {
    [page]: {
      activeView: { $set: view },
      tableData: { $set: pageState.tableData },
      chartData: { $set: pageState.chartData },
      data: { $set: pageState.data },
      topVisualData: { $set: pageState.topVisualData },
    },
  }),
  [viewActions.viewClearActive]: (state, { payload: { page } }) => update(state, {
    [page]: {
      activeView: { $set: pageState.activeView },
      tableData: { $set: pageState.tableData },
      chartData: { $set: pageState.chartData },
      data: { $set: pageState.data },
      topVisualData: { $set: pageState.topVisualData },
    },
  }),
  [pageHeaderActions.dataRequestSuccess]: (state, { payload: { page, data } }) => {
    if (!state[page]) {
      return update(state, {
        $merge: { [page]: { pageLogic: { $set: data } } },
      });
    }
    return update(state, {
      [page]: { pageLogic: { $set: data } },
    });
  },

  [pageActions.getPendingRegisterUserCountSuccess]: (state, { payload: { page, data } }) => {
    return update(state, {
      pendingRegisterUsers: { $set: data.total }
    });
  },

}, initialState);

export default pageContainerReducer;
