import { reducer } from 'redux-storage';
import { reducer as ui } from 'react-redux-ui-tools';
import { combineReducers } from 'redux';
import GDPRModalReducer from 'Components/GDPRModal/reducer';
import userReducer from 'Containers/UserContainer/reducer';
import pageReducer from 'Containers/PageContainer/reducer';
import authReducer from 'Pages/AuthPage/reducer';
import appStateReducer from 'Containers/App/reducer';
import alertsReducer from 'Pages/AlertsPage/reducer';
import alertModalReducer from 'Components/CreateAlertModal/reducer';
import feedbackModalReducer from 'Components/FeedbackModal/reducer';
import languageSwitcherModalReducer from 'Components/LanguageSwitcherModal/reducer';
import informationModalReducer from 'Components/InformationModal/reducer';
import createViewModalReducer from 'Components/CreateViewModal/reducer';
import createReportModalReducer from 'Components/CreateReportModal/reducer';
import shareStateModalReducer from 'Components/ShareStateModal/reducer';
import exportPageModalReducer from 'Components/ExportPageModal/reducer';
import viewPageInfoModalReducer from 'Components/ViewPageInfoModal/reducer';
import scenarioModalReducer from 'Components/EditScenarioModal/reducer';
import scenarioLibraryModalReducer from 'Components/ScenarioLibraryModal/reducer';
import focusRouteModalReducer from 'Components/FocusRouteModal/reducer';
import saveScenarioModalReducer from 'Components/SaveScenarioModal/reducer';
import notificationsReducer from 'Components/ToastComponent/reducer';
import pageLogicViewModalReducer from 'Components/PageLogicViewModal/reducer';

export default function createReducer(injectedReducers) {
  return reducer(
    combineReducers({
      user: userReducer,
      auth: authReducer,
      appState: appStateReducer,
      pagesData: pageReducer,
      alerts: alertsReducer,
      modalsEditScenario: scenarioModalReducer,
      modalsScenarioLibrary: scenarioLibraryModalReducer,
      modalsSaveScenario: saveScenarioModalReducer,
      modalsCreateAlert: alertModalReducer,
      modalsFeedback: feedbackModalReducer,
      modalsLanguageSwitcher: languageSwitcherModalReducer,
      modalsInformation: informationModalReducer,
      modalsShareState: shareStateModalReducer,
      modalsExportPage: exportPageModalReducer,
      modalsViewDataInfo: viewPageInfoModalReducer,
      modalsFocusRoute: focusRouteModalReducer,
      modalsCreateView: createViewModalReducer,
      modalsCreateReport: createReportModalReducer,
      notifications: notificationsReducer,
      modalsGDPR: GDPRModalReducer,
      ui,
      ...injectedReducers,
      modalsViewPageLogic: pageLogicViewModalReducer,
    })
  );
}
