import { createAction } from 'redux-actions';
import {
  FILTERS_REQUEST,
  FILTER_REQUEST,
  FILTERS_SUCCESS,
  FILTERS_UPDATE,
  FILTER_SUCCESS,
  FILTERS_FAIL,
  FILTER_FAIL,
  FILTERS_SET_ACTIVE,
  FILTERS_CLEAR_ACTIVE,
  FILTER_CLEAR_ACTIVE,
  FILTERS_REMOVE,
  MULTIPLE_FILTERS_REQUEST,
} from './constants';

export const filtersSetActive = createAction(
  FILTERS_SET_ACTIVE,
  filters => ({ filters })
);
export const filterClearActive = createAction(FILTER_CLEAR_ACTIVE);
export const filtersClearActive = createAction(FILTERS_CLEAR_ACTIVE);
export const multipleFiltersRequest = createAction(
  MULTIPLE_FILTERS_REQUEST, ({ page, data }) => ({ page, requestList: data })
);
export const filtersRequest = createAction(FILTERS_REQUEST);
export const filterRequest = createAction(
  FILTER_REQUEST,
  data => {
    const { path, page, params = null, key = null } = data;
    return { PATH: path, page, params, key };
  }
);
export const filtersUpdate = createAction(
  FILTERS_UPDATE,
  (page, data) => ({ page, data })
);
export const filtersSuccess = createAction(
  FILTERS_SUCCESS,
  (page, data) => ({ page, data })
);
export const filterSuccess = createAction(
  FILTER_SUCCESS,
  (page, data) => ({ page, data })
);
export const filtersRemove = createAction(
  FILTERS_REMOVE,
  (page, filters) => ({ page, filters })
);
export const filtersFail = createAction(
  FILTERS_FAIL,
  (page, error) => ({ page, error }),
  (page, name) => ({
    error: true,
    notify: true,
    page,
    name,
  }),
);
export const filterFail = createAction(
  FILTER_FAIL,
  (page, error) => ({ page, error }),
  (page, name) => ({
    error: true,
    notify: true,
    page,
    name,
  }),
);
