import React from 'react';
import { FormattedMessage } from 'react-intl';
import { stringify } from 'qs';
import { takeEvery, select, put, call, fork, takeLatest } from 'redux-saga/effects';
import fetch from 'Utils/fetch';
import { replacePageURLKey } from 'Utils/constants';
import logger from 'Utils/logger';
import { makeSelectPageName } from 'Containers/PageContainer/selectors';
import { makeSelectCurrentLocation } from 'Containers/App/selectors';
import showNotification from 'Components/ToastComponent/actions';
import { API_PATH, PAGE_LOGIC_DATA_REQUEST, DATA_SUCCESS, DATA_FAIL} from './constants';
import messages from './messages';

function* getComponentData({ payload: params }) {
  let { page } = params;

  try {
    
    const data = yield call(fetch, API_PATH, { params: { page },
      headers:{
        'page': '/api/data/getPageLogicInfo'
      } });
    yield put({ type: DATA_SUCCESS, payload: { page, data } });

  } catch (error) {
    const name = yield select(makeSelectPageName);
    yield put(showNotification({
      title: <FormattedMessage { ...messages.notifications.dataRequest.error.title } />,
      body: (
        <FormattedMessage
          { ...messages.notifications.dataRequest.error.body }
          values={{
            page: <strong>{ name }</strong>,
          }}
        />
      ),
      timeout: 5000,
      kind: 'error',
    }));
    yield put({ type: DATA_FAIL, payload: { page, error } });
    logger.error(error);
  }
}

export default function* defaultSagas() {
  yield takeEvery(PAGE_LOGIC_DATA_REQUEST, getComponentData);
}

export const getPageHeaderSaga = [
  takeLatest(PAGE_LOGIC_DATA_REQUEST, getComponentData),
];
