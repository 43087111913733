import { takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import fetch from 'Utils/fetch';
import logger from 'Utils/logger';
import { API_USER_INFO, API_GET_LOGIN, API_GET_LOGOUT, API_GET_USERREPORT } from './constants';
import { getUserRequest, getLogin, getLogout, getUserReport } from './actions';

function* fetchUser() {
  try {
    const user = yield call(
      fetch,
      API_USER_INFO,
      {
        method: 'GET',
        headers: {
          'page': '/api/user/getInfo'
        }
      });
    yield put(getUserRequest.success({ user }));
  } catch (error) {
    yield put(getUserRequest.failure({ error }));
    if (error?.payload?.action) {
      yield put(error.payload.action);
    }
    logger.error(error);
  }
}

function* getUserLogin() {
  try {
    yield call(
      fetch,
      API_GET_LOGIN,
      {
        method: 'GET',
        headers: {
          'page': '/api/data/getLogin'
        }
      });
  } catch (error) {
    logger.error(error);
  }
}

function* getUserLogout() {
  try {
    yield call(
      fetch,
      API_GET_LOGOUT,
      {
        method: 'GET',
        headers: {
          'page': '/api/data/getLogout'
        }
      });
  } catch (error) {
    logger.error(error);
  }
}

function* getUserInfo() {
  try {
    yield call(fetch, API_GET_USERREPORT);
  } catch (error) {
    logger.error(error);
  }
}

// Individual export for testing purposes
export default function* () {
  yield takeLatest(getUserRequest.REQUEST, fetchUser);
  yield takeLatest(getLogin.REQUEST, getUserLogin);
  yield takeLatest(getLogout.REQUEST, getUserLogout);
  yield takeLatest(getUserReport.REQUEST, getUserInfo);
}

/* eslint-disable redux-saga/yield-effects */
export const userSagas = [
  takeLatest(getUserRequest.REQUEST, fetchUser),
  takeLatest(getLogin.REQUEST, getUserLogin),
  takeLatest(getLogout.REQUEST, getUserLogout),
  takeLatest(getUserReport.REQUEST, getUserInfo),
];
/* eslint-enable redux-saga/yield-effects */
