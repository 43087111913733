import { handleActions } from 'redux-actions';
import { createViewModalOpen, createViewModalClose } from './actions';

export const initialState = {
  open: false,
};

const createViewModalReducer = handleActions({
  [createViewModalOpen]: () => ({ open: true }),
  [createViewModalClose]: () => ({ open: false }),
}, initialState);

export default createViewModalReducer;
