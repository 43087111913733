import { handleActions } from 'redux-actions';
import { logoutRequest } from 'Containers/UserContainer/actions';
import { USER_FAIL } from 'Containers/UserContainer/constants';
import loginRequest from './actions';

export const initialState = {
  loggedIn: false,
  error: null,
};

const authReducer = handleActions({
  [loginRequest.SUCCESS]: () => ({ loggedIn: true, error: null }),
  [loginRequest.FAILURE]: (state, { payload: { error } }) => ({ loggedIn: false, error }),
  [logoutRequest]: (state, { payload: { error } }) => ({ loggedIn: false, error }),
  [USER_FAIL]: (state, { payload: { error } }) => ({ loggedIn: false, error }),
}, initialState);

export default authReducer;
