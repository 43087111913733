export default {
  headers: [
    [
      {
        key: 'empty_name',
        header: null,
        sticky: true,
      },
      {
        key: 'empty_spend',
        header: null,
        sticky: true,
      },
      {
        key: 'protocol_port_spend_0',
        header: 'First',
        colspan: 3,
      },
      {
        key: 'protocol_port_spend_1',
        header: 'Second',
        colspan: 3,
      },
      {
        key: 'protocol_port_spend_2',
        header: 'Third',
        colspan: 3,
      },
      {
        key: 'protocol_port_spend_3',
        header: 'Fourth',
        colspan: 3,
      },
    ],
    [
      {
        key: 'name',
        header: 'Name',
        sticky: true,
      },
      {
        key: 'totals',
        header: 'Total Spend',
        sticky: true,
        isSortHeader: true,
        sortDirection: 'DESC',
        type: 'currency',
      },
      {
        key: 'protocol_0',
        header: 'Protocol 1',
      },
      {
        key: 'port_0',
        header: 'Port 1',
        type: 'currency',
      },
      {
        key: 'spend_0',
        header: 'Spend 1',
        type: 'currency',
      },
      {
        key: 'protocol_1',
        header: 'Protocol 2',
      },
      {
        key: 'port_1',
        header: 'Port 2',
        type: 'currency',
        sticky: true,
      },
      {
        key: 'spend_1',
        header: 'Spend 2',
        type: 'currency',
      },
      {
        key: 'protocol_2',
        header: 'Protocol 3',
      },
      {
        key: 'port_2',
        header: 'Port 3',
        type: 'currency',
      },
      {
        key: 'spend_2',
        header: 'Spend 3',
        type: 'currency',
      },
      {
        key: 'protocol_3',
        header: 'Protocol 4',
      },
      {
        key: 'port_3',
        header: 'Port 3',
        type: 'currency',
      },
      {
        key: 'spend_3',
        header: 'Spend 4',
        type: 'currency',
      },
    ],
  ],
  rows: [
    {
      id: 'totals',
      name: 'Totals',
      protocol_0: '',
      port_0: 100000,
      port_0_percentage: 0.652,
      spend_0: 12314,
      protocol_1: '',
      port_1: 10000,
      port_1_percentage: 0.621,
      spend_1: 12314,
      port_2: 10000,
      port_2_percentage: 0.513,
      spend_2: 12314,
      port_3: 10000,
      port_3_percentage: 0.325,
      spend_3: 12314,
      totals: 15211,
    },
    {
      id: 'UNITED STATES',
      name: 'Load Balancer 3',
      protocol_0: 'HTTPS',
      port_0: 9443,
      spend_0: 12314,
      protocol_1: 'HTTP',
      spend_1: 12314,
      port_1: 8080,
      port_1_percentage: 0.1,
      port_2: 10000,
      spend_2: 12314,
      port_2_percentage: 0.5,
      port_3: 10000,
      spend_3: 12314,
      port_3_percentage: 0.35,
      totals: 15211,
    },
    {
      id: 'b',
      name: 'Load Balancer 1',
      protocol_0: 'HTTPS',
      port_0: 443,
      spend_0: 12314,
      protocol_1: 'HTTP',
      port_1: 3000,
      spend_1: 12314,
      port_1_percentage: 0.12,
      port_2: 10000,
      spend_2: 12314,
      port_2_percentage: 0.53,
      port_3: 10000,
      spend_3: 12314,
      port_3_percentage: 0.25,
      totals: 15324311,
    },
    {
      id: 'c',
      name: 'Load Balancer 2',
      protocol_0: 'HTTP',
      port_0: 80,
      spend_0: 12314,
      protocol_1: 'HTTPS',
      port_1: 9443,
      spend_1: 12314,
      port_1_percentage: 0.11,
      port_2: 10000,
      spend_2: 12314,
      port_2_percentage: 0.44,
      port_3: 10000,
      spend_3: 12314,
      port_3_percentage: 0.75,
      totals: 1511,
    },
  ],
  totalRows: 50,
};
