import React from 'react';
import { FormattedMessage } from 'react-intl';
import { select, call, takeLatest, takeEvery, put } from 'redux-saga/effects';
import fetch from 'Utils/fetch';
import { makeSelectCurrentLocation } from 'Containers/App/selectors';
import showNotification from 'Components/ToastComponent/actions';
import logger from 'Utils/logger';
import {
  PAGE_SETTINGS_REQUEST,
  PAGE_SETTINGS_SAVE,
  PAGE_SETTINGS_UPDATE,
  API_PAGE_SETTINGS_PATH,
  PENDING_REGISTER_USERS,
  API_PENDING_REGISTER_USERS,
  PENDING_REGISTER_USERS_SUCCESS,
} from './constants';
import { pageDataRequest } from './actions';
import messages from './messages';
import { makeSelectPageName } from './selectors';
import data from '../DataTableContainer/data';

function* getPageData({ payload: { PATH, payload, ...rest } }) {
  try {
    const data = yield call(fetch, PATH, { 
      params: payload,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'page': PATH
      },
      ...rest 
    });
    if (typeof data !== 'string' && Reflect.ownKeys(data).length) {
      yield put(pageDataRequest.success({ page: payload.page, data }));
    } else {
      yield put(showNotification({
        title: <FormattedMessage { ...messages.notifications.data.warning.title } />,
        body: <FormattedMessage { ...messages.notifications.data.warning.body } />,
        kind: 'warning',
      }));
      yield put(pageDataRequest.failure({ page: payload.page, error: Error('No Data Available') }));
    }
  } catch (error) {
    const name = yield select(makeSelectPageName);
    yield put(showNotification({
      title: <FormattedMessage { ...messages.notifications.data.error.title } />,
      body: (
        <FormattedMessage
          { ...messages.notifications.data.error.body }
          values={{ page: <strong>{ name }</strong> }}
        />
      ),
      kind: 'error',
    }));
    yield put(pageDataRequest.failure({ page: payload.page, error }));
    logger.error(error);
  }
}

function* getPageSettings({ payload: { page: path } }) {
  let page = path;
  if (!path) {
    page = yield select(makeSelectCurrentLocation);
  }
  try {
    const data = yield call(fetch, API_PAGE_SETTINGS_PATH, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'page': '/api/user/pageSettings'
      },
      params: { page },
    });
    if (typeof data !== 'string' && Reflect.ownKeys(data).length) {
      yield put({ type: PAGE_SETTINGS_UPDATE, payload: { page, data } });
    }
  } catch (error) {
    const name = yield select(makeSelectPageName);
    yield put(showNotification({
      title: <FormattedMessage { ...messages.notifications.settings.request.error.title } />,
      body: (
        <FormattedMessage
          { ...messages.notifications.settings.request.error.body }
          values={{ page: <strong>{ name }</strong> }}
        />
      ),
      kind: 'error',
      timeout: 3500,
    }));
    logger.error(`There was an error fetching the page settings for ${page}: ${error}`);
  }
}

function* savePageSettings({ payload: { page, data: settings } }) {
  try {
    yield call(fetch, API_PAGE_SETTINGS_PATH, {
      method: 'POST',
      params: { page, settings },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'page': '/api/user/pageSettings'
      },
    });
  } catch (error) {
    const name = yield select(makeSelectPageName);
    yield put(showNotification({
      title: <FormattedMessage { ...messages.notifications.settings.save.error.title } />,
      body: (
        <FormattedMessage
          { ...messages.notifications.settings.save.error.body }
          values={{ page: name }}
        />
      ),
      kind: 'error',
      canClose: false,
      timeout: 3500,
    }));
    logger.error(`Error saving settings for page ${name} due to ${error}`);
  }
}

function* getPendingRegisterUsers({ payload: { page: path } }) {

  try {
    const data = yield call(fetch, API_PENDING_REGISTER_USERS,
      {
        headers:{
          'page': '/api/data/getInformation'
        }
    });
    if (data) {
      yield put({ type: PENDING_REGISTER_USERS_SUCCESS, payload: { data } });
    }
  } catch (e) {
    //yield put({ type: APP_DATA_INFO_FAIL, payload: { data: [] } });
  }

}

export default function* defaultSaga() {
  yield takeLatest(pageDataRequest.REQUEST, getPageData);
  yield takeLatest(PAGE_SETTINGS_REQUEST, getPageSettings);
  yield takeEvery(PAGE_SETTINGS_SAVE, savePageSettings);
  yield takeLatest(PENDING_REGISTER_USERS, getPendingRegisterUsers);
}

export const getPageContainerSaga = [
  takeLatest(PENDING_REGISTER_USERS, getPendingRegisterUsers),
];
