import { createSelector } from 'reselect';

const appStateDomain = state => state.appState;
const locationStateDomain = state => state.router;

export const makeSelectCurrentLocation = createSelector(
  locationStateDomain,
  routeState => routeState.location.pathname.replace(/(\/)$/g, '')
);

export const makeSelectLocation = createSelector(
  locationStateDomain,
  routeState => routeState.location,
);

export const makeSelectActiveFilters = createSelector(
  appStateDomain,
  state => state.activeFilters
);

export const makeSelectLanguage = createSelector(
  appStateDomain,
  state => state.userSettings.language
);

export const makeSelectTimezone = createSelector(
  appStateDomain,
  state => state.userSettings.timezone
);

export const makeSelectTimeFormat = createSelector(
  appStateDomain,
  state => state.userSettings.timeFormat
);

export const makeSelectAppDataInfo = createSelector(
  appStateDomain,
  state => state.dataInformation
);

export const makeSelectPageActiveView = createSelector(
  appStateDomain,
  state => state.page.activeView
);

export const makeSelectActivePage = createSelector(
  appStateDomain,
  state => state.page
);

export const makeSelectAppPageSettings = createSelector(
  appStateDomain,
  state => state.page.settings
);