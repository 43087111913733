export const API_PATH = '/api/alerts';
export const API_ACTIVITIES_PATH = '/api/alerts/activities';
export const API_AUTOMATED_ALERTS_PATH = '/api/alerts/automated';
export const API_PATH_ALERT_OPTIONS = '/api/alerts/getAlertOptions';
export const API_PATH_ALERT_UPDATE_ACTIVE = '/api/alerts/updateActive';
export const API_PATH_ACTIVITY_MARK_OPENED = '/api/alerts/activity/markOpened';
export const API_PATH_ACTIVITY_MARK_VIEWED = '/api/alerts/activity/markViewed';

export const ALERTS_REQUEST = 'app/AlertsPage/ALERTS_REQUEST';
export const AUTOMATED_ALERTS_REQUEST = 'app/AlertsPage/AUTOMATED_ALERTS_REQUEST';
export const ACTIVITIES_REQUEST = 'app/AlertsPage/ACTIVITIES_REQUEST';
export const ALERT_OPTIONS_REQUEST = 'app/AlertsPage/ALERT_OPTIONS_REQUEST';
export const ALERT_SET_ACTIVE = 'app/AlertsPage/ALERT_SET_ACTIVE';
export const ALERT_CLEAR_ACTIVE = 'app/AlertsPage/ALERT_CLEAR_ACTIVE';
