import { handleActions } from 'redux-actions';
import { informationModalClose, informationModalOpen } from './actions';

const initialState = {
  open: false,
};

const informationModalReducer = handleActions({
  [informationModalOpen]: () => ({ open: true }),
  [informationModalClose]: () => ({ open: false }),
}, initialState);

export default informationModalReducer;
