/* eslint-disable no-restricted-syntax */
export const defaultSortItems = (items, { selectedItems }) => items
  .sort((itemA, itemB) => {
    const hasItemA = selectedItems.includes(itemA);
    const hasItemB = selectedItems.includes(itemB);

    // Prefer whichever item is in the `selectedItems` array first
    if (hasItemA && !hasItemB) {
      return -1;
    }

    if (hasItemB && !hasItemA) {
      return 1;
    }

    return 0;
  });

export default function sortData(data, page, type) {
  if (page.indexOf('/analysis/air/advanced') > -1) {
    // eslint-disable-next-line guard-for-in
    for (const i in data) {
      // data[i].data.sort((a, b) => parseInt(b.name.replace('+ days', '').replace('- days', ''), 10) < parseInt(a.name.replace('+ days', '').replace('- days', ''), 10) ? 1 : -1);
      data[i].data.sort((a, b) => a.name == 'Other' ? 1 : b.name == 'Other' ? -1 : parseInt(a.name.replace(/[+-].*/g,"")) < parseInt(b.name.replace(/[+-].*/g,"")) ? -1 : 1);
    }
  } if (page.indexOf('/analysis/hotel/marketshare') > -1) { // && (type === 'city' || type === 'hotel')) {
    // eslint-disable-next-line guard-for-in
    // for (const i in data) {
    //   data[i].data.sort((a, b) => a.name.localeCompare(b.name));
    // }
  } 
  return data;
};

export const formaterFilters = (filters, page) => {
  if ((page.indexOf('/dashboard/car') > -1 || page.indexOf('/analysis/car/rentalbooking') > -1 || page.indexOf('/analysis/car/benchmark') > -1 
  || page.indexOf('/analysis/car/rebate') > -1 || page.indexOf('/analysis/car/compliance') > -1 )
    && filters?.carTravelPurpose?.length > 0) {
    filters.carTravelPurpose.forEach(element => {
      switch (element.label) {
        case 'B' :
          // eslint-disable-next-line no-param-reassign
          element.label = 'Business';
          break;
        // eslint-disable-next-line no-fallthrough
        case 'L' :
          // eslint-disable-next-line no-param-reassign
          element.label = 'Leisure';
          break;
        case 'N' :
          // eslint-disable-next-line no-param-reassign
          element.label = 'Non Company';
          break;
        // eslint-disable-next-line no-fallthrough
        default :
      }
    });
  } else if (page.indexOf('/customer/standardreport') > -1 && filters?.carTravelPurposeCarRentalGroupSpend?.length > 0) {
    filters.carTravelPurposeCarRentalGroupSpend.forEach(element => {
      switch (element.label) {
        case 'B' :
          // eslint-disable-next-line no-param-reassign
          element.label = 'Business';
          break;
        // eslint-disable-next-line no-fallthrough
        case 'BM' :
          // eslint-disable-next-line no-param-reassign
          element.label = 'Business Unmatched';
          break;
        // eslint-disable-next-line no-fallthrough
        case 'L' :
          // eslint-disable-next-line no-param-reassign
          element.label = 'Leisure';
          break;
        // eslint-disable-next-line no-fallthrough
        default :
      }
    });
  }
  return filters;
};
