import { stringify } from 'qs';
import { takeLatest, call, put } from 'redux-saga/effects';
import fetch from 'Utils/fetch';
import logger from 'Utils/logger';
import { errorMessages } from 'Components/Login/messages';
import { API_AUTH } from './constants';
import login from './actions';

function* authorize({ payload: { username, password } }) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: stringify({ j_username: username, j_password: password }),
  };

  try {
    yield call(fetch, API_AUTH, options);
    yield put(login.success());
  } catch (error) {
    yield put(login.failure({ error: Error(errorMessages.credentials.id) }));
    logger.error(error);
  }
}

// Default export for testing
export default function* sagas() {
  yield takeLatest(login.REQUEST, authorize);
}

/* eslint-disable redux-saga/yield-effects */
export const authSagas = [
  takeLatest(login.REQUEST, authorize),
];
/* eslint-enable redux-saga/yield-effects */
