import { defineMessages } from 'react-intl';

const staticMessages = {
  compliance: 'Compliance Rate',
  totalSpend: 'Total Spend',
  totalCategorySpend: 'Total Category Spend',
  totalMiscSpend: 'Total Misc. Spend',
  totalBaseSpend: 'Total Base Spend',
  totalFullSpend: 'Total Full Spend',
};

export default defineMessages({
  placeholder: {
    id: 'app.containers.TopVisualContainer.placeholder',
    defaultMessage: '{ message }',
  },
  dashboard: {
    manager: {
      title: {
        id: 'app.containers.TopVisualContainer.dashboard.manager.title',
        defaultMessage: staticMessages.totalSpend,
      },
      totalSpend: {
        id: 'app.containers.TopVisualContainer.dashboard.manager.totalSpend',
        defaultMessage: staticMessages.totalSpend,
      },
      totalCategorySpend: {
        id: 'app.containers.TopVisualContainer.dashboard.manager.totalCategorySpend',
        defaultMessage: staticMessages.totalCategorySpend,
      },
      totalMiscSpend: {
        id: 'app.containers.TopVisualContainer.dashboard.manager.totalMiscSpend',
        defaultMessage: staticMessages.totalMiscSpend,
      },
    },
    air: {
      totalSpend: {
        id: 'app.containers.TopVisualContainer.dashboard.air.totalSpend',
        defaultMessage: staticMessages.totalSpend,
      },
      totalMiscSpend: {
        id: 'app.containers.TopVisualContainer.dashboard.air.totalMiscSpend',
        defaultMessage: staticMessages.totalMiscSpend,
      },
      totalTickets: {
        id: 'app.containers.TopVisualContainer.dashboard.air.totalTickets',
        defaultMessage: 'Total Tickets',
      },
      complianceRate: {
        id: 'app.containers.TopVisualContainer.dashboard.air.complianceRate',
        defaultMessage: staticMessages.compliance,
      },
      advancedBooking: {
        id: 'app.containers.TopVisualContainer.dashboard.air.advancedBooking',
        defaultMessage: 'Adv Booking > 14 days',
      },
      averageTripCost: {
        id: 'app.containers.TopVisualContainer.dashboard.air.averageTripCost',
        defaultMessage: 'Average Trip Cost',
      },
      averageTripCost: {
        id: 'app.containers.TopVisualContainer.dashboard.air.averageTripCost',
        defaultMessage: 'Average Trip Cost',
      },
    },
    hotel: {
      totalSpend: {
        id: 'app.containers.TopVisualContainer.dashboard.hotel.totalSpend',
        defaultMessage: staticMessages.totalSpend,
      },
      totalMiscSpend: {
        id: 'app.containers.TopVisualContainer.dashboard.hotel.totalMiscSpend',
        defaultMessage: staticMessages.totalMiscSpend,
      },
      nightsStayed: {
        id: 'app.containers.TopVisualContainer.dashboard.hotel.nightsStayed',
        defaultMessage: 'Nights Stayed',
      },
      averageStay: {
        id: 'app.containers.TopVisualContainer.dashboard.hotel.averageStay',
        defaultMessage: 'Average Stay',
      },
      averageRoomRate: {
        id: 'app.containers.TopVisualContainer.dashboard.hotel.averageRoomRate',
        defaultMessage: 'Average Room Rate',
      },
    },
    car: {
      totalSpend: {
        id: 'app.containers.TopVisualContainer.dashboard.car.totalSpend',
        defaultMessage: 'Total Spend',
      },
      totalMiscSpend: {
        id: 'app.containers.TopVisualContainer.dashboard.car.totalMiscSpend',
        defaultMessage: 'Total Misc Spend',
      },
      carRentalDays: {
        id: 'app.containers.TopVisualContainer.dashboard.car.carRentalDays',
        defaultMessage: '# of Car Rental Days',
      },
      averageCarRentalDays: {
        id: 'app.containers.TopVisualContainer.dashboard.car.averageCarRentalDays',
        defaultMessage: 'Avg. Car Rental Days',
      },
      averageCarRentalRate: {
        id: 'app.containers.TopVisualContainer.dashboard.car.averageCarRentalRate',
        defaultMessage: 'Avg. Car Rental Rate',
      },
      carRentals: {
        id: 'app.containers.TopVisualContainer.dashboard.car.carRentals',
        defaultMessage: '# of Car Rentals',
      },
    },
    ground: {
      totalSpend: {
        id: 'app.containers.TopVisualContainer.dashboard.ground.totalSpend',
        defaultMessage: staticMessages.totalSpend,
      },
      poSpend: {
        id: 'app.containers.TopVisualContainer.dashboard.ground.poSpend',
        defaultMessage: "PO Spend",
      },
      expenseSpend: {
        id: 'app.containers.TopVisualContainer.dashboard.ground.expenseSpend',
        defaultMessage: "Expense Spend",
      },
      miscSpend: {
        id: 'app.containers.TopVisualContainer.dashboard.ground.miscSpend',
        defaultMessage: 'Misc spend',
      },
      totalMiscSpend: {
        id: 'app.containers.TopVisualContainer.dashboard.ground.totalMiscSpend',
        defaultMessage: staticMessages.totalMiscSpend,
      },
      averageTripCost: {
        id: 'app.containers.TopVisualContainer.dashboard.ground.averageTripCost',
        defaultMessage: 'Average Trip Cost (Expense only)',
      },
      trips: {
        id: 'app.containers.TopVisualContainer.dashboard.ground.trips',
        defaultMessage: '# of Trips (Expense only)',
      },
    },
    card: {
      totalTransactions: {
        id: 'app.containers.TopVisualContainer.dashboard.card.totalTransactions',
        defaultMessage: 'Total Card Transactions',
      },
      totalSpend: {
        id: 'app.containers.TopVisualContainer.dashboard.card.totalSpend',
        defaultMessage: 'Total Card Charge Volumes',
      },
      totalOwners: {
        id: 'app.containers.TopVisualContainer.dashboard.card.totalOwners',
        defaultMessage: 'Total Cards in Force',
      },
      averageSpend: {
        id: 'app.containers.TopVisualContainer.dashboard.card.averageSpend',
        defaultMessage: 'Average Card Charge Volumes per Card Member',
      },
    },
    meetings: {
      totalSpend: {
        id: 'app.containers.TopVisualContainer.dashboard.meetings.totalSpend',
        defaultMessage: 'Total Spend',
      },
      totalNights: {
        id: 'app.containers.TopVisualContainer.dashboard.meetings.totalNights',
        defaultMessage: 'Total Nights',
      },
    },
  },
  analysis: {
    air: {
      advanced: {
        'avgPrice0-6 days': {
          id: 'app.containers.TopVisualContainer.analysis.air.advanced.avgPrice0-6 days',
          defaultMessage: 'Avg. Price 0-6 Days',
        },
        'avgPrice7-14 days': {
          id: 'app.containers.TopVisualContainer.analysis.air.advanced.avgPrice7-14 days',
          defaultMessage: 'Avg. Price 7-14 Days',
        },
        'avgPrice15+ days': {
          id: 'app.containers.TopVisualContainer.analysis.air.advanced.avgPrice15+ days',
          defaultMessage: 'Avg. Price 15+ Days',
        },
        'spendPercent0-6 days': {
          id: 'app.containers.TopVisualContainer.analysis.air.advanced.spendPercent0-6 days',
          defaultMessage: 'Spend Percent 0-6 Days',
        },
        'spendPercent7-14 days': {
          id: 'app.containers.TopVisualContainer.analysis.air.advanced.spendPercent7-14 days',
          defaultMessage: 'Spend Percent 7-14 days',
        },
        'spendPercent15+ days': {
          id: 'app.containers.TopVisualContainer.analysis.air.advanced.spendPercent15+ days',
          defaultMessage: 'Spend Percent 15+ days',
        },
        totalSpend: {
          id: 'app.containers.TopVisualContainer.analysis.air.advanced.totalSpend',
          defaultMessage: staticMessages.totalSpend,
        },
        totalTickets: {
          id: 'app.containers.TopVisualContainer.analysis.air.advanced.totalTickets',
          defaultMessage: 'Total Tickets',
        },
      },
      agency: {
        totalTaxFreeSpend: {
          id: 'app.containers.TopVisualContainer.analysis.air.agency.totalTaxFreeSpend',
          defaultMessage: 'Total Spend Without Tax',
        },
        totalOnlineTickets: {
          id: 'app.containers.TopVisualContainer.analysis.air.agency.totalOnlineTickets',
          defaultMessage: 'Total Online Tickets',
        },
        totalOfflineTickets: {
          id: 'app.containers.TopVisualContainer.analysis.air.agency.totalOfflineTickets',
          defaultMessage: 'Total Offline Tickets',
        },
        totalAdoption: {
          id: 'app.containers.TopVisualContainer.analysis.air.agency.totalAdoption',
          defaultMessage: 'Total Adoption',
        },
        totalAgencySavings: {
          id: 'app.containers.TopVisualContainer.analysis.air.agency.totalAgencySavings',
          defaultMessage: 'Total Agency Savings',
        },
        totalAgencyLossSavings: {
          id: 'app.containers.TopVisualContainer.analysis.air.agency.totalAgencyLossSavings',
          defaultMessage: 'Total Agency Loss Savings',
        },
        totalFareSavings: {
          id: 'app.containers.TopVisualContainer.analysis.air.agency.totalFareSavings',
          defaultMessage: 'Total Fare Savings',
        },
        totalFareLossSavings: {
          id: 'app.containers.TopVisualContainer.analysis.air.agency.totalFareLossSavings',
          defaultMessage: 'Total Fare Loss Savings',
        },
      },
      citypair: {
        common: {
          volume: {},
          averagePrice: {},
          cityPairs: {},
        },
        carrier: {},
        cabin: {},
        booking: {},
        origin: {},
        items: {
          id: 'app.containers.TopVisualContainer.analysis.air.citypair.items',
          defaultMessage: `{ prefix } { type }`,
        },
        totalSpend: {
          id: 'app.containers.TopVisualContainer.analysis.air.citypair.totalSpend',
          defaultMessage: `{ prefix } ${staticMessages.totalBaseSpend}`,
        },
        totalBaseSpend: {
          id: 'app.containers.TopVisualContainer.analysis.air.citypair.totalBaseSpend',
          defaultMessage: `{ prefix } ${staticMessages.totalBaseSpend}`,
        },
        totalFullSpend: {
          id: 'app.containers.TopVisualContainer.analysis.air.citypair.totalFullSpend',
          defaultMessage: `{ prefix } ${staticMessages.totalFullSpend}`,
        },
        tickets: {
          id: 'app.containers.TopVisualContainer.analysis.air.citypair.tickets',
          defaultMessage: '{ prefix } Tickets',
        },
        flights: {
          id: 'app.containers.TopVisualContainer.analysis.air.citypair.flights',
          defaultMessage: '{ prefix } Flights',
        },
        averageTicketPrice: {
          id: 'app.containers.TopVisualContainer.analysis.air.citypair.averageTicketPrice',
          defaultMessage: '{ prefix } Average Ticket Price',
        },
        averageFlightPrice: {
          id: 'app.containers.TopVisualContainer.analysis.air.citypair.averageFlightPrice',
          defaultMessage: '{ prefix } Average Flight Price',
        },

        comparedItems: {
          id: 'app.containers.TopVisualContainer.analysis.air.citypair.comparedItems',
          defaultMessage: `{ prefix } { type }`,
        },
        comparedTotalFullSpend: {
          id: 'app.containers.TopVisualContainer.analysis.air.citypair.comparedTotalFullSpend',
          defaultMessage: `{ prefix } ${staticMessages.totalBaseSpend}`,
        },
        comparedTotalBaseSpend: {
          id: 'app.containers.TopVisualContainer.analysis.air.citypair.comparedTotalBaseSpend',
          defaultMessage: `{ prefix } ${staticMessages.totalBaseSpend}`,
        },
        comparedTickets: {
          id: 'app.containers.TopVisualContainer.analysis.air.citypair.comparedTickets',
          defaultMessage: '{ prefix } Tickets',
        },
        comparedFlights: {
          id: 'app.containers.TopVisualContainer.analysis.air.citypair.comparedFlights',
          defaultMessage: '{ prefix } Flights',
        },
        comparedAverageTicketPrice: {
          id: 'app.containers.TopVisualContainer.analysis.air.citypair.comparedAverageTicketPrice',
          defaultMessage: '{ prefix } Average Ticket Price',
        },
        comparedAverageFlightPrice: {
          id: 'app.containers.TopVisualContainer.analysis.air.citypair.comparedAverageFlightPrice',
          defaultMessage: '{ prefix } Average Flight Price',
        },


        cityPairs: {
          id: 'app.containers.TopVisualContainer.analysis.air.citypair.cityPairs',
          defaultMessage: '{ prefix } Number of Airport Pairs',
        },
        airlines: {
          id: 'app.containers.TopVisualContainer.analysis.air.citypair.airlines',
          defaultMessage: '{ prefix } Number of Airlines',
        },
      },
      corporatefare: {
        common: {},
        carrier: {},
        cityPair: {},
        cabin: {},
        booking: {},
        totalBaseSpend: {
          id: 'app.containers.TopVisualContainer.analysis.air.corporatefare.totalBaseSpend',
          defaultMessage: staticMessages.totalBaseSpend,
        },
        totalFullSpend: {
          id: 'app.containers.TopVisualContainer.analysis.air.corporatefare.totalFullSpend',
          defaultMessage: staticMessages.totalFullSpend,
        },
        corporateSpend: {
          id: 'app.containers.TopVisualContainer.analysis.air.corporatefare.corporateSpend',
          defaultMessage: 'Corporate Spend Percentage',
        },
        publishedSpend: {
          id: 'app.containers.TopVisualContainer.analysis.air.corporatefare.publishedSpend',
          defaultMessage: 'Published Spend Percentage',
        },
        otherSpend: {
          id: 'app.containers.TopVisualContainer.analysis.air.corporatefare.otherSpend',
          defaultMessage: 'Other Spend Percentage',
        },
      },
      onlineadoption: {
        common: {},
        employee: {},
        transactions: {},
        overallPrevious: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.overallPrevious',
          defaultMessage: 'Overall Blended {suffix}',
        },
        overallCurrent: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.overallCurrent',
          defaultMessage: 'Overall Blended {suffix}',
        },
        overall: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.overall',
          defaultMessage: 'Overall Blended',
        },
        domestic: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.domestic',
          defaultMessage: 'Domestic {suffix}',
        },
        international: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.international',
          defaultMessage: 'International {suffix}',
        },
        european: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.european',
          defaultMessage: 'European {suffix}',
        },
        employeeName: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.employeeName',
          defaultMessage: 'Employee Name',
        },
        employeeSerial: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.employeeSerial',
          defaultMessage: 'Employee Serial',
        },
        lobName: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.lobName',
          defaultMessage: 'Line of Business',
        },
        totalSpend: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.totalSpend',
          defaultMessage: staticMessages.totalSpend,
        },
      },
      compliance: {
        lostSavingsCompliant: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.compliantLostSavingsCompliant',
          defaultMessage: 'Compliant Lost Savings',
        },
        quantityCompliant: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.compliantQuantityCompliant',
          defaultMessage: 'Compliant Qty',
        },
        'lostSavingsNon-compliant': {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.compliantLostSavingsNon-compliant',
          defaultMessage: 'Non-compliant Lost Savings',
        },
        'quantityNon-compliant': {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.compliantQuantityNon-compliant',
          defaultMessage: 'Non-compliant Qty',
        },
        lostSavingsUnknown: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.compliantLostSavingsUnknown',
          defaultMessage: 'Unknown Lost Savings',
        },
        quantityUnknown: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.compliantQuantityUnknown',
          defaultMessage: 'Unknown Qty',
        },
        totalSavingsUnknown: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.totalSavingsUnknown',
          defaultMessage: 'Total Savings',
        },
        lostSavingsUncategorized: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.lostSavingsUncategorized',
          defaultMessage: 'Unknown Lost Savings',
        },
        quantityUncategorized: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.quantityUncategorized',
          defaultMessage: 'Unknown Qty',
        },
        lostSavingsOther: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.compliantLostSavingsOther',
          defaultMessage: 'Other Lost Savings',
        },
        quantityOther: {
          id: 'app.containers.TopVisualContainer.analysis.air.onlineadoption.compliantQuantityOther',
          defaultMessage: 'Other Qty',
        },
      },
      ticketUtilize: {
        totalBookedTickets: {
          id: 'app.containers.TopVisualContainer.analysis.air.ticketUtilize.totalBookedTickets',
          defaultMessage: 'Total Number of Ticket Booked',
        },
        totalBookSpent: {
          id: 'app.containers.TopVisualContainer.analysis.air.ticketUtilize.totalBookSpent',
          defaultMessage: 'Total Spend of Ticket Booked',
        },
        totalUnutilizedAmount: {
          id: 'app.containers.TopVisualContainer.analysis.air.ticketUtilize.totalUnutilizedAmount',
          defaultMessage: 'Total Number of Ticket Un-utilized',
        },
        totalUnutilizedTickets: {
          id: 'app.containers.TopVisualContainer.analysis.air.ticketUtilize.totalUnutilizedTickets',
          defaultMessage: 'Total Spend of Ticket Un-utilized',
        },
      },
      trend : {
        year1: {
          id: 'app.containers.TopVisualContainer.analysis.air.trend.year1',
          defaultMessage: '{prefix} Spend'
        },
        year2: {
          id: 'app.containers.TopVisualContainer.analysis.air.trend.year2',
          defaultMessage: '{prefix} Spend'
        },
        year3: {
          id: 'app.containers.TopVisualContainer.analysis.air.trend.year3',
          defaultMessage: '{prefix} Spend'
        },
        year4: {
          id: 'app.containers.TopVisualContainer.analysis.air.trend.year4',
          defaultMessage: '{prefix} Spend'
        },
        year5: {
          id: 'app.containers.TopVisualContainer.analysis.air.trend.year5',
          defaultMessage: '{prefix} Spend'
        },
        topRecovery1: {
          id: 'app.containers.TopVisualContainer.analysis.air.trend.topRecovery1',
          defaultMessage: '{prefix} recovery',
        },
        topRecovery2: {
          id: 'app.containers.TopVisualContainer.analysis.air.trend.topRecovery2',
          defaultMessage: '{prefix} recovery',
        },
        topRecovery3: {
          id: 'app.containers.TopVisualContainer.analysis.air.trend.topRecovery3',
          defaultMessage: '{prefix} recovery',
        },
        topRecovery4: {
          id: 'app.containers.TopVisualContainer.analysis.air.trend.topRecovery4',
          defaultMessage: '{prefix} recovery',
        },
        topRecovery5: {
          id: 'app.containers.TopVisualContainer.analysis.air.trend.topRecovery5',
          defaultMessage: '{prefix} recovery',
        },
        topYearSpend1: {
          id: 'app.containers.TopVisualContainer.analysis.air.trend.topYearSpend1',
          defaultMessage: '{prefix} Total Spend',
        },
        topYearSpend2: {
          id: 'app.containers.TopVisualContainer.analysis.air.trend.topYearSpend2',
          defaultMessage: '{prefix} Total Spend',
        },
        topYearSpend3: {
          id: 'app.containers.TopVisualContainer.analysis.air.trend.topYearSpend3',
          defaultMessage: '{prefix} Total Spend',
        },
        topYearSpend4: {
          id: 'app.containers.TopVisualContainer.analysis.air.trend.topYearSpend4',
          defaultMessage: '{prefix} Total Spend',
        },
        topYearSpend5: {
          id: 'app.containers.TopVisualContainer.analysis.air.trend.topYearSpend5',
          defaultMessage: '{prefix} Total Spend',
        },
      },
      contract : {
        totalSpend: {
          id: 'app.containers.TopVisualContainer.analysis.air.contract.totalSpend',
          defaultMessage: 'Total Spend'
        },
        totalTicket: {
          id: 'app.containers.TopVisualContainer.analysis.air.contract.totalTicket',
          defaultMessage: 'Total Ticket'
        },
      }

    },
    hotel: {
      contract: {
        common: {},
        group: {},
        chain: {},
        branch: {},
        totalSpend: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.contract.totalSpend',
          defaultMessage: staticMessages.totalSpend,
        },
        spendUnmatched: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.contract.spendUnmatched',
          defaultMessage: 'Unmatched Spend',
        },
        rateUnmatched: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.contract.rateUnmatched',
          defaultMessage: 'Unmatched Avg. Rate',
        },
        spendOutofContract: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.contract.spendOutofContract',
          defaultMessage: 'Out of Contract Spend',
        },
        rateOutofContract: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.contract.rateOutofContract',
          defaultMessage: 'Out of Contract Avg. Rate',
        },
        spendFixedOutofContract: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.contract.spendFixedOutofContract',
          defaultMessage: 'Fixed Out Of Contract Spend',
        },
        rateFixedOutofContract: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.contract.rateFixedOutofContract',
          defaultMessage: 'Fixed Out Of Contract Avg. Rate',
        },
        spendInContract: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.contract.spendInContract',
          defaultMessage: 'In Contract Spend',
        },
        rateInContract: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.contract.rateInContract',
          defaultMessage: 'In Contract Avg. Rate',
        },
      },
      marketshare: {
        common: {},
        group: {},
        chain: {},
        branch: {},
        totalSpend: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.marketshare.totalSpend',
          defaultMessage: '{prefix} Total Spend',
        },
        totalMiscSpend: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.marketshare.totalMiscSpend',
          defaultMessage: '{prefix} Total Misc Spend',
        },
        nights: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.marketshare.nights',
          defaultMessage: '{prefix} Nights Stayed',
        },
        averageStay: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.marketshare.averageStay',
          defaultMessage: '{prefix} Average Stay',
        },
        averageRate: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.marketshare.averageRate',
          defaultMessage: '{prefix} Average Room Rate',
        },
      },
      marketshift: {
        totalSpend: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.marketshift.totalSpend',
          defaultMessage: staticMessages.totalSpend,
        },
        totalMiscSpend: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.marketshift.totalMiscSpend',
          defaultMessage: staticMessages.totalMiscSpend,
        },
        nights: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.marketshift.nights',
          defaultMessage: 'Nights Stayed',
        },
        averageStay: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.marketshift.averageStay',
          defaultMessage: 'Average Stay',
        },
        averageRate: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.marketshift.averageRate',
          defaultMessage: 'Average Room Rate',
        },
        estimatedSpend: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.marketshift.estimatedSpend',
          defaultMessage: 'Estimated Spend',
        },
        estimatedSavings: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.marketshift.estimatedSavings',
          defaultMessage: 'Estimated Savings',
        },
        estimatedLoss: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.marketshift.estimatedLoss',
          defaultMessage: 'Estimated Loss',
        },
      },
      rate: {
        common: {},
        group: {},
        chain: {},
        branch: {},
        totalSpend: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.rate.totalSpend',
          defaultMessage: staticMessages.totalSpend,
        },
        totalContractSpend: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.rate.totalContractSpend',
          defaultMessage: 'Total In Contract Spend',
        },
        averageRateFixed: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.rate.averageRateFixed',
          defaultMessage: 'Avg. Rate Fixed',
        },
        averageStayFixed: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.rate.averageRateFixed',
          defaultMessage: 'Avg. Stay Fixed',
        },
        averageRateDynamic: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.rate.averageRateDynamic',
          defaultMessage: 'Avg. Rate Dynamic',
        },
        averageStayDynamic: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.rate.averageStayDynamic',
          defaultMessage: 'Avg. Stay Dynamic',
        },
        averageRateFixedOutofContract: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.rate.averageRateFixedOutofContract',
          defaultMessage: 'Avg. Rate Fixed Out Of Contract',
        },
        averageStayFixedOutofContract: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.rate.averageStayFixedOutofContract',
          defaultMessage: 'Avg. Stay Fixed Out Of Contract',
        },
      },
      fixedrate: {
        hotelTotalSpends: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.booking.hotelTotalSpends',
          defaultMessage: 'Total Spend',
        },
        totalSpend: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.fixedrate.totalSpend',
          defaultMessage: 'Total Spend { with } Tax',
        },
        totalNights: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.fixedrate.totalNights',
          defaultMessage: 'Total Nights',
        },
        fixedRateSpend: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.fixedrate.fixedRateSpend',
          defaultMessage: 'Fixed Rate Spend { with } Tax',
        },
        fixedRateNights: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.fixedrate.fixedRateNights',
          defaultMessage: 'Fixed Rate Nights',
        },
        group: {},
        chain: {},
        branch: {},
      },
      booking: {
        hotelTotalSpends: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.booking.hotelTotalSpends',
          defaultMessage: 'Total Spend',
        },
        totalSpend: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.booking.totalSpend',
          defaultMessage: 'Total Spend Without Tax',
        },
        totalSpendWithoutTax: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.booking.totalSpendWithoutTax',
          defaultMessage: 'Total Spend Without Tax',
        },
        totalNights: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.booking.totalNights',
          defaultMessage: 'Total Nights',
        },
        onlineSpend: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.booking.onlineSpend',
          defaultMessage: 'Online Spend With Tax',
        },
        onlineTotalSpend: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.booking.onlineTotalSpend',
          defaultMessage: 'Online Total Spend Without Tax',
        },
        onlineTotalSpendWithoutTax: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.booking.onlineTotalSpendWithoutTax',
          defaultMessage: 'Online Total Spend Without Tax',
        },
        onlineTotalNights: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.booking.onlineTotalNights',
          defaultMessage: 'Total Nights',
        },
        directTotalSpend: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.booking.directTotalSpend',
          defaultMessage: 'Direct Total Spend Without Tax',
        },
        directTotalSpendWithoutTax: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.booking.directTotalSpendWithoutTax',
          defaultMessage: 'Direct Total Spend Without Tax',
        },
        directTotalNights: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.booking.directTotalNights',
          defaultMessage: 'Total Nights',
        },
        offlineTotalSpend: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.booking.offlineTotalSpend',
          defaultMessage: 'Offline Total Spend Without Tax',
        },
        offlineTotalSpendWithoutTax: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.booking.offlineTotalSpendWithoutTax',
          defaultMessage: 'Offline Total Spend Without Tax',
        },
        offlineTotalNights: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.booking.offlineTotalNights',
          defaultMessage: 'Total Nights',
        },
        offlineNights: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.booking.offlineNights',
          defaultMessage: 'Offline Nights',
        },
        onlineNights: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.booking.onlineNights',
          defaultMessage: 'Online Nights',
        },
        directNights: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.booking.directNights',
          defaultMessage: 'Direct Nights',
        },
      },
    },
    car: {
      rentalbooking: {
        totalSpends: {
          id: 'app.containers.TopVisualContainer.analysis.car.rentalbooking.totalSpends',
          defaultMessage: 'Total Spends',
        },
        totalRentals: {
          id: 'app.containers.TopVisualContainer.analysis.car.rentalbooking.totalRentals',
          defaultMessage: 'Total Rentals',
        },
        bookedSavings: {
          id: 'app.containers.TopVisualContainer.analysis.car.rentalbooking.bookedSavings',
          defaultMessage: 'Booked vs Paid Savings',
        },
        bookedLoss: {
          id: 'app.containers.TopVisualContainer.analysis.car.rentalbooking.notApprovedSpend',
          defaultMessage: 'Booked vs Paid Loss',
        },
        pickedUpSavings: {
          id: 'app.containers.TopVisualContainer.analysis.car.rentalbooking.notApprovedSpend',
          defaultMessage: 'Picked-Up vs Paid Savings',
        },
        pickedUpLoss: {
          id: 'app.containers.TopVisualContainer.analysis.car.rentalbooking.notApprovedSpend',
          defaultMessage: 'Picked-Up vs Paid Loss',
        },
      },
      benchmark: {
        totalSpend: {
          id: 'app.containers.TopVisualContainer.analysis.car.benchmark.totalSpend',
          defaultMessage: 'Total Spend',
        },
        carRentalDays: {
          id: 'app.containers.TopVisualContainer.analysis.car.benchmark.carRentalDays',
          defaultMessage: 'Total Rentals',
        },
        averageCarRentalRate: {
          id: 'app.containers.TopVisualContainer.analysis.car.benchmark.averageCarRentalRate',
          defaultMessage: 'Average Daily Rate of Paid',
        },
        averageBenchmarkRate: {
          id: 'app.containers.TopVisualContainer.analysis.car.benchmark.averageBenchmarkRate',
          defaultMessage: 'Average Daily Rate of Benchmark',
        },
        averageRateVariance: {
          id: 'app.containers.TopVisualContainer.analysis.car.benchmark.averageRateVariance',
          defaultMessage: 'Average Daily Rate of Variance',
        },
      },
      rebate: {
        totalSpend: {
          id: 'app.containers.TopVisualContainer.analysis.car.rebate.totalSpend',
          defaultMessage: 'Total Spend',
        },
        totalBusinessSpend: {
          id: 'app.containers.TopVisualContainer.analysis.car.rebate.totalBusinessSpend',
          defaultMessage: 'Total Business Spend',
        },
        totalRebateAmount: {
          id: 'app.containers.TopVisualContainer.analysis.car.rebate.totalRebateAmount',
          defaultMessage: 'Total Rebate Amount',
        },
      },
      compliance: {  
        totalRentals: {
          id: 'app.containers.TopVisualContainer.analysis.car.compliance.totalRentals',
          defaultMessage: 'Total Rentals',
        },
        totalSpends: {
          id: 'app.containers.TopVisualContainer.analysis.car.compliance.totalSpends',
          defaultMessage: 'Total Spends',
        },
        lostSavingsOutofPolicy: {
          id: 'app.containers.TopVisualContainer.analysis.car.compliance.lostSavingsOutofPolicy',
          defaultMessage: 'Out of Policy Lost Savings',
        },
        lostSavingsVendorClarificationNeeded: {
          id: 'app.containers.TopVisualContainer.analysis.car.compliance.lostSavingsVendorClarificationNeeded',
          defaultMessage: 'Vendor Clarification Needed Lost Savings',
        },
        quantityInPolicy: {
          id: 'app.containers.TopVisualContainer.analysis.car.compliance.quantityInPolicy',
          defaultMessage: 'In Policy Transactions',
        },
        quantityOutofPolicy: {
          id: 'app.containers.TopVisualContainer.analysis.car.compliance.quantityOutofPolicy',
          defaultMessage: 'Out of Policy Transactions',
        },
        quantityVendorClarificationNeeded: {
          id: 'app.containers.TopVisualContainer.analysis.car.compliance.quantityVendorClarificationNeeded',
          defaultMessage: 'Vendor Clarification Needed Transactions',
        },

        spendInPolicy: {
          id: 'app.containers.TopVisualContainer.analysis.car.compliance.spendInPolicy',
          defaultMessage: 'In Policy Spend',
        },
        spendOutofPolicy: {
          id: 'app.containers.TopVisualContainer.analysis.car.compliance.spendOutofPolicy',
          defaultMessage: 'Out of Policy Spend',
        },
        spendVendorClarificationNeeded: {
          id: 'app.containers.TopVisualContainer.analysis.car.compliance.spendVendorClarificationNeeded',
          defaultMessage: 'Vendor Clarification Needed Spend',
        },
      },
    },
    card: {
      credit: {
        totalCards: {
          id: 'app.containers.TopVisualContainer.analysis.card.credit.totalCards',
          defaultMessage: 'Total Cards',
        },
        activeCards: {
          id: 'app.containers.TopVisualContainer.analysis.card.credit.activeCards',
          defaultMessage: 'Active Cards',
        },
        activeEmpCancelledCards: {
          id: 'app.containers.TopVisualContainer.analysis.card.credit.activeEmpCancelledCards',
          defaultMessage: 'Active Employee Cancelled Cards',
        },
        exitEmpCancelledCards: {
          id: 'app.containers.TopVisualContainer.analysis.card.credit.exitEmpCancelledCards',
          defaultMessage: 'Exit Employee Cancelled Cards',
        },
        totalCardsAmount: {
          id: 'app.containers.TopVisualContainer.analysis.card.credit.totalCardsAmount',
          defaultMessage: 'Total Credits',
        },
        activeCardsAmount: {
          id: 'app.containers.TopVisualContainer.analysis.card.credit.activeCardsAmount',
          defaultMessage: 'Active Credits',
        },
        activeEmpCancelledCardsAmount: {
          id: 'app.containers.TopVisualContainer.analysis.card.credit.activeEmpCancelledCardsAmount',
          defaultMessage: 'Active Employee Cancelled Cards Credits',
        },
        exitEmpCancelledCardsAmount: {
          id: 'app.containers.TopVisualContainer.analysis.card.credit.exitEmpCancelledCardsAmount',
          defaultMessage: 'Exit Employee Cancelled Cards Credits',
        },
        totalDebit: {
          id: 'app.containers.TopVisualContainer.analysis.card.credit.totalDebit',
          defaultMessage: 'Total Cards with Debit',
        },
        totalDebitAmount: {
          id: 'app.containers.TopVisualContainer.analysis.card.credit.totalDebitAmount',
          defaultMessage: 'Total Debit Amount',
        },
      },
      compliance: {
        currentCreditLoss: {
          id: 'app.containers.TopVisualContainer.analysis.card.compliance.currentCreditLoss',
          defaultMessage: '{prefix} Credit Loss',
        },
        currentDelinquency: {
          id: 'app.containers.TopVisualContainer.analysis.card.compliance.currentDelinquency',
          defaultMessage: '{prefix} Delinquency',
        },
        preCreditLoss: {
          id: 'app.containers.TopVisualContainer.analysis.card.compliance.preCreditLoss',
          defaultMessage: '{prefix} Credit Loss',
        },
        preDelinquency: {
          id: 'app.containers.TopVisualContainer.analysis.card.compliance.preDelinquency',
          defaultMessage: '{prefix} Delinquency',
        },
      },
      payment: {
        totalTransactions:{
          id: 'app.containers.TopVisualContainer.analysis.card.payment.totalTransactions',
          defaultMessage: 'Total Corporate Card Transactions',
        },
        totalSpend: {
          id: 'app.containers.TopVisualContainer.analysis.card.payment.totalSpend',
          defaultMessage: 'Total Corporate Cards Charge Volumes',
        },
        totalOwners: {
          id: 'app.containers.TopVisualContainer.analysis.card.payment.totalOwners',
          defaultMessage: 'Total Corporate Cards in Force',
        },
        averageSpend: {
          id: 'app.containers.TopVisualContainer.analysis.card.payment.averageSpend',
          defaultMessage: 'Total Corporate Card Charge Volumes per Card Member',
        }
      },
      incentive: {
        totalIncentivePayment: {
          id: 'app.containers.TopVisualContainer.analysis.card.incentive.totalIncentivePayment',
          defaultMessage: 'Annual Incentive Payment'
        },
        netIncentive: {
          id: 'app.containers.TopVisualContainer.analysis.card.incentive.netIncentive',
          defaultMessage: 'Net Annual Incentive'
        },
        sbExpense: {
          id: 'app.containers.TopVisualContainer.analysis.card.incentive.sbExpense',
          defaultMessage: 'S&B Expense'
        },
        totalLibor: {
          id: 'app.containers.TopVisualContainer.analysis.card.incentive.totalLibor',
          defaultMessage: 'LIBOR'
        }
        
      },
      leakage: {
        currentNonPreferredAmount: {
          id: 'app.containers.TopVisualContainer.analysis.card.leakage.currentNonPreferredAmount',
          defaultMessage: '{prefix} Non-preferred Charge Volume',
        },
        currentMerchantAcceptAmount: {
          id: 'app.containers.TopVisualContainer.analysis.card.leakage.currentMerchantAcceptAmount',
          defaultMessage: '{prefix} Non-usage of Corporate Card with Card Charge Volume',
        },
      },
    },
    meeting: {
      cventRFP: {
        totalEstimated: {
          id: 'app.containers.TopVisualContainer.analysis.meeting.cvent.totalEstimated',
          defaultMessage: 'Total Estimated',
        },
        totalContracted: {
          id: 'app.containers.TopVisualContainer.analysis.meeting.cvent.totalContracted',
          defaultMessage: 'Total Contracted',
        },
        totalVariance: {
          id: 'app.containers.TopVisualContainer.analysis.meeting.cvent.totalVariance',
          defaultMessage: 'Estimated - Contracted',
        },
        totalEvents: {
          id: 'app.containers.TopVisualContainer.analysis.meeting.cvent.totalEvents',
          defaultMessage: 'Total Events',
        },
      },
      cventSupplier: {
        totalRFPSent: {
          id: 'app.containers.TopVisualContainer.analysis.meeting.cventSupplier.totalRFPSent',
          defaultMessage: 'Total RFP Sent',
        },
        notReply: {
          id: 'app.containers.TopVisualContainer.analysis.meeting.cventSupplier.notReply',
          defaultMessage: 'No Reply',
        },
        hotelDeclined: {
          id: 'app.containers.TopVisualContainer.analysis.meeting.cventSupplier.hotelDeclined',
          defaultMessage: 'Hotel Declined',
        },
        notContracted: {
          id: 'app.containers.TopVisualContainer.analysis.meeting.cventSupplier.notContracted',
          defaultMessage: 'Responsed but not contracted',
        },
        contracted: {
          id: 'app.containers.TopVisualContainer.analysis.meeting.cventSupplier.contracted',
          defaultMessage: 'Contracted',
        },
        contractRate: {
          id: 'app.containers.TopVisualContainer.analysis.meeting.cventSupplier.totalRFPSent',
          defaultMessage: 'Contracted %',
        },
      },
      meetingRate: {
        totalSpend: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.meetingRate.totalSpends',
          defaultMessage: 'Total Spend',
        },
        totalNights: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.meetingRate.totalNights',
          defaultMessage: 'Total Nights',
        },
        avgRate: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.meetingRate.avgRate',
          defaultMessage: 'Avg. Rate',
        },
        avgStay: {
          id: 'app.containers.TopVisualContainer.analysis.hotel.meetingRate.avgStay',
          defaultMessage: 'Avg. Stay',
        },
      },
    }
  },
  admin: {
    hotelmatching: {
      totalHotels: {
        id: 'app.containers.TopVisualContainer.admin.hotelmatching.totalHotels',
        defaultMessage: 'Total Hotels',
      },
      approvedHotels: {
        id: 'app.containers.TopVisualContainer.admin.hotelmatching.approvedHotels',
        defaultMessage: 'Approved Hotels',
      },
      incompleteHotels: {
        id: 'app.containers.TopVisualContainer.admin.hotelmatching.incompleteHotels',
        defaultMessage: 'Incomplete Hotels',
      },
      notApprovedHotels: {
        id: 'app.containers.TopVisualContainer.admin.hotelmatching.notApprovedHotels',
        defaultMessage: 'Not Approved Hotels',
      },
      totalSpend: {
        id: 'app.containers.TopVisualContainer.admin.hotelmatching.totalSpend',
        defaultMessage: 'Total Spend',
      },
      approvedSpend: {
        id: 'app.containers.TopVisualContainer.admin.hotelmatching.approvedSpend',
        defaultMessage: 'Approved Spend',
      },
      incompleteSpend: {
        id: 'app.containers.TopVisualContainer.admin.hotelmatching.incompleteSpend',
        defaultMessage: 'Incomplete Spend',
      },
      notApprovedSpend: {
        id: 'app.containers.TopVisualContainer.admin.hotelmatching.notApprovedSpend',
        defaultMessage: 'Not Approved Spend',
      },
    }
  },
  notifications: {
    dataRequest: {
      error: {
        title: {
          id: 'app.components.Notifications.messages.TopVisualContainer.errors.dataRequest.title',
          defaultMessage: 'TopVisual Failed',
        },
        body: {
          id: 'app.components.Notifications.messages.TopVisualContainer.errors.dataRequest.body',
          defaultMessage: `Failed to fetch Top Visual Data for page { page }. Please contact an
          administrator about this issue`,
        },
      },
    },
  },
});