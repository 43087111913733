import { List } from 'immutable';

// https://github.com/facebook/immutable-js/issues/1452
const isMergeable = a => (
  a && typeof a === 'object' && typeof a.mergeWith === 'function' && !List.isList(a)
);

// toSet().toList() is equal to distinct(), only the latter does not exist in immutable
export const distinctLists = a => {
  if (List.isList(a)) {
    return a.toSet().toList();
  }
  return a;
};

export const mergeDeep = (a, b) => {
  // If b is null, it would overwrite a, even if a is mergeable
  if (isMergeable(a) && b !== null) {
    return a.mergeWith(mergeDeep, b);
  }

  if (!List.isList(a) || !List.isList(b)) {
    return b;
  }

  return b.reduce((acc, nextItem, index) => {
    const existingItem = acc.get(index);
    if (isMergeable(existingItem)) {
      return acc.set(index, existingItem.mergeWith(mergeDeep, nextItem));
    }

    return acc.set(index, nextItem);
  }, a);
};

export const mergeDeepOverwriteLists = (a, b) => {
  // If b is null, it would overwrite a, even if a is mergeable
  if (b === null) return b;

  if (isMergeable(a) && !List.isList(a)) {
    return a.mergeWith(mergeDeepOverwriteLists, b);
  }

  return b;
};
