import { handleActions } from 'redux-actions';
import updateObject from 'Utils/updateObject';
import {
  scenarioOptionsClear,
  scenarioOptionsRequest
} from 'Containers/ScenariosContainer/actions';
import {
  scenarioModalStateUpdate,
  scenarioModalClose,
  scenarioModalOpen,
  scenarioModalTypeSet,
  scenarioModalStateClear,
  scenarioModalUpdate,
} from './actions';
import { types } from './constants';

export const initialState = {
  open: false,
  state: null,
  type: types[0],
  options: {},
};

const scenarioModalReducer = handleActions({
  [scenarioModalOpen]: state => updateObject(state, { open: true }),
  [scenarioModalClose]: state => updateObject(state, { open: false }),
  [scenarioModalStateUpdate]: (state, { payload }) => updateObject(state, { state: payload }),
  [scenarioModalStateClear]: state => updateObject(state, { state: null }),
  [scenarioModalTypeSet]: (state, { payload }) => updateObject(state, { type: payload }),
  [scenarioModalUpdate]: (state, { payload }) => updateObject(state, payload),
  [scenarioOptionsRequest.SUCCESS]: (state, { payload }) => updateObject(state, {
    options: payload,
  }),
  [scenarioOptionsClear]: state => updateObject(state, { options: initialState.options }),
}, initialState);

export default scenarioModalReducer;
