import { createAction } from 'redux-actions';
import {
  VIEWS_REQUEST,
  VIEWS_CLEAR,
  VIEWS_SUCCESS,
  VIEWS_FAIL,
  VIEW_SET_ACTIVE,
  VIEW_CLEAR_ACTIVE,
} from './constants';

export const viewsRequest = createAction(VIEWS_REQUEST, page => ({ page }));
export const viewsSuccess = createAction(VIEWS_SUCCESS, data => ({ data }));
export const viewsFail = createAction(VIEWS_FAIL);
export const viewsClear = createAction(VIEWS_CLEAR);
export const viewSetActive = createAction(VIEW_SET_ACTIVE, (page, view) => ({ page, view }));
export const viewClearActive = createAction(VIEW_CLEAR_ACTIVE, page => ({ page }));
