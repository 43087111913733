import { shape, arrayOf, string, bool } from 'prop-types';

const UserPropType = shape({
  name: string,
  cnum: string,
  email: string,
  photo: string,
  acceptedConsent: bool,
  allowPersonalInformation: bool,
  group: arrayOf(string),
  geos: arrayOf(string),
  countries: arrayOf(string),
});

export const UserSettings = shape({
  language: string,
  currency: string,
  currencyFormat: string,
  numberFormat: string,
});

export default UserPropType;
