import { defineMessages } from 'react-intl';

const staticMessages = {
  advanceBooking: 'Advance Purchase By Tickets',
  bookingMethod: 'Booking Method',
  bookingMethodDD: 'Booking Method',
  compliance: 'Compliance Rate',
  daysOfWeek: 'Days-of-Week Breakdown',
  miscellaneousSpend: 'Miscellaneous Spend',
  miscByCategory: 'Misc Spend by Category',
  paymentType: 'Payment Method',
  spendByAmount: 'Ground Transportation Spend By Amount',
  topCitiesBySpend: 'Top 5 Cities By Destination (Spend)',
  topCountriesBySpend: 'Top 5 Countries By Destination (Spend)',
  total: 'Total',
  spendByDestinationGeo: 'Spend by Destination IOT',
  // projectedSpendCity: 'Top Hotel Group - {suffix}',
  projectedSpendCity: 'Top 5 {suffix}',
  dynamicFixedDestinations: 'Dynamic vs. Fixed Top Destinations',
  categoryAvgPricePerPayment: 'Category Average Price Per Payment Form',
  top10: 'Top 10 {suffix}',
};

export default defineMessages({
  placeholder: {
    id: 'app.containers.ChartContainer.placeholder',
    defaultMessage: '{ message }',
  },
  trend: {
    id: 'app.containers.ChartContainer.trend',
    defaultMessage: 'Trend Chart',
  },
  notifications: {
    createAlert: {
      success: {
        title: {
          id: 'app.components.Notifications.messages.ChartContainer.success.createAlert.title',
          defaultMessage: 'New Alert Created',
        },
        body: {
          id: 'app.components.Notifications.messages.ChartContainer.success.createAlert.title',
          defaultMessage: `Alert for chart { name } created successfully`,
        },
      },
      error: {
        title: {
          id: 'app.components.Notifications.messages.ChartContainer.error.createAlert.title',
          defaultMessage: 'Alert Failed',
        },
        body: {
          id: 'app.components.Notifications.messages.ChartContainer.error.createAlert.title',
          defaultMessage: `Failed creating alert for chart { name }. Please try again or contact an 
          administrator if the issue persists.`,
        },
      },
    },
    dataRequest: {
      error: {
        title: {
          id: 'app.components.Notifications.messages.ChartContainer.errors.dataRequest.title',
          defaultMessage: 'Charts Failed',
        },
        body: {
          id: 'app.components.Notifications.messages.ChartContainer.errors.dataRequest.body',
          defaultMessage: `Failed to fetch Chart Data for page { page }. Please contact an
          administrator about this issue`,
        },
      },
    },
    chartExport: {
      error: {
        title: {
          id: 'app.components.Notifications.messages.ChartContainer.errors.chartExport.title',
          defaultMessage: 'Export Failed',
        },
        body: {
          id: 'app.components.Notifications.messages.ChartContainer.errors.chartExport.body',
          defaultMessage: `There was an error trying to export the { name } chart. Please contact an
          administrator about this issue`,
        },
      },
      success: {
        title: {
          id: 'app.components.Notifications.messages.ChartContainer.success.chartExport.title',
          defaultMessage: 'Export Succesfull',
        },
        body: {
          id: 'app.components.Notifications.messages.ChartContainer.success.chartExport.body',
          defaultMessage: `Your chart { name } has been successfully exported`,
        },
      },
    }
  },
  dashboard: {
    manager: {
      airBookingMethod: {
        id: 'app.containers.ChartContainer.dashboard.manager.airBookingMethod',
        defaultMessage: 'Air Booking Method (Spend)',
      },
      airCompliance: {
        id: 'app.containers.ChartContainer.dashboard.manager.airCompliance',
        defaultMessage: 'Air Compliance (Spend)',
      },
      airTopVendors: {
        id: 'app.containers.ChartContainer.dashboard.manager.airTopVendors',
        defaultMessage: 'Air Top 5 Vendors (Spend)',
      },
      carBookingMethod: {
        id: 'app.containers.ChartContainer.dashboard.manager.carBookingMethod',
        defaultMessage: 'Car Rental Booking Method (Spend)',
      },
      carCompliance: {
        id: 'app.containers.ChartContainer.dashboard.manager.carCompliance',
        defaultMessage: 'Car Rental Compliance (Spend)',
      },
      carTopVendors: {
        id: 'app.containers.ChartContainer.dashboard.manager.carTopVendors',
        defaultMessage: 'Car Rental Top 5 Vendors (Spend)',
      },
      gtBuckets: {
        id: 'app.containers.ChartContainer.dashboard.manager.gtBuckets',
        defaultMessage: staticMessages.spendByAmount,
      },
      gtTopCitiesBySpend: {
        id: 'app.containers.ChartContainer.dashboard.manager.gtTopCitiesBySpend',
        defaultMessage: 'Ground Transportation Top 5 Cities By Destination (Spend) (Expense only)',
      },
      gtType: {
        id: 'app.containers.ChartContainer.dashboard.manager.gtType',
        defaultMessage: 'Ground Transportation Type (Spend)',
      },
      hotelBookingMethod: {
        id: 'app.containers.ChartContainer.dashboard.manager.hotelBookingMethod',
        defaultMessage: 'Hotel Booking Method (Spend)',
      },
      hotelCompliance: {
        id: 'app.containers.ChartContainer.dashboard.manager.hotelCompliance',
        defaultMessage: 'Hotel Compliance (Spend)',
      },
      hotelTopVendors: {
        id: 'app.containers.ChartContainer.dashboard.manager.hotelTopVendors',
        defaultMessage: 'Hotel Top 5 Vendors (Spend)',
      },
      topCountriesBySpend: {
        id: 'app.containers.ChartContainer.dashboard.manager.topCountriesBySpend',
        defaultMessage: 'Hotel Top 5 Countries By Destination (Spend)',
      },
      miscByCategory: {
        id: 'app.containers.ChartContainer.dashboard.manager.miscByCategory',
        defaultMessage: staticMessages.miscByCategory + ' (Expense only)',
      },
      spendByDestGeo: {
        id: 'app.containers.ChartContainer.dashboard.manager.spendByDestGeo',
        defaultMessage: staticMessages.spendByDestinationGeo,
      },
      spendByCategory: {
        id: 'app.containers.ChartContainer.dashboard.manager.spendByCategory',
        defaultMessage: 'Spend by Category',
      },
      spendByLob: {
        id: 'app.containers.ChartContainer.dashboard.manager.spendByLob',
        defaultMessage: 'Spend by LOB',
      },
      trend: {
        id: 'app.containers.ChartContainer.dashboard.manager.trend',
        defaultMessage: 'Manager Trend Chart',
      },
      meetingEventsTopCities: {
        id: 'app.containers.ChartContainer.dashboard.manager.meetingEventsTopCities',
        defaultMessage: 'Meeting Top 5 Cities',
      },
      meetingEventsTopVendors: {
        id: 'app.containers.ChartContainer.dashboard.manager.meetingEventsTopVendors',
        defaultMessage: 'Meeting Top 5 Vendors',
      },
    },
    air: {
      advanceBooking: {
        id: 'app.containers.ChartContainer.dashboard.air.advanceBooking',
        defaultMessage: staticMessages.advanceBooking,
      },
      bookingMethod: {
        id: 'app.containers.ChartContainer.dashboard.air.bookingMethod',
        defaultMessage: staticMessages.bookingMethod,
      },
      classTickets: {
        id: 'app.containers.ChartContainer.dashboard.air.classTickets',
        defaultMessage: 'Cabin Class (Tickets)',
      },
      complianceTickets: {
        id: 'app.containers.ChartContainer.dashboard.air.complianceTickets',
        defaultMessage: 'Compliance Rate',
      },
      flightTypeSpend: {
        id: 'app.containers.ChartContainer.dashboard.air.flightTypeSpend',
        defaultMessage: 'Flight Type Spend',
      },
      flightTypeTickets: {
        id: 'app.containers.ChartContainer.dashboard.air.flightTypeTickets',
        defaultMessage: 'Flight Type Tickets',
      },
      geoSpend: {
        id: 'app.containers.ChartContainer.dashboard.air.geoSpend',
        defaultMessage: 'Travel Spend By Employee IOT',
      },
      geoTickets: {
        id: 'app.containers.ChartContainer.dashboard.air.geoTickets',
        defaultMessage: 'Travel Ticket By Employee IOT',
      },
      routeTickets: {
        id: 'app.containers.ChartContainer.dashboard.air.routeTickets',
        defaultMessage: 'Route Type Tickets',
      },
      trend: {
        id: 'app.containers.ChartContainer.dashboard.manager.trend',
        defaultMessage: 'Air Trend Chart',
      },
      destGeoSpend: {
        id: 'app.containers.ChartContainer.dashboard.manager.destGeoSpend',
        defaultMessage: 'Travel Spend By Destination IOT',
      },
    },
    hotel: {
      bookingMethod: {
        id: 'app.containers.ChartContainer.dashboard.hotel.bookingMethod',
        defaultMessage: 'Hotel Booking Method (Spend)',
      },
      compliance: {
        id: 'app.containers.ChartContainer.dashboard.hotel.compliance',
        defaultMessage: 'Hotel Compliance (Spend)',
      },
      hotelTopVendors: {
        id: 'app.containers.ChartContainer.dashboard.hotel.hotelTopVendors',
        defaultMessage: 'Hotel Top 5 Vendors (Spend)',
      },
      daysOfWeek: {
        id: 'app.containers.ChartContainer.dashboard.hotel.daysOfWeek',
        defaultMessage: 'Days-of-week-Breakdown',
      },
      geoTravelByExpenditure: {
        id: 'app.containers.ChartContainer.dashboard.hotel.geoTravelByExpenditure',
        defaultMessage: 'Travel Spend By Employee IOT',
      },
      geoTravelByVolume: {
        id: 'app.containers.ChartContainer.dashboard.hotel.geoTravelByVolume',
        defaultMessage: 'Travel Volume By Employee IOT',
      },
      miscellaneousSpend: {
        id: 'app.containers.ChartContainer.dashboard.hotel.miscellaneousSpend',
        defaultMessage: staticMessages.miscellaneousSpend,
      },
      paymentType: {
        id: 'app.containers.ChartContainer.dashboard.hotel.paymentType',
        defaultMessage: 'Payment Type',
      },
      topCitiesBySpend: {
        id: 'app.containers.ChartContainer.dashboard.hotel.topCitiesBySpend',
        defaultMessage: 'Top 5 Cities By Destination (Spend)',
      },
      topCountriesBySpend: {
        id: 'app.containers.ChartContainer.dashboard.hotel.topCountriesBySpend',
        defaultMessage: staticMessages.topCountriesBySpend,
      },
      trend: {
        id: 'app.containers.ChartContainer.dashboard.manager.trend',
        defaultMessage: 'Hotel Trend Chart',
      },
    },
    car: {
      bookingMethod: {
        id: 'app.containers.ChartContainer.dashboard.car.bookingMethod',
        defaultMessage: 'Car Rental Booking Method (Spend)',
      },
      bookingMethodDD: {
        id: 'app.containers.ChartContainer.dashboard.car.bookingMethodDD',
        defaultMessage: staticMessages.bookingMethodDD,
      },
      geoTravelByVolume: {
          id: 'app.containers.ChartContainer.dashboard.car.geoTravelByVolume',
          defaultMessage: 'Total IOT # of Rentals (Destination)',
        },
      geoTravelByExpenditure: {
          id: 'app.containers.ChartContainer.dashboard.car.geoTravelByExpenditure',
          defaultMessage: 'Total IOT Spend (Destination)',
        },
      complianceTransactionsByCarClass: {
          id: 'app.containers.ChartContainer.dashboard.car.complianceTransactionsByCarClass',
          defaultMessage: 'Compliant # of Rental Transactions by Car Class',
        },
      complianceDaysByCarClass: {
          id: 'app.containers.ChartContainer.dashboard.car.complianceDaysByCarClass',
          defaultMessage: 'Compliant # of Rental Days by Car Class',
        },
      complianceSpendByCarClass: {
          id: 'app.containers.ChartContainer.dashboard.car.complianceSpendByCarClass',
          defaultMessage: 'Car Rental Compliance (Spend)',
        },
      complianceTransactionsByVendor: {
          id: 'app.containers.ChartContainer.dashboard.car.complianceTransactionsByVendor',
          defaultMessage: 'Compliant # of Rental Transactions by Vendor',
        },
      complianceDaysByVendor: {
          id: 'app.containers.ChartContainer.dashboard.car.complianceDaysByVendor',
          defaultMessage: 'Compliant # of Rental Days by Vendor',
        },
      complianceSpendByVendor: {
          id: 'app.containers.ChartContainer.dashboard.car.complianceSpendByVendor',
          defaultMessage: 'Compliant Rental Spend by Vendor',
        },
      volumeByGroup: {
          id: 'app.containers.ChartContainer.dashboard.car.volumeByGroup',
          defaultMessage: 'Rental Days by Rental Group',
        },
      volumeByVendor: {
          id: 'app.containers.ChartContainer.dashboard.car.volumeByVendor',
          defaultMessage: 'Rental Days by Rental Company',
        },
      travelPurposeSpend: {
          id: 'app.containers.ChartContainer.dashboard.car.travelPurposeSpend',
          defaultMessage: 'Travel Purpose Rental Spend Breakdown',
        },
      travelPurposeTickets: {
          id: 'app.containers.ChartContainer.dashboard.car.travelPurposeTickets',
          defaultMessage: 'Travel Purpose # of Rental Transactions Breakdown',
        },
      topCitiesBySpend: {
          id: 'app.containers.ChartContainer.dashboard.car.topCitiesBySpend',
          defaultMessage: staticMessages.topCitiesBySpend,
        },
      paymentType: {
          id: 'app.containers.ChartContainer.dashboard.car.paymentType',
          defaultMessage: staticMessages.paymentType,
        },
      volumeByCarType: {
          id: 'app.containers.ChartContainer.dashboard.car.volumeByCarType',
          defaultMessage: 'Top 5 Rented Car Types',
        },
      airportLocation: {
          id: 'app.containers.ChartContainer.dashboard.car.airportLocation',
          defaultMessage: 'Rented at Airport',
        },
      miscellaneousSpend: {
          id: 'app.containers.ChartContainer.dashboard.car.miscellaneousSpend',
          defaultMessage: staticMessages.miscellaneousSpend,
        },
      rentBuckets: {
          id: 'app.containers.ChartContainer.dashboard.car.rentBuckets',
          defaultMessage: 'Rent Days',
        },
      transactionsByGroup: {
          id: 'app.containers.ChartContainer.dashboard.car.transactionsByGroup',
          defaultMessage: 'Transactions by Rental Group',
        },
      transactionsByVendor: {
        id: 'app.containers.ChartContainer.dashboard.car.transactionsByVendor',
        defaultMessage: 'Transactions by Rental Company',
      },
      spendByRentalGroup: {
        id: 'app.containers.ChartContainer.dashboard.car.spendByRentalGroup',
        defaultMessage: 'Total spend by Rental Group',
      },
      spendByRentalCompany: {
        id: 'app.containers.ChartContainer.dashboard.car.spendByRentalCompany',
        defaultMessage: 'Car Rental Top 5 Vendors (Spend)',
      },
      trend: {
        id: 'app.containers.ChartContainer.dashboard.manager.trend',
        defaultMessage: 'Car Rental Trend Chart',
      },
    },
    ground: {
      daysOfWeek: {
        id: 'app.containers.ChartContainer.dashboard.ground.daysOfWeek',
        defaultMessage: staticMessages.daysOfWeek + " (Expense only)",
      },
      geoTravelByExpenditure: {
        id: 'app.containers.ChartContainer.dashboard.ground.geoTravelByExpenditure',
        defaultMessage: 'Travel Spend By Destination IOT',
      },
      geoTravelByVolume: {
        id: 'app.containers.ChartContainer.dashboard.ground.geoTravelByVolume',
        defaultMessage: 'Travel Volume By Destination IOT (Expense only)',
      },
      gtBuckets: {
        id: 'app.containers.ChartContainer.dashboard.ground.gtBuckets',
        defaultMessage: staticMessages.spendByAmount,
      },
      gtType: {
        id: 'app.containers.ChartContainer.dashboard.ground.gtType',
        defaultMessage: 'Ground Transportation Type (Spend)',
      },
      miscByCategory: {
        id: 'app.containers.ChartContainer.dashboard.ground.miscByCategory',
        defaultMessage: staticMessages.miscByCategory + " (Expense only)",
      },
      paymentType: {
        id: 'app.containers.ChartContainer.dashboard.ground.paymentType',
        defaultMessage: staticMessages.paymentType,
      },
      categoryAvgPricePerPayment: {
        id: 'app.containers.ChartContainer.dashboard.ground.categoryAvgPricePerPayment',
        defaultMessage: staticMessages.categoryAvgPricePerPayment + " (Expense only)",
      },
      topCitiesBySpend: {
        id: 'app.containers.ChartContainer.dashboard.ground.topCitiesBySpend',
        defaultMessage: 'Ground Transportation Top 5 Cities By Destination (Spend) (Expense only)',
      },
      trend: {
        id: 'app.containers.ChartContainer.dashboard.manager.trend',
        defaultMessage: 'Ground Transportation Trend Chart',
      },
      topCountryByPOSpend: {
        id: 'app.containers.ChartContainer.dashboard.manager.topCountryByPOSpend',
        defaultMessage: 'Ground Transportation Top 5 Countries (Spend) (PO only)'
      },
      topVendor: {
        id: 'app.containers.ChartContainer.dashboard.manager.topVendorByPOSpend',
        defaultMessage: 'Top 10 Vendors (Spend)'
      }
    },
    card: {
      inForceByCardType: {
        id: 'app.containers.ChartContainer.dashboard.card.cardVsCashAmount',
        defaultMessage: 'Total Cards in Force by Card Type',
      },
      transactionsByCardType: {
        id: 'app.containers.ChartContainer.dashboard.card.transactionsByCardType',
        defaultMessage: 'Booking Transactions / Card Type',
      },
      spendByCardType: {
        id: 'app.containers.ChartContainer.dashboard.card.spendByCardType',
        defaultMessage: 'Booking Spend Amount / Card Type',
      },
      spendByPaymentForm: {
        id: 'app.containers.ChartContainer.dashboard.card.spendByPaymentForm',
        defaultMessage: 'Booking Spend',
      },
      cardVsCashTransactions: {
        id: 'app.containers.ChartContainer.dashboard.card.cardVsCashTransactions',
        defaultMessage: 'Transactions by Card Type',
      },
      transactionsByPaymentForm: {
        id: 'app.containers.ChartContainer.dashboard.card.transactionsByPaymentForm',
        defaultMessage: 'Booking Transactions',
      },
      stackedBarChartDataTrans: {
        id: 'app.containers.ChartContainer.dashboard.card.stackedBarChartDataTrans',
        defaultMessage: 'Travel Commodity Transactions by Corporate Card and Other Forms of Payment',
      },
      stackedBarChartData: {
        id: 'app.containers.ChartContainer.dashboard.card.stackedBarChartData',
        defaultMessage: 'Travel Commodity by Corporate Card and Other Forms of Payment',
      },
      top10CountrySpendChart: {
        id: 'app.containers.ChartContainer.dashboard.card.top10CountrySpendChart',
        defaultMessage: 'Spend by Top 10 Countries',
      },
      iotSpendChart: {
        id: 'app.containers.ChartContainer.dashboard.card.iotSpendChart',
        defaultMessage: 'Spend By IOT',
      },
      commoditySpendChart: {
        id: 'app.containers.ChartContainer.dashboard.card.commoditySpendChart',
        defaultMessage: 'Spend by Commodity',
      },
      trend: {
        id: 'app.containers.ChartContainer.dashboard.manager.trend',
        defaultMessage: 'Card Expense Trend Chart',
      },
      volumeSpendByCardType: {
        id: 'app.containers.ChartContainer.dashboard.volumeSpendByCardType',
        defaultMessage: 'Charge Volumes by Card Type',
      }
    },
    meetings: {
      byCategory: {
        id: 'app.containers.ChartContainer.dashboard.meetings.byCategory',
        defaultMessage: 'Meeting Category',
      },
      byDestCity: {
        id: 'app.containers.ChartContainer.dashboard.meetings.byDestCity',
        defaultMessage: 'Top 5 Cities',
      },
      byDestCountry: {
        id: 'app.containers.ChartContainer.dashboard.meetings.byDestCountry',
        defaultMessage: 'Top 5 Countries',
      },
      byHotelGroup: {
        id: 'app.containers.ChartContainer.dashboard.meetings.byHotelGroup',
        defaultMessage: 'Top 5 Vendors',
      },
      byLob: {
        id: 'app.containers.ChartContainer.dashboard.meetings.byLob',
        defaultMessage: 'Spend by LOB',
      },
    }
  },
  analysis: {
    air: {
      advanced: {
        common: {
          spendChart: {
            id: 'app.containers.ChartContainer.analysis.air.advanced.spendChart',
            defaultMessage: 'Advance Purchase By Spend',
          },
          advanceBooking: {
            id: 'app.containers.ChartContainer.analysis.air.advanced.advanceBooking',
            defaultMessage: 'Advance Purchase By Tickets',
          },
          trendChart: {
            id: 'app.containers.ChartContainer.analysis.air.advanced.trendChart',
            defaultMessage: 'Quarterly Purchase Trend',
          },
        },
      },
      agency: {
        firstChart: {
          id: 'app.containers.ChartContainer.analysis.air.agency.topAgencySavings',
          defaultMessage: 'Top 5 Agency Savings',
        },
        secondChart: {
          id: 'app.containers.ChartContainer.analysis.air.agency.topAgencyLossSavings',
          defaultMessage: 'Top 5 Agency Loss Savings',
        },
        thirdChart: {
          id: 'app.containers.ChartContainer.analysis.air.agency.topFareSavings',
          defaultMessage: 'Top 5 Fare Savings',
        },
      },
      citypair: {
        common: {
          firstChartData: {
            id: 'app.containers.ChartContainer.analysis.air.citypair.common.firstChartData',
            defaultMessage: 'Dynamic vs. Fixed Top Destinations',
          },
        },
        carrier: {
          firstChartData: {
            id: 'app.containers.ChartContainer.analysis.air.citypair.carrier.firstChartData',
            defaultMessage: '{suffix} Top Carriers',
          },
          secondChartData: {
            id: 'app.containers.ChartContainer.analysis.air.citypair.carrier.secondChartData',
            defaultMessage: '{suffix} Top Carriers',
          },
          thirdChartData: {
            id: 'app.containers.ChartContainer.analysis.air.citypair.carrier.thirdChartData',
            defaultMessage: 'Top Negative Carriers',
          },
          fourthChartData: {
            id: 'app.containers.ChartContainer.analysis.air.citypair.carrier.fourthChartData',
            defaultMessage: 'Top Gain Carriers',
          },
        },
        cabin: {
          firstChartData: {
            id: 'app.containers.ChartContainer.analysis.air.citypair.cabin.firstChartData',
            defaultMessage: '{suffix} Cabin Class',
          },
          secondChartData: {
            id: 'app.containers.ChartContainer.analysis.air.citypair.cabin.secondChartData',
            defaultMessage: '{suffix} Cabin Class',
          },
          thirdChartData: {
            id: 'app.containers.ChartContainer.analysis.air.citypair.cabin.thirdChartData',
            defaultMessage: '{suffix} Cabin Class',
          },
        },
        booking: {
          firstChartData: {
            id: 'app.containers.ChartContainer.analysis.air.citypair.booking.firstChartData',
            defaultMessage: '{suffix} Booking Class',
          },
          secondChartData: {
            id: 'app.containers.ChartContainer.analysis.air.citypair.booking.secondChartData',
            defaultMessage: '{suffix} Booking Class',
          },
          thirdChartData: {
            id: 'app.containers.ChartContainer.analysis.air.citypair.booking.thirdChartData',
            defaultMessage: '{suffix} Booking Class',
          },
        },
      },
      corporatefare: {
        common: {
          farePublished: {
            id: 'app.containers.ChartContainer.analysis.air.corporatefare.firstChart',
            defaultMessage: 'Top 5 Published Fare Saving Loss/Opportunity',
          },
          fareOther: {
            id: 'app.containers.ChartContainer.analysis.air.corporatefare.secondChart',
            defaultMessage: 'Top 5 Other Fare Saving Loss/Opportunity',
          },
          fareTotal: {
            id: 'app.containers.ChartContainer.analysis.air.corporatefare.thirdChart',
            defaultMessage: 'Top 5 Total Saving Loss/Opportunity',
          },
        },
      },
      onlineadoption: {
        common: {
          firstChart: {
            id: 'app.containers.ChartContainer.analysis.air.onlineadoption.common.firstChart',
            defaultMessage: 'Current Month {prefix} Top 5 {suffix}',
          },
          secondChart: {
            id: 'app.containers.ChartContainer.analysis.air.onlineadoption.common.secondChart',
            defaultMessage: 'Previous Month {prefix} Top 5 {suffix}',
          },
          thirdChart: {
            id: 'app.containers.ChartContainer.analysis.air.onlineadoption.common.thirdChart',
            defaultMessage: 'YTD Top 5 {suffix}',
          },
        },
        emp: {
          firstChart: {
            id: 'app.containers.ChartContainer.analysis.air.onlineadoption.employee.firstChart',
            defaultMessage: 'Total Online Adoption {suffix}',
          },
          secondChart: {
            id: 'app.containers.ChartContainer.analysis.air.onlineadoption.employee.secondChart',
            defaultMessage: 'Current Month {suffix} Top 5 Employees',
          },
          thirdChart: {
            id: 'app.containers.ChartContainer.analysis.air.onlineadoption.employee.thirdChart',
            defaultMessage: 'YTD Top 5 Employees',
          },
        },
        transactions: {
          thirdChart: {
            id: 'app.containers.ChartContainer.analysis.air.onlineadoption.transactions.adoption',
            defaultMessage: 'Total Online Adoption',
          },
          firstChart: {
            id: 'app.containers.ChartContainer.analysis.air.onlineadoption.transactions.compliance',
            defaultMessage: staticMessages.compliance,
          },
          secondChart: {
            id: 'app.containers.ChartContainer.analysis.air.onlineadoption.transactions.trend',
            defaultMessage: 'Online Adoption Trend',
          },
        },
      },
      compliance: {
        common: {
          nonCompliantCodes: {
            id: 'app.containers.ChartContainer.analysis.air.compliance.nonCompliantCodes',
            defaultMessage: 'Non-compliant Codes',
          },
          nonCompliantSavings: {
            id: 'app.containers.ChartContainer.analysis.air.compliance.nonCompliantSavings',
            defaultMessage: 'Top Non-compliant Savings',
          },
          nonCompliantVolume: {
            id: 'app.containers.ChartContainer.analysis.air.compliance.nonCompliantVolume',
            defaultMessage: 'Highest Non-compliant Volume',
          },
        },
      },
      ticketUtilize:{
        ticketUtilizedBarchart: {
          id: 'app.containers.ChartContainer.analysis.air.ticketUtilize.barChartByCommodity',
            defaultMessage: 'Ticket Numbers for Booked and Un-utilized by IOT / for Top 10 countries / for Top 10 LOBs / for Top 10 Vendors',
        }
      },
      contract:{
        totalAmoutByMarket: {
          id: 'app.containers.ChartContainer.analysis.air.contract.totalAmoutByMarket',
            defaultMessage: 'Total Amount by Market',
        },
        top10AmountByAirPortPair: {
          id: 'app.containers.ChartContainer.analysis.air.contract.top10AmountByAirPortPair',
            defaultMessage: 'Top 10 Amount by Airport Pair',
        },
        top10AmountByAirline: {
          id: 'app.containers.ChartContainer.analysis.air.contract.top10AmountByAirline',
            defaultMessage: 'Top 10 Amount by Airline',
        },
        amountByCabinType: {
          id: 'app.containers.ChartContainer.analysis.air.contract.amountByCabinType',
            defaultMessage: 'O&D Amount by Cabin Type',
        },
        odCountChart: {
          id: 'app.containers.ChartContainer.analysis.air.contract.odCountChart',
            defaultMessage: 'O&D Count by Cabin Type',
        },
        barOfMarketShareChart: {
          id: 'app.containers.TopVisualContainer.analysis.air.contract.bar',
          defaultMessage: 'Market Share'
        },
        barOfMarketGoalChart: {
          id: 'app.containers.TopVisualContainer.analysis.air.contract.line',
          defaultMessage: 'Goal'
        },
        barOfmarketShareVarianceChart: {
          id: 'app.containers.TopVisualContainer.analysis.air.contract.marketShareVarianceChart',
          defaultMessage: 'Performance'
        },
        marketShareAndGoal: {
          id: 'app.containers.TopVisualContainer.analysis.air.contract.marketShareAndGoal',
          defaultMessage: 'Market Goal & Share'
        }
      },
    },
    hotel: {
      marketshare: {
        common: {
          latestPeriodChart: {
            id: 'app.containers.ChartContainer.analysis.hotel.marketshare.latestPeriodChart',
            defaultMessage: staticMessages.projectedSpendCity,
          },
          olderPeriodChart: {
            id: 'app.containers.ChartContainer.analysis.hotel.marketshare.olderPeriodChart',
            defaultMessage: staticMessages.projectedSpendCity,
          },
          Chart1: {
            id: 'app.containers.ChartContainer.analysis.hotel.marketshare.Chart1',
            defaultMessage: 'Market-Share Overview',
          },
          Chart2: {
            id: 'app.containers.ChartContainer.analysis.hotel.marketshare.Chart2',
            defaultMessage: staticMessages.projectedSpendCity,
            // defaultMessage: ',',
          },
          varianceChart: {
            id: 'app.containers.ChartContainer.analysis.hotel.marketshare.varianceChart',
            defaultMessage: 'Variance Top Hotel Group',
          },
          PositiveVarianceChart: {
            id: 'app.containers.ChartContainer.analysis.hotel.marketshare.PositiveVarianceChart',
            defaultMessage: 'Positive Variance Top Hotel Group',
          },
        },
      },
      marketshift: {
        common: {
          topSpend: {
            id: 'app.containers.ChartContainer.analysis.hotel.marketshift.topSpend',
            defaultMessage: 'Aggregate Top City',
          },
          barChart: {
            id: 'app.containers.ChartContainer.analysis.hotel.marketshift.barChart',
            defaultMessage: 'Quarterly Top Destinations',
          },
          topSpendScenario: {
            id: 'app.containers.ChartContainer.analysis.hotel.marketshift.topSpendScenario',
            defaultMessage: 'Actual Top Spend',
          },
          topProjectedSpend: {
            id: 'app.containers.ChartContainer.analysis.hotel.marketshift.topProjectedSpend',
            defaultMessage: 'Projected Top Spend',
          },
          variance: {
            id: 'app.containers.ChartContainer.analysis.hotel.marketshift.variance',
            defaultMessage: 'Variance',
          },
        },
      },
      rate: {
        common: {
          dynamicFixed: {
            id: 'app.containers.ChartContainer.analysis.hotel.rate.dynamicFixed',
            defaultMessage: staticMessages.dynamicFixedDestinations,
          },
          rateSavings: {
            id: 'app.containers.ChartContainer.analysis.hotel.rate.rateSavings',
            defaultMessage: 'Total Potential Savings',
          },
          rateTrend: {
            id: 'app.containers.ChartContainer.analysis.hotel.rate.group.rateTrend',
            defaultMessage: 'Fixed vs. Dynamic Monthly Average Rates - {suffix}',
          },
        },
      },
      contract: {
        common: {
          barChart: {
            id: 'app.containers.ChartContainer.analysis.hotel.contract.barChart',
            defaultMessage: staticMessages.dynamicFixedDestinations,
          },
          trendChart: {
            id: 'app.containers.ChartContainer.analysis.hotel.contract.trendChart',
            defaultMessage: 'Dynamic vs. Fixed Top Hotels',
          },
        },
      },
      booking: {
        common: {
          firstChart: {
            id: 'app.containers.ChartContainer.analysis.hotel.booking.firstChart',
            defaultMessage: 'Spend Breakdown',
          },
          secondChart: {
            id: 'app.containers.ChartContainer.analysis.hotel.booking.secondChart',
            defaultMessage: 'Nights Breakdown',
          },
          thirdChartData: {
            id: 'app.containers.ChartContainer.analysis.hotel.booking.thirdChartData',
            defaultMessage: 'Average Rate',
          },
        },
      },
      fixedrate: {
        comparativeChart: {
          id: 'app.containers.ChartContainer.analysis.hotel.fixedRate.comparativeChart',
          defaultMessage: 'Top 5 Paid vs Negotiated',
        },
        varianceChart: {
          id: 'app.containers.ChartContainer.analysis.hotel.fixedRate.varianceChart',
          defaultMessage: 'Top Saving Loss',
        },
      },
    },
    car: {
      compliance: {  
        outOfPolicyLostSavings: {
          id: 'app.containers.ChartContainer.analysis.car.compliance.outOfPolicyLostSavings',
          defaultMessage: 'Top 5 Out-of-Policy Lost Savings',
        },
        outOfPolicyCarTypes: {
          id: 'app.containers.ChartContainer.analysis.car.compliance.outOfPolicyCarTypes',
          defaultMessage: 'Top 5 Out-of-Policy Car Types',
        },
        inPolicyCarTypes: {
          id: 'app.containers.ChartContainer.analysis.car.compliance.inPolicyCarTypes',
          defaultMessage: 'Top 5 In-policy Car Types',
        },
        outOfPolicySpends: {
          id: 'app.containers.ChartContainer.analysis.car.compliance.outOfPolicySpends',
          defaultMessage: 'Top 5 Highest Out-of-Policy Spend',
        },
        inPolicySpends: {
          id: 'app.containers.ChartContainer.analysis.car.compliance.inPolicySpends',
          defaultMessage: 'Top 5 Highest In-policy Spends',
        },
      },
    },
    card: {
      credit: {  
        cardCreditAmountCurrent: {
          id: 'app.containers.ChartContainer.analysis.card.credit.cardCreditAmountCurrent',
          defaultMessage: staticMessages.top10,
          // defaultMessage: 'Top 5 Highest In-policy Spends',
        },
        cardCreditAmountPre: {
          id: 'app.containers.ChartContainer.analysis.card.credit.cardCreditAmountPre',
          defaultMessage: staticMessages.top10,
          // defaultMessage: 'Top 5 Highest In-policy Spends',
        },
      },
      compliance: {  
        cardComplianceCreditLossAmount: {
          id: 'app.containers.ChartContainer.analysis.card.compliance.cardComplianceCreditLossAmount',
          defaultMessage: '{suffix} Credit Loss',
        },
        cardComplianceDelinquencyAmount: {
          id: 'app.containers.ChartContainer.analysis.card.compliance.cardComplianceDelinquencyAmount',
          defaultMessage: '{suffix} Delinquency',
        },
      },
      payment:{
        cardVsCashAmount: {
          id: 'app.containers.ChartContainer.analysis.card.payment.stackedBarChartDataTrans',
          defaultMessage: 'Corporate Card vs. Other Form of Payment',
        },
        stackedBarChartData: {
          id: 'app.containers.ChartContainer.analysis.card.payment.stackedBarChartData',
          defaultMessage: 'Travel Commodity by Corporate Card and Other Form of Payment',
        },
        cardVsCashTransactions: {
          id: 'app.containers.ChartContainer.analysis.card.payment.cardVsCashTransactions',
          defaultMessage: 'Corporate Card vs. Other Payment Transactions',
        },	  
        stackedBarChartDataTrans: {
          id: 'app.containers.ChartContainer.analysis.card.payment.stackedBarChartDataTrans',
          defaultMessage: 'Travel Commodity Transactions by Corporate Card and Other Form of Payment',
        }
      },
      incentive: {
        chartByIncentiveType : {
          id: 'app.containers.ChartContainer.analysis.card.incentive.chartByIncentiveType',
          defaultMessage: 'Incentive by Incentive Type',
        },
        chartByCardType: {
          id: 'app.containers.ChartContainer.analysis.card.incentive.chartByCardType',
          defaultMessage: 'Incentive by Card Type',
        }
      },
      leakage: {  
        cardLeakageNonPreferredAmount: {
          id: 'app.containers.ChartContainer.analysis.card.leakage.cardLeakageNonPreferredAmount',
          defaultMessage: '{suffix} Non-preferred Charge Volume',
        },
        cardLeakageNonUsageWithCardAmount: {
          id: 'app.containers.ChartContainer.analysis.card.leakage.cardLeakageNonUsageWithCardAmount',
          defaultMessage: '{suffix} Charge Volume of Corporate Card with Card',
        },
      },
    },
    meeting: {
      cventRFP: {  
        top_poCountryBySpend: {
          id: 'app.containers.ChartContainer.analysis.meeting.cvent.top_poCountryBySpend',
          defaultMessage: 'Top 10 Countries (Spend)',
        },
        top_poCountryByValume: {
          id: 'app.containers.ChartContainer.analysis.meeting.cvent.top_poCountryByValume',
          defaultMessage: 'Top 10 Countries (# of Events)',
        },
        top_cityByValume: {
          id: 'app.containers.ChartContainer.analysis.meeting.cvent.top_cityByValume',
          defaultMessage: 'Top 10 Cities (# of Events)',
        },
        top_cityBySpend: {
          id: 'app.containers.ChartContainer.analysis.meeting.cvent.top_cityBySpend',
          defaultMessage: 'Top 10 Cities (Spend)',
        },
        top_hotelChainBySpend: {
          id: 'app.containers.ChartContainer.analysis.meeting.cvent.top_hotelGroupBySpend',
          defaultMessage: 'Top 10 Suppliers (Spend)',
        },
        top_hotelChainByValume: {
          id: 'app.containers.ChartContainer.analysis.meeting.cvent.top_hotelGroupByValume',
          defaultMessage: 'Top 10 Suppliers (# of Events)',
        },
        spendByMeetingSizeChart: {
          id: 'app.containers.ChartContainer.analysis.meeting.cvent.spendByMeetingSize',
          defaultMessage: 'Meeting Size (Spend)',
        },
        spendByCostBreakDownChart: {
          id: 'app.containers.ChartContainer.analysis.meeting.cvent.spendByCostBreakDown',
          defaultMessage: 'Meeting Cost Breakdown',
        },
      },
      cventSupplier: {
        submissionChart: {
          id: 'app.containers.ChartContainer.analysis.meeting.cventSupplier.submissionChart',
          defaultMessage: 'Submission / Awarded',
        }
      }
    }
  },
});
