import { createSelector } from 'reselect';

const getAuth = state => state.auth;

const makeSelectAuth = createSelector(
  getAuth,
  authState => authState
);

export default makeSelectAuth;
