import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import updateObject from 'Utils/updateObject';
import {
  alertOptionsClear,
  alertModalOpen,
  alertModalClose,
  alertComponentOptionsRequest,
  alertOptionsRequest,
} from './actions';

export const initialState = {
  options: {},
  data: {},
  open: false,
};

const alertModalReducer = handleActions({
  [alertModalOpen]: state => updateObject(state, { open: true }),
  [alertModalClose]: state => updateObject(state, { open: false }),
  [alertComponentOptionsRequest]: (state, { payload: { options } }) => update(state, {
    $merge: {
      open: true,
      options: updateObject(state.options, options),
    },
  }),
  [alertOptionsRequest.SUCCESS]: (state, { payload: options }) => update(state, {
    $set: { data: options },
  }),
  [alertOptionsClear]: state => updateObject(state, { options: {} }),
}, initialState);

export default alertModalReducer;
