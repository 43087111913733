import { createAction } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';
import {
  PAGE_DATA_REQUEST,
  PAGES_CLEAR,
  PAGE_CLEAR,
  PAGE_UPDATE,
  PAGE_PARAMS_UPDATE,
  PAGE_PARAMS_CLEAR,
  PAGE_SETTINGS_REQUEST,
  PAGE_SETTINGS_CLEAR,
  PAGE_SETTINGS_UPDATE,
  PAGE_SETTINGS_SAVE,
  PENDING_REGISTER_USERS,
  PENDING_REGISTER_USERS_SUCCESS,
} from './constants';

export const pagePropsUpdate = createAction(
  PAGE_UPDATE,
  (page, payload) => ({ page: page.replace(/(\/)$/g, ''), payload })
);
export const pageDataRequest = createFormAction(
  PAGE_DATA_REQUEST,
  ({ PATH, payload, ...rest }) => ({ PATH, payload: { ...payload, ...rest } }),
);
export const pageParamsUpdate = createAction(
  PAGE_PARAMS_UPDATE,
  (page, payload) => ({ page: page.replace(/(\/)$/g, ''), payload })
);
export const pageParamsClear = createAction(PAGE_PARAMS_CLEAR);
export const pageSettingsRequest = createAction(
  PAGE_SETTINGS_REQUEST,
  page => ({ page: page.replace(/(\/)$/g, '') })
);
export const pageSettingsSave = createAction(PAGE_SETTINGS_SAVE, (page, data) => ({ page, data }));
export const pageSettingsUpdate = createAction(PAGE_SETTINGS_UPDATE, (page, data) => ({ page, data }));
export const pageSettingsClear = createAction(PAGE_SETTINGS_CLEAR);
export const pageClear = createAction(PAGE_CLEAR, page => ({ page: page.replace(/(\/)$/g, '') }));
export const pagesClear = createAction(PAGES_CLEAR);
export const getPendingRegisterUserCount = createAction(PENDING_REGISTER_USERS);
export const getPendingRegisterUserCountSuccess = createAction(PENDING_REGISTER_USERS_SUCCESS);

