import React from 'react';
import { FormattedMessage } from 'react-intl';
import { takeLatest, select, call, put } from 'redux-saga/effects';
import fetch from 'Utils/fetch';
import logger from 'Utils/logger';
import showNotification from 'Components/ToastComponent/actions';
import { makeSelectPageName } from 'Containers/PageContainer/selectors';
import { ISAM_URL_KEY } from 'Utils/constants';
import {
  API_PATH,
  VIEWS_REQUEST,
  VIEWS_SUCCESS,
  VIEWS_FAIL,
} from './constants';
import messages from './messages';

/**
 * Gets saved views for current page
 * @param page {string}
 */
function* getPageViews({ payload: { page } }) {
  try {
    const data = yield call(fetch, API_PATH, { params: { page },
    headers:{
      'page': '/api/views',
      'iv-user': 'lixiaobo@cn.ibm.com',
      'viewPage': page.replace(`${ISAM_URL_KEY}`, ''), 
    } });
    yield put({ type: VIEWS_SUCCESS, payload: { page, data } });
  } catch (error) {
    const name = yield select(makeSelectPageName);
    yield put(showNotification({
      title: <FormattedMessage { ...messages.notifications.data.request.error.title } />,
      body: (
        <FormattedMessage
          { ...messages.notifications.data.request.error.body }
          values={{ page: name }}
        />
      ),
      kind: 'error',
      timeout: 3500,
    }));
    yield put({ type: VIEWS_FAIL, payload: { page, error } });
    logger.error(`Error getting views for page ${name} due to ${error}`);
  }
}

export default function* defaultSagas() {
  yield takeLatest(VIEWS_REQUEST, getPageViews);
}

/* eslint-disable redux-saga/yield-effects */
export const viewSagas = [
  takeLatest(VIEWS_REQUEST, getPageViews),
];
/* eslint-enable redux-saga/yield-effects */
