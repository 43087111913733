import { initialState as appState } from 'Containers/App/reducer';
import { initialUserState as userState } from 'Containers/UserContainer/reducer';
import { initialState as authState } from 'Pages/AuthPage/reducer';
import { alertsState } from 'Pages/AlertsPage/reducer';

const initialState = {
  appState,
  user: userState,
  auth: authState,
  alerts: alertsState,
};

export default initialState;
