import { all } from 'redux-saga/effects';
import { authSagas } from 'Pages/AuthPage/saga';
import { userSagas } from 'Containers/UserContainer/saga';
import { filterSagas } from 'Containers/FiltersContainer/saga';
import { viewSagas } from 'Containers/ViewsContainer/saga';
import { tableSagas } from 'Containers/DataTableContainer/saga';
import { alertSagas } from 'Pages/AlertsPage/saga';
import { scenarioSagas } from 'Containers/ScenariosContainer/saga';
import { chartSaga } from 'Containers/ChartContainer/saga';
import { topVisualSaga } from 'Containers/TopVisualContainer/saga';
import { appSagas } from 'Containers/App/saga';
import { getPageHeaderSaga } from 'Containers/PageHeaderContainer/saga';
import { getPageContainerSaga } from 'Containers/PageContainer/saga';

export default function* rootSagas() {
  yield all([
    ...authSagas,
    ...appSagas,
    ...userSagas,
    ...filterSagas,
    ...viewSagas,
    ...tableSagas,
    ...alertSagas,
    ...scenarioSagas,
    ...chartSaga,
    ...topVisualSaga,
    ...getPageHeaderSaga,
    ...getPageContainerSaga,
  ]);
}
