import { handleActions, combineActions } from 'redux-actions';
import { getUserRequest, logoutRequest } from './actions';

export const initialUserState = {
  name: null,
  shortName: null,
  email: null,
  photo: null,
  allowPersonalInformation: null,
  acceptedConsent: null,
  group: [],
  geos: [],
  countries: [],
  sessionIndex: null,
  logoutStatus: false,
};
export const initialLogoutUserState = {
  name: null,
  shortName: null,
  email: null,
  photo: null,
  allowPersonalInformation: null,
  acceptedConsent: null,
  group: [],
  geos: [],
  countries: [],
  sessionIndex: null,
  logoutStatus: true,
};

const userReducer = handleActions({
  [getUserRequest.SUCCESS]: (state, { payload: { user } }) => user,
  [combineActions(getUserRequest.FAILURE, logoutRequest)]: () => initialLogoutUserState,
}, initialUserState);

export default userReducer;
