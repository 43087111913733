import { isPlainObject, reduce, isObject } from 'lodash-es';
import { saveAs } from 'file-saver/FileSaver';
import { USER_LOGOUT_REQUEST } from 'Containers/UserContainer/constants';
import { errorMessages } from 'Components/Login/messages';
import updateObject from 'Utils/updateObject';
import { ISAM_URL_KEY } from 'Utils/constants';
import KeycloakService  from '../services/KeycloakService';

const REQUEST_STACK = [];

// TODO: handle 4xx and 5xx
/**
 * Check and perform based on response status
 * @param response {HttpResponse}
 * @returns {HttpResponse|ErrorEvent}
 */
const checkStatus = response => {
  console.log('checkStatus-response.redirected', response.redirected)
  console.log('checkStatus-response.status ', response.status )
  if (response.redirected) {
    // 3xx => Redirect. Main assumption is that the session expired. Trigger login handler
    return handleLogin(response);
  }
  if (response.status >= 200 && response.status < 300 && !response.redirected) {
    // 2xx => Ok, exit.
    return response;
  }
  if (response.status === 401) {
    // 2xx => Ok, exit.
    KeycloakService.doLogin();
  }
  throw response;
};

const handleErrors = response => {
  if (response.code === 20) {
    // Request cancellation, don't throw;
    return;
  }
  const contentType = response?.headers?.get('Content-Type');
  if (contentType) {
    if (contentType.match('json')) {
      throw response.json();
    }
    return response.text().then(res => throw res); // eslint-disable-line
  }
  throw response;
};

// NOTE: this is an UNSAFE workaround for the Auth protocol and should be replaced with a
// proper API-based solultion.
/**
 * Check if redirect should perform login / logout operations based on response.url
 * If true, dispatch a USER_LOGOUT_REQUEST event with appropriate message
 * @param response {HttpResponse}
 * @returns {HttpResponse}
 */
const handleLogin = response => {
  if (response.url.match(/\/login(\/|\.html)?$/)) {
    const event = new CustomEvent(USER_LOGOUT_REQUEST, {
      detail: {
        error: new Error(errorMessages.session.id),
      },
    });
    document.dispatchEvent(event);
    Object.assign(response, { message: errorMessages.session.id });
  } else if (response.url.match(/\/error(\/|\.html)?$/)) {
    // User tried logging in, but was kicked out based on invalid credentials
    Object.assign(response, { message: errorMessages.credentials.id });
  } else return response;
  throw response;
};

const parseResponse = async response => {
  const contentType = response.headers.get('Content-Type');
  const contentDisposition = response.headers.get('content-disposition');
  if (contentType && contentType.match('json') && !contentDisposition) {
    return response.json();
  }
  if (contentType && contentDisposition && /^attachment/.test(contentDisposition)) {
    // Download attachments
    const fileName = contentDisposition
      .match(/filename[^;=\n]*=(UTF-8(['"]*))?(.*)/)[3]
      .replace(/"/g, '');
    const data = await response.arrayBuffer();
    saveAs(new Blob([data], { type: contentType }), fileName);
    return data;
  }
  return response.text();
};

const loginInterceptor = data => {
  if (typeof data === 'string' && data.indexOf('pkmslogin.form') > -1) {
    window.location.href = ISAM_URL_KEY;
  }
  console.log('loginInterceptor')
  return data;
};

const handleRequest = (path = null, options) => {
  let URI = (typeof path !== 'string' && path !== null) ? (path?.url || path.path) : path;
  if (isPlainObject(path) && !options) {
    options = path; // eslint-disable-line
  }
  if (!URI || path === '' || (typeof URI !== 'string' && !(URI instanceof URL))) {
    throw new Error(`Invalid request to unknown path: ${path}`);
  }
  if (process.env.NODE_ENV === 'production') {
    if (!(typeof path === 'string' && path.indexOf('pkmslogout') > -1)) {
      // if (path !== '/j_security_check') {
      URI = ISAM_URL_KEY + URI;
      // }
    }
  }
  let url;
  if (URI instanceof URL) {
    url = URI;
  } else {
    url = new URL(`${window.location?.origin || document.location?.origin}${URI}`);
  }

  Reflect.ownKeys({ ...options?.params }).forEach(key => {
    let param = options.params[key];
    if (isObject(param)) {
      param = JSON.stringify(param);
    }
    if (key === 'page') {
      param = param.replace(`${ISAM_URL_KEY}`, '');
    }
    url.searchParams.append(key, param);
  });

  if (isObject(options?.body)) {
    if (options?.type === 'upload') {
      Object.assign(options, { body: options?.body });
    } else {
      const keys = Reflect.ownKeys(options.body);
      // Intentional mutation of options.
      Object.assign(options, { body: reduce(options.body, (acc, item, key) => {
        acc += `${key}=${window.encodeURIComponent(JSON.stringify(item))}`; // eslint-disable-line
        if (keys.indexOf(key) !== keys.length - 1) {
          acc += '&'; // eslint-disable-line
        }
        return acc;
      }, '') });
    }
  }

  const request = {
    id: URI,
    controller: new AbortController(),
  };

  if (!options?.method || options?.method === 'GET') {
    for (const req of REQUEST_STACK) { // eslint-disable-line
      if (request.id === req.id) {
        req.controller.abort();
        console.log(
          `%c__REQUEST__ Cancelled for URI: %c${req.id}`,
          'color: blue',
          'font-weight: bold',
        );
      }
    }
    REQUEST_STACK.push(request);
  }

  let noCache = '';
  if (options?.params?.page?.indexOf('/analysis/air/citypair') > -1) {
    noCache = 'no-cache';
  }

  let token = 'Bearer ' + KeycloakService?.getToken();
  //let token = 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJQMlljenhJUEh2bXpmbEFKMjlza21mc1EyaUdvRHh5d3NyYXlraE13bFc0In0.eyJleHAiOjE2NDY5OTIwNjUsImlhdCI6MTY0Njk5MTc2NSwiYXV0aF90aW1lIjoxNjQ2OTgzNzY0LCJqdGkiOiJhOTU4Y2UzNC0yMTRlLTQxMGItODI4Yi03ZjFkYTMzN2VmNjYiLCJpc3MiOiJodHRwczovL2Rldi1zZXJ2ZXIxLmZ5cmUuaWJtLmNvbTo4ODkwL2F1dGgvcmVhbG1zL2l0bS1kZXYiLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiZDE4ZjVhZDctNDBlMi00MWJiLWI3MjktMGEzODZmMDU0ZDcwIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiYm9va0JveCIsIm5vbmNlIjoiMTUxZWRjZGUtYWI0Zi00ZDJkLTllZmMtNTUyMjVkZGJkYmM3Iiwic2Vzc2lvbl9zdGF0ZSI6ImRhNWVjYmJlLWY4YjgtNDcxNC04ZWQwLWRlNmE0YWI3OGFjNCIsImFjciI6IjAiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiIsImRlZmF1bHQtcm9sZXMtaXRtLWRldiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwiLCJzaWQiOiJkYTVlY2JiZS1mOGI4LTQ3MTQtOGVkMC1kZTZhNGFiNzhhYzQiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsIm5hbWUiOiJ0ZXN0IHRlc3QiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJ0ZXN0IiwiZ2l2ZW5fbmFtZSI6InRlc3QiLCJmYW1pbHlfbmFtZSI6InRlc3QiLCJlbWFpbCI6InRlc3RAY24uaWJtLmNvbSJ9.IR2lqV4wlMU_PZX89VEvhxAIBaL_lmMlrYbX5Bz_lofKM9fIsgf41I8KDvajRy12RTod8z4d6po2xCtq22JlJNrjpaNnl9jbC4hPAelerAIjm63vOgKBMzEZbsMH39EbtaP753tvgTVImQcfIdGAUoOZ6Md_gVqVlghPVLgIr6jF7EdX_nTP_0m0FkFxzCok-F56IsM-iejZNpyNTAfFbhXGY3JgW0YEB9fUSrW3JbnFsvF0kG0gyK8oN6jqL1zSV4rnCpmfMXUZXSjPHx7nkWdiMAwtetGJZaq3FaoYt9k-MHY3NSVWYgI6RreGngBvGkD65nz3V0ECwCE0FfP29g'

  if(options && options.headers){
    options.headers.Authorization = token;
    options.headers['session-index'] = token.substring(token.length-50);
    //options.headers['iv-user'] = 'qiufangc@cn.ibm.com';
  }
  //options.headers['iv-user'] = 'qiufangc@cn.ibm.com';
  return fetch(
    url,
    updateObject({
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'Authorization':token,
        'Cache-control': noCache,
      },
      credentials: 'same-origin',
      referrer: 'no-referrer',
      signal: request.controller.signal,
    }, options))
    .then(checkStatus)
    .then(parseResponse)
    .then(loginInterceptor)
    .catch(handleErrors)
    .finally(() => {
      const requestIdx = REQUEST_STACK.findIndex(v => v.id === request.id);
      if (requestIdx !== -1) {
        REQUEST_STACK.splice(requestIdx, 1);
      }
    });
};

export default handleRequest;
