import { handleActions } from 'redux-actions';
import { languageSwitcherModalClose, languageSwitcherModalOpen } from './actions';

const initialState = {
  open: false,
};

const languageSwitcherModalReducer = handleActions({
  [languageSwitcherModalOpen]: () => ({ open: true }),
  [languageSwitcherModalClose]: () => ({ open: false }),
}, initialState);

export default languageSwitcherModalReducer;
