import React from 'react';
import { FormattedMessage } from 'react-intl';
import { takeLatest, all, throttle, call, put, select } from 'redux-saga/effects';
import fetch from 'Utils/fetch';
import logger from 'Utils/logger';
import { alertOptionsRequest } from 'Components/CreateAlertModal/actions';
import showNotification from 'Components/ToastComponent/actions';
import { makeSelectPageName } from 'Containers/PageContainer/selectors';
import { API_GET_LOGOUT } from 'Containers/UserContainer/constants';
import { ISAM_URL_KEY } from 'Utils/constants';
import {
  API_PATH,
  API_PATH_ALERT_OPTIONS,
  API_AUTOMATED_ALERTS_PATH,
  API_ACTIVITIES_PATH,
} from './constants';
import messages from './messages';
import { activitiesRequest, alertsRequest, automatedAlertsRequest } from './actions';

function* getAlerts({ payload: { page = 'ALL' } }) {
  try {
    const data = yield call(fetch, API_PATH, { params: { types: page },headers:{
      'page':'/api/alerts'
    } });
    if (data.operation === 'login' || (typeof data === 'string' && data.indexOf('pkmslogin.form') > -1)) {
      // yield call(fetch, API_GET_LOGOUT);
      yield put(showNotification({
        title: (
          <FormattedMessage { ...messages.notifications.timeout.error.title } />
        ),
        body: (
          <FormattedMessage { ...messages.notifications.timeout.error.body } />
        ),
        kind: 'error',
        // timeout: 2500,
      }));
    } else {
      yield put(alertsRequest.success(data));
    }
  } catch (error) {
    yield put(showNotification({
      title: (
        <FormattedMessage { ...messages.notifications.dataRequest.error.title } />
      ),
      body: (
        <FormattedMessage { ...messages.notifications.dataRequest.error.body } />
      ),
      kind: 'error',
      timeout: 2500,
    }));
    yield put(alertsRequest.failure({ page, error }));
    logger.error(error);
  }
}

function* getAutomatedAlerts({ payload: { page = 'ALL' } }) {
  try {
    const data = yield call(fetch, API_AUTOMATED_ALERTS_PATH, { params: { types: page },headers:{
      'page':'/api/alerts/automated'
    } });
    yield put(automatedAlertsRequest.success(data));
  } catch (error) {
    yield put(showNotification({
      title: (
        <FormattedMessage { ...messages.notifications.dataRequest.error.title } />
      ),
      body: (
        <FormattedMessage { ...messages.notifications.dataRequest.error.body } />
      ),
      kind: 'error',
      timeout: 2500,
    }));
    yield put(automatedAlertsRequest.failure({ page, error }));
    logger.error(error);
  }
}

function* getActivities({ payload: { limit = 5 } }) {
  try {
    const data = yield call(fetch, API_ACTIVITIES_PATH, { params: { limit },
    headers:{
      'page': '/api/alerts/activities'
    } });
    if (data.operation === 'login' || (typeof data === 'string' && data.indexOf('pkmslogin.form') > -1)) {
      // yield call(fetch, API_GET_LOGOUT);
      yield put(showNotification({
        title: (
          <FormattedMessage { ...messages.notifications.timeout.error.title } />
        ),
        body: (
          <FormattedMessage { ...messages.notifications.timeout.error.body } />
        ),
        kind: 'error',
        // timeout: 2500,
      }));
    } else {
      yield put(activitiesRequest.success(data));
    }
  } catch (error) {
    const page = yield select(makeSelectPageName);
    yield put(showNotification({
      title: (
        <FormattedMessage { ...messages.notifications.dataRequest.error.title } />
      ),
      body: (
        <FormattedMessage { ...messages.notifications.dataRequest.error.body } />
      ),
      kind: 'error',
      timeout: 2500,
    }));
    yield put(activitiesRequest.failure({ page, error }));
    logger.error(error);
  }
}

function* getAlertOptions({ payload: { page } }) {
  try {
    const options = yield call(fetch, API_PATH_ALERT_OPTIONS, { params: { page },
      headers:{
        'page': '/api/alerts/getAlertOptions',
        'viewPage': page.replace(`${ISAM_URL_KEY}`, ''), 
      } });
    yield put(alertOptionsRequest.success(options));
  } catch (error) {
    // yield put(showNotification({
    //   title: (
    //     <FormattedMessage { ...appGenericMessages.generic.error } />
    //   ),
    //   body: (
    //     <FormattedMessage { ...messages.notifications.alertOptionsRequest.error.body } />
    //   ),
    //   kind: 'error',
    //   timeout: 3000,
    // }));
    yield put(alertOptionsRequest.failure(error));
    logger.error(error);
  }
}

export default function* defaultSagas() {
  yield all([
    takeLatest(alertsRequest.REQUEST, getAlerts),
    takeLatest(automatedAlertsRequest.REQUEST, getAutomatedAlerts),
    takeLatest(activitiesRequest.REQUEST, getActivities),
    throttle(1000, alertOptionsRequest.REQUEST, getAlertOptions),
  ]);
}

export const alertSagas = [
  takeLatest(alertsRequest.REQUEST, getAlerts),
  takeLatest(automatedAlertsRequest.REQUEST, getAutomatedAlerts),
  takeLatest(activitiesRequest.REQUEST, getActivities),
  throttle(1000, alertOptionsRequest.REQUEST, getAlertOptions),
];
