export const API_PATH = '/api/feedback';
export const MODAL_OPEN = 'app/FeedbackContainer/MODAL_OPEN';
export const MODAL_CLOSE = 'app/FeedbackContainer/MODAL_CLOSE';
export const NPS_MODAL_OPEN = 'app/FeedbackContainer/NPS_MODAL_OPEN';
export const NPS_MODAL_CLOSE = 'app/FeedbackContainer/NPS_MODAL_CLOSE';
export const OPTIONS_ENUM = [
  'DATA_ISSUE',
  'NEW_FUNC',
  'UI_ISSUE',
  'OTHER',
];
