import { defineMessages } from 'react-intl';

export default defineMessages({
  pageOptions: {
    id: 'app.containers.PageHeaderContainer.pageOptions',
    defaultMessage: 'Page Options',
  },
  componentInformation: {
    id: 'app.containers.PageHeaderContainer.pageDataInfo',
    defaultMessage: 'View Data Information',
  },
  agencyFee: {
    id: 'app.containers.PageHeaderContainer.agencyFee',
    defaultMessage: 'Agency Fee',
  },
  pageLogic: {
    id: 'app.containers.PageHeaderContainer.pageLogic',
    defaultMessage: 'View Page Logic',
  },
  monthlyReportNotification: {
    id: 'app.containers.PageHeaderContainer.pageDataInfo',
    defaultMessage: 'Monthly Notification',
  },
  validationPassedMSG: {
    id: 'app.containers.PageHeaderContainer.validationWarningMSG',
    defaultMessage: 'Data validation passed.',
  },
  validationInProgressMSG: {
    id: 'app.containers.PageHeaderContainer.validationWarningMSG',
    defaultMessage: 'Data validation in progress.',
  },
});
