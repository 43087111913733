import { takeLatest, call, put } from 'redux-saga/effects';
import fetch from 'Utils/fetch';
import {
  API_PATH_DATA,
  APP_DATA_INFO_FAIL,
  APP_DATA_INFO_REQUEST,
  APP_DATA_INFO_SUCCESS,
} from './constants';

function* getDataInformation() {
  try {
    const data = yield call(fetch, API_PATH_DATA,
      {
        headers:{
          'page': '/api/data/getInformation'
        }
    });
    if (data) {
      yield put({ type: APP_DATA_INFO_SUCCESS, payload: { data } });
    }
  } catch (e) {
    yield put({ type: APP_DATA_INFO_FAIL, payload: { data: [] } });
  }
}

export default function* defaultSagas() {
  yield takeLatest(APP_DATA_INFO_REQUEST, getDataInformation);
}

export const appSagas = [
  takeLatest(APP_DATA_INFO_REQUEST, getDataInformation),
];
