import { defineMessages } from 'react-intl';

export const filterStateMessages = defineMessages({
  default: {
    id: 'app.components.Filters.filter.state.default',
    defaultMessage: 'All Options',
  },
  empty: {
    id: 'app.components.Filters.filter.state.empty',
    defaultMessage: 'Select',
  },
  noState: {
    id: 'app.components.Filters.filter.state.noState',
    defaultMessage: 'No items',
  },
  employeeLocation: {
    id: 'app.components.Filters.filter.state.employeeLocation.selected',
    defaultMessage: 'All Locations'
  },
  pointsOfSale: {
    id: 'app.components.Filters.filter.state.pointsOfSale.selected',
    defaultMessage: 'All Options'
  },
  destinationLocation: {
    id: 'app.components.Filters.filter.state.destinationLocation.selected',
    defaultMessage: 'All Locations'
  },
  fromLocation: {
    id: 'app.components.Filters.filter.state.fromLocation.selected',
    defaultMessage: 'All Locations'
  },
  toLocation: {
    id: 'app.components.Filters.filter.state.toLocation.selected',
    defaultMessage: 'All Locations'
  },
  lob: {
    id: 'app.components.Filters.filter.state.lob.selected',
    defaultMessage: 'All Options'
  },
  vendorSubCategory: {
    id: 'app.components.Filters.filter.state.vendorSubCategory.selected',
    defaultMessage: 'All Options'
  },
  cardVendor: {
    id: 'app.components.Filters.filter.state.cardVendor.selected',
    defaultMessage: 'All Options'
  },
  airline: {
    id: 'app.components.Filters.filter.state.airline.selected',
    defaultMessage: 'All Airlines'
  },
  vendor: {
    id: 'app.components.Filters.filter.state.vendor.selected',
    defaultMessage: 'All Vendors'
  },
  compliant: {
    id: 'app.components.Filters.filter.state.compliant.selected',
    defaultMessage: 'All Compliances'
  },
  carTravelPurpose: {
    id: 'app.components.Filters.filter.state.carTravelPurpose.selected',
    defaultMessage: 'All Purposes',
  },
  routeTypeMulti: {
    id: 'app.components.Filters.filter.state.routeTypeMulti.selected',
    defaultMessage: 'All Routes'
  },
  flightType: {
    id: 'app.components.Filters.filter.state.flightType.selected',
    defaultMessage: 'All Flights'
  },
  bookingType: {
    id: 'app.components.Filters.filter.state.bookingType.selected',
    defaultMessage: 'All Bookings'
  },
  cabinClass: {
    id: 'app.components.Filters.filter.state.cabinClass.selected',
    defaultMessage: 'All Cabins'
  },
  advanceBooking: {
    id: 'app.components.Filters.filter.state.advanceBooking.selected',
    defaultMessage: 'All Advance Purchase'
  },
  benchmarkAdvancePurchase: {
    id: 'app.components.Filters.filter.state.benchmarkAdvancePurchase.selected',
    defaultMessage: 'All Options'
  },
  benchmarkCompanySize: {
    id: 'app.components.Filters.filter.state.benchmarkCompanySize.selected',
    defaultMessage: 'All Options'
  },

  hotelGroups: {
    id: 'app.components.Filters.filter.state.hotelGroups.selected',
    defaultMessage: 'All Hotel Groups'
  },
  hotelMatchGroups: {
    id: 'app.components.Filters.filter.state.hotelMatchGroups.selected',
    defaultMessage: 'All Hotel Groups',
  },
  carType: {
    id: 'app.components.Filters.filter.state.carType.selected',
    defaultMessage: 'All Cars',
  },
  analysisCarType: {
    id: 'app.components.Filters.filter.state.analysisCarType.selected',
    defaultMessage: 'All Cars',
  },
  rentBuckets: {
    id: 'app.components.Filters.filter.state.rentBuckets.selected',
    defaultMessage: 'All Rentals',
  },
  approved: {
    id: 'app.components.Filters.filter.state.approved.selected',
    defaultMessage: 'All Options',
  },
  paymentForm: {
    id: 'app.components.Filters.filter.state.paymentForm.selected',
    defaultMessage: 'All Payments',
  },
  matchingRule: {
    id: 'app.components.Filters.filter.state.matchingRule.selected',
    defaultMessage: 'All Matches',
  },
  matchingCriteria: {
    id: 'app.components.Filters.filter.state.matchingCriteria.selected',
    defaultMessage: 'All Matching Criteria',
  },
  commodity: {
    id: 'app.components.Filters.filter.state.commodity.selected',
    defaultMessage: 'All Options',
  },
  gtDataSource: {
    id: 'app.components.Filters.filter.state.gtDataSource.selected',
    defaultMessage: 'All Types',
  },
  dataSource: {
    id: 'app.components.Filters.filter.state.dataSource.selected',
    defaultMessage: 'All Types',
  },
  market: {
    id: 'app.components.Filters.filter.state.market.selected',
    defaultMessage: 'All Markets'
  },
  airlineType: {
    id: 'app.components.Filters.filter.state.airlineType.selected',
    defaultMessage: 'All Airlines'
  },
});

export const notificationMessages = defineMessages({
  dataRequest: {
    errors: {
      title: {
        id: 'app.components.Notifications.messages.Filters.errors.dataRequest.title',
        defaultMessage: 'Filters Fail',
      },
      body: {
        all: {
          id: 'app.components.Notifications.messages.Filters.errors.dataRequest.body.all',
          defaultMessage: `Failed to fetch Filter Data for page { page }. Please contact an
          administrator about this issue`,
        },
        single: {
          id: 'app.components.Notifications.messages.Filters.errors.dataRequest.body.single',
          defaultMessage: `There was an error requesting filters [{ key }] for { page }. Please
        try again or contact an administrator if the issue persists`,
        },
      },
    },
  },
});

const filterMessages = defineMessages({
  placeholder: {
    id: 'app.components.Filters.filter.placeholder',
    defaultMessage: '{ filter }',
  },
  airportPair: {
    id: 'app.components.Filters.filter.airportPair',
    defaultMessage: 'Airport Pair',
  },
  airport: {
    id: 'app.components.Filters.filter.airport',
    defaultMessage: 'Airport',
  },
  status: {
    id: 'app.components.Filters.filter.status',
    defaultMessage: 'Status',
  },
  approved: {
    id: 'app.components.Filters.filter.approved',
    defaultMessage: 'Matching Status',
    options: {
      0: {
        id: 'app.components.Filters.filter.approved.options.default',
        defaultMessage: 'Not approved',
      },
      1: {
        id: 'app.components.Filters.filter.approved.options.default',
        defaultMessage: 'Approved',
      },
      5: {
        id: 'app.components.Filters.filter.approved.options.default',
        defaultMessage: 'Incomplete',
      },
    },
  },
  amount: {
    id: 'app.components.Filters.filter.amount',
    defaultMessage: 'Amount',
    options: {
      base: {
        id: 'app.components.Filters.filter.amount.options.base',
        defaultMessage: 'Base Amount',
      },
      full: {
        id: 'app.components.Filters.filter.amount.options.full',
        defaultMessage: 'Full Amount',
      },
    },
  },
  carAmount: {
    id: 'app.components.Filters.filter.carAmount',
    defaultMessage: 'Amount',
    options: {
      tm: {
        id: 'app.components.Filters.filter.amount.options.base',
        defaultMessage: 'Time & Mileage',
      },
      full: {
        id: 'app.components.Filters.filter.amount.options.full',
        defaultMessage: 'Full Amount',
      },
    },
  },
  dateRange: {
    id: 'app.components.Filters.filter.dateRange',
    defaultMessage: 'Date',
  },
  dateComparison: {
    id: 'app.components.Filters.filter.dateComparison',
    defaultMessage: 'Date',
  },
  mutipleDateComparison: {
    id: 'app.components.Filters.filter.mutipleDateComparison',
    defaultMessage: 'Date',
  },
  compareType: {
    id: 'app.components.Filters.filter.compareType',
    defaultMessage: 'Compare Type',
  },
  dateRangeAirCabin: {
    id: 'app.components.Filters.filter.dateRangeAirCabin',
    defaultMessage: 'Date',
  },
  dateRangeConcurforAMEXCreditCardAnalysis: {
    id: 'app.components.Filters.filter.dateRangeConcurforAMEXCreditCardAnalysis',
    defaultMessage: 'Date',
  },
  dateRangeAirTopTravelers: {
    id: 'app.components.Filters.filter.dateRangeAirTopTravelers',
    defaultMessage: 'Date',
  },
  dateRangeCarRentalTailSpend: {
    id: 'app.components.Filters.filter.dateRangeCarRentalTailSpend',
    defaultMessage: 'Date',
  },
  dateRangeCarRentalGroupSpend: {
    id: 'app.components.Filters.filter.dateRangeCarRentalGroupSpend',
    defaultMessage: 'Date',
  },
  dateRangeGroundTransportation: {
    id: 'app.components.Filters.filter.dateRangeGroundTransportation',
    defaultMessage: 'Date',
  },
  dateRangeHotelContractedSpend: {
    id: 'app.components.Filters.filter.dateRangeHotelContractedSpend',
    defaultMessage: 'Date',
  },
  dateRangeTrainSpend: {
    id: 'app.components.Filters.filter.dateRangeTrainSpend',
    defaultMessage: 'Date',
  },
  employeeLocation: {
    id: 'app.components.Filters.filter.employeeLocation',
    defaultMessage: 'Employee Location',
  },
  pointsOfSale: {
    id: 'app.components.Filters.filter.pointsOfSale',
    defaultMessage: 'Point of Sale'
  },
  destinationLocation: {
    id: 'app.components.Filters.filter.destinationLocation',
    defaultMessage: 'Destination Location'
  },
  destinationLocationCarRentalGroupSpend: {
    id: 'app.components.Filters.filter.destinationLocationCarRentalGroupSpend',
    defaultMessage: 'Destination Location'
  },
  gtTypeGroundTransportation: {
    id: 'app.components.Filters.filter.gtTypeGroundTransportation',
    defaultMessage: 'Ground Transportation Type'
  },
  fromLocation: {
    id: 'app.components.Filters.filter.fromLocation',
    defaultMessage: 'From Location',
  },
  toLocation: {
    id: 'app.components.Filters.filter.toLocation',
    defaultMessage: 'To Location',
  },
  lob: {
    id: 'app.components.Filters.filter.lob',
    defaultMessage: 'Line of Business',
  },
  vendorSubCategory: {
    id: 'app.components.Filters.filter.vendorSubCategory',
    defaultMessage: 'Vendor Subcategory',
  },

  benchmarkAdvancePurchase: {
    id: 'app.components.Filters.filter.benchmarkAdvancePurchase',
    defaultMessage: 'Benchmark Advance Purchase'
  },
  benchmarkCompanySize: {
    id: 'app.components.Filters.filter.benchmarkCompanySize',
    defaultMessage: 'Benchmark CompanySize'
  },
  cardType: {
    id: 'app.components.Filters.filter.cardType',
    defaultMessage: 'Card Type',
  },
  creditType: {
    id: 'app.components.Filters.filter.creditType',
    defaultMessage: 'Card Status',
  },
  cardBalance: {
    id: 'app.components.Filters.filter.cardBalance',
    defaultMessage: 'Card Balance',
  },
  days: {
    id: 'app.components.Filters.filter.days',
    defaultMessage: 'Days',
  },
  type: {
    id: 'app.components.Filters.filter.type',
    defaultMessage: 'Type',
  },
  cardVendor: {
    id: 'app.components.Filters.filter.cardVendor',
    defaultMessage: 'Card Vendor',
  },
  airline: {
    id: 'app.components.Filters.filter.airline',
    defaultMessage: 'Airline',
  },
  airlineAirTopTravelers: {
    id: 'app.components.Filters.filter.airlineAirTopTravelers',
    defaultMessage: 'Airline',
  },
  vendor: {
    id: 'app.components.Filters.filter.vendor',
    defaultMessage: 'Vendor',
  },
  compliant: {
    id: 'app.components.Filters.filter.compliant',
    defaultMessage: 'Compliant',
  },
  carTravelPurpose: {
    id: 'app.components.Filters.filter.state.carTravelPurpose',
    defaultMessage: 'Travel Purpose',
  },
  carTravelPurposeCarRentalGroupSpend: {
    id: 'app.components.Filters.filter.state.carTravelPurposeCarRentalGroupSpend',
    defaultMessage: 'Travel Purpose',
  },
  routeTypeMulti: {
    id: 'app.components.Filters.filter.routeTypeMulti',
    defaultMessage: 'Route Type',
  },
  flightType: {
    id: 'app.components.Filters.filter.flightType',
    defaultMessage: 'Flight Type',
  },
  bookingType: {
    id: 'app.components.Filters.filter.bookingType',
    defaultMessage: 'Booking Method',
  },
  cabinClass: {
    id: 'app.components.Filters.filter.cabinClass',
    defaultMessage: 'Cabin Type',
  },
  advanceBooking: {
    id: 'app.components.Filters.filter.advanceBooking',
    defaultMessage: 'Advance Purchase',
  },
  city: {
    id: 'app.components.Filters.filter.city',
    defaultMessage: 'City',
  },
  carType: {
    id: 'app.components.Filters.filter.carType',
    defaultMessage: 'Car Type',
  },
  analysisCarType: {
    id: 'app.components.Filters.filter.analysisCarType',
    defaultMessage: 'Car Type',
  },
  rentBuckets: {
    id: 'app.components.Filters.filter.rentBuckets',
    defaultMessage: 'Rental Days',
  },
  routeType: {
    id: 'app.components.Filters.filter.routeType',
    defaultMessage: 'Route Type',
  },
  focusRoute: {
    id: 'app.components.Filters.filter.focusRoute',
    defaultMessage: 'Focus Route',
    options: {
      '-1': {
        id: 'app.components.Filters.filter.focusRoute.options.default',
        defaultMessage: 'No focus route',
      },
    },
  },
  hotelAmount: {
    id: 'app.components.Filters.filter.hotelAmount',
    defaultMessage: 'Hotel Amount',
  },
  hotelGroups: {
    id: 'app.components.Filters.filter.hotelGroups',
    defaultMessage: 'Hotel Group',
  },
  hotelGroup: {
    id: 'app.components.Filters.filter.hotelGroup',
    defaultMessage: 'Hotel Group',
  },
  hotelMatchGroups: {
    id: 'app.components.Filters.filter.hotelMatchGroups',
    defaultMessage: 'Hotel Group',
  },
  hotelChain: {
    id: 'app.components.Filters.filter.hotelChain',
    defaultMessage: 'Hotel Chain',
  },
  hotelContract: {
    id: 'app.components.Filters.filter.hotelChain',
    defaultMessage: 'Hotel Contract',
  },
  matchPercentage: {
    id: 'app.components.Filters.filter.matchPercentage',
    defaultMessage: 'Match Percentage',
  },
  paymentForm: {
    id: 'app.components.Filters.filter.paymentForm',
    defaultMessage: 'Payment form',
  },
  validatedBy: {
    id: 'app.components.Filters.filter.validatedBy',
    defaultMessage: 'Validated User',
  },
  validatedDate: {
    id: 'app.components.Filters.filter.validatedDate',
    defaultMessage: 'Validated Date',
  },
  masterSuggested: {
    id: 'app.components.Filters.filter.masterSuggested',
    defaultMessage: 'Suggested Master',
  },
  lastMinute: {
    id: 'app.components.Filters.filter.lastMinute',
    defaultMessage: 'Last Minute Purchase',
  },
  directional: {
    id: 'app.components.Filters.filter.directional',
    defaultMessage: 'Directional',
    options: {
      true: {
        id: 'app.components.Filters.filter.directional.options.dir',
        defaultMessage: 'Directional',
      },
      false: {
        id: 'app.components.Filters.filter.directional.options.nonDir',
        defaultMessage: 'Non-Directional',
      },
    },
  },
  breakdown: {
    id: 'app.components.Filters.filter.breakdown',
    defaultMessage: 'Breakdown',
    options: {
      originDest: {
        id: 'app.components.Filters.filter.breakdown.options.originDest',
        defaultMessage: 'Origin Destination',
      },
      tickets: {
        id: 'app.components.Filters.filter.breakdown.options.amount',
        defaultMessage: 'Tickets',
      },
      flights: {
        id: 'app.components.Filters.filter.breakdown.options.flights',
        defaultMessage: 'Flights',
      },
      lob: {
        id: 'app.components.Filters.filter.breakdown.options.lob',
        defaultMessage: 'Line of Business',
      },
      cardVendor: {
        id: 'app.components.Filters.filter.breakdown.options.cardVendor',
        defaultMessage: 'Card Vendor',
      },
      geo: {
        id: 'app.components.Filters.filter.breakdown.options.geo',
        defaultMessage: 'Geo',
      },
    },
  },
  employee: {
    id: 'app.components.Filters.filter.employee',
    defaultMessage: 'Employee',
  },
  commodity: {
    id: 'app.components.Filters.filter.commodity',
    defaultMessage: 'Commodity',
  },
  subCommodity: {
    id: 'app.components.Filters.filter.subCommodity',
    defaultMessage: 'Sub-category',
  },
  cardIncentive: {
    id: 'app.components.Filters.filter.cardIncentive',
    defaultMessage: 'Card Incentive',
  },
  employeeStatus: {
    id: 'app.components.Filters.filter.employeeStatus',
    defaultMessage: 'Employee Status',
  },
  gtDataSource: {
    id: 'app.components.Filters.filter.gtDataSource',
    defaultMessage: 'Data Source Type',
  }, 
  dataSource: {
    id: 'app.components.Filters.filter.dataSource',
    defaultMessage: 'Data Source',
  },  
  simpleDateRange: {
    id: 'app.components.Filters.filter.simpleDateRange',
    defaultMessage: 'Date',
  }, 
  cventRequestStatus: {
    id: 'app.components.Filters.filter.cventRequestStatus',
    defaultMessage: 'Request Status',
  },
  cventContractStatus: {
    id: 'app.components.Filters.filter.cventContractStatus',
    defaultMessage: 'Contract Status',
  },
  cventPoCountry: {
    id: 'app.components.Filters.filter.cventPoCountry',
    defaultMessage: 'PO Country',
  },
  cventAwardedCity: {
    id: 'app.components.Filters.filter.cventAwardedCity',
    defaultMessage: 'City',
  },
  cventHotelChain: {
    id: 'app.components.Filters.filter.cventHotelChain',
    defaultMessage: 'Supplier Chain',
  },
  cventSupplierCities: {
    id: 'app.components.Filters.filter.cventSupplierCities',
    defaultMessage: 'Location',
  },
  cventSupplierEventStartPeriod: {
    id: 'app.components.Filters.filter.cventSupplierEventStartPeriod',
    defaultMessage: 'Event Start Date',
  },
  cventSupplierRFPSentPeriod: {
    id: 'app.components.Filters.filter.cventSupplierRFPSentPeriod',
    defaultMessage: 'RFP Sent Date',
  },
  cventSupplierHotelChains: {
    id: 'app.components.Filters.filter.cventSupplierHotelChains',
    defaultMessage: 'Hotel Chain',
  },
  cventSupplierRFPStatus: {
    id: 'app.components.Filters.filter.cventSupplierRFPStatus',
    defaultMessage: 'RFP Status',
  },
  currencyList: {
    id: 'app.components.Filters.filter.currencyList',
    defaultMessage: 'Currency',
  },
  singleSelectAirline: {
    id: 'app.components.Filters.filter.singleSelectAirline',
    defaultMessage: 'Airline',
  },
  marskOtherAirline: {
    id: 'app.components.Filters.filter.marskOtherAirline',
    defaultMessage: 'Mask other airline',
    options: {
      true: {
        id: 'app.components.Filters.filter.marskOtherAirline.options.yes',
        defaultMessage: 'Yes',
      },
      false: {
        id: 'app.components.Filters.filter.marskOtherAirline.options.no',
        defaultMessage: 'No',
      },
    },
  },
  market: {
    id: 'app.components.Filters.filter.market',
    defaultMessage: 'Market',
  },
  airlineType: {
    id: 'app.components.Filters.filter.airlineType',
    defaultMessage: 'Airline Type',
  },
  employeeLocationExpenseRawData: {
    id: 'app.components.Filters.filter.employeeLocationExpenseRawData',
    defaultMessage: 'Employee Location',
  },
  destinationLocationExpenseRawData: {
    id: 'app.components.Filters.filter.destinationLocationExpenseRawData',
    defaultMessage: 'Destination Location',
  },
  expenseDataCategory: {
    id: 'app.components.Filters.filter.expenseDataCategory',
    defaultMessage: 'Category',
  },
  expenseDataRange: {
    id: 'app.components.Filters.filter.expenseDataRange',
    defaultMessage: 'Date',
  }
});

export default filterMessages;
