import { handleActions } from 'redux-actions';
import { viewPageInfoModalOpen, viewPageInfoModalClose } from './actions';

export const initialState = {
  open: false,
};

const viewPageInfoModalReducer = handleActions({
  [viewPageInfoModalOpen]: () => ({ open: true }),
  [viewPageInfoModalClose]: () => ({ open: false }),
}, initialState);

export default viewPageInfoModalReducer;
