import { handleActions } from 'redux-actions';
import { createReportModalOpen, createReportModalClose } from './actions';

export const initialState = {
  open: false,
};

const createReportModalReducer = handleActions({
  [createReportModalOpen]: () => ({ open: true }),
  [createReportModalClose]: () => ({ open: false }),
}, initialState);

export default createReportModalReducer;
