import { hot } from 'react-hot-loader/root';
import KeycloakService from "../../services/KeycloakService";
import React, { Fragment, PureComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import { shape, bool, string, object, func } from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Loading } from '@ibmtm/carbon-components-react';
import PageContainer from 'Containers/PageContainer/Loadable';
import AuthPage from 'Pages/AuthPage/Loadable';
import TermsPage from 'Pages/TermsPage/Loadable';
import makeSelectAuth from 'Pages/AuthPage/selectors';
import { makeSelectUserConsent, makeSelectUser } from 'Containers/UserContainer/selectors';
import { getUserRequest, logoutRequest, getLogin } from 'Containers/UserContainer/actions';
import UserPropType from 'Containers/UserContainer/types';
import { errorMessages } from 'Components/Login/messages';
import { USER_LOGOUT_REQUEST } from 'Containers/UserContainer/constants';
import { makeSelectCurrentLocation, makeSelectLocation } from './selectors';


const mapStateToProps = state => ({
  auth: makeSelectAuth(state),
  user: makeSelectUser(state),
  userAcceptedConsent: makeSelectUserConsent(state),
  location: makeSelectLocation(state),
  page: makeSelectCurrentLocation(state),
});

const mapDispatchToProps = dispatch => ({
  logoutUser: err => dispatch(logoutRequest(err)),
  getUserInfo: () => getUserRequest(null, dispatch),
  goTo: page => dispatch(push(page)),
  getLogin: () => getLogin(null, dispatch),
});

@connect(mapStateToProps, mapDispatchToProps)
class App extends PureComponent {
  static propTypes = {
    auth: shape({
      loggedIn: bool,
    }),
    page: string,
    user: UserPropType,
    location: object,
    userAcceptedConsent: bool,
    getUserInfo: func,
    logoutUser: func,
    goTo: func,
    getLogin: func,
  };

  constructor(props) {
    super(props);
    this.validateUserData = this.validateUserData.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
  }

  timesRequested = 0;

  // This flow is counter-intuitive. Remove when WLS session is fixed.
  getUserInfo() {
    const { getUserInfo, logoutUser } = this.props;
    if (this.timesRequested < 2) {
      this.timesRequested += 1;
      getUserInfo().then(this.validateUserData).catch(this.getUserInfo);
    } else {
      // Server session crashed. Force user to perform login.
      this.timesRequested = 0;
      logoutUser({ error: new Error(errorMessages.server.id) });
    }
  }

  validateUserData({ user }) {
    if (!('group' in user) && !('name' in user) && user.acceptedConsent === false) {
      throw new Error('Invalid User Data received from server');
    }
  }

  componentDidMount() {
    // eslint-disable-next-line no-shadow
    const { auth: { loggedIn }, page, user, logoutUser, userAcceptedConsent, goTo, getLogin } = this.props;

    document.addEventListener(USER_LOGOUT_REQUEST, e => logoutUser(e.detail));
    if (process.env.NODE_ENV === 'production') {
      if (user.logoutStatus) {
        console.log('goto isam login page');
        // goTo('/itmdev');
      } else if (!user.sessionIndex && !user.logoutStatus) {
        this.getUserInfo();
        getLogin();
      } else {
        console.log('goto isam login page');
        // goTo('/');
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      //if (loggedIn) {
       if (true) {
        if (user.name && user.email && userAcceptedConsent !== true && page !== '/terms') {
          goTo('/terms');
        } else if ((!user.name || !user.email) && userAcceptedConsent != null) {
          // Force re-login in case server returns no user after logging in
          logoutUser({ error: new Error(errorMessages.session.id) });
        } else if (!user.name || !user.email) {
          this.getUserInfo();
          getLogin();
        } else {
          goTo('/');
        }
      } else if (page !== '/login') {
        goTo('/login');
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { auth: { loggedIn: wasLoggedIn }, user: { sessionIndex: wasSessionIndex, logoutStatus: wasLogoutStatus } } = prevProps;
    const { auth: { loggedIn: isLoggedIn }, user: { sessionIndex: isSessionIndex, logoutStatus: isLogoutStatus }, goTo, page } = this.props;

    if (process.env.NODE_ENV === 'production') {
      if (isLogoutStatus) {
        console.log('goto isam login page');
        // goTo('/itmdev');
        // window.location.href = '/itmdev';
      } else if (!wasSessionIndex && !isSessionIndex && (wasLogoutStatus === false || wasLogoutStatus === undefined) && isLogoutStatus === undefined) {
        this.getUserInfo();
      } else if (wasSessionIndex && !isSessionIndex) {
        console.log('goto isam login page');
        // goTo('/login');
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (isLoggedIn && !wasLoggedIn) {
        this.getUserInfo();
      } else if (!isLoggedIn && wasLoggedIn && page !== '/login') {
        goTo('/login');
      }
    }
  }

  render() {
    const { user, page, auth: { loggedIn } } = this.props;
    return (
      <Fragment>
        {/* <Loading active={ !user.name && page !== '/login' && page !== '/terms' } /> */}
        <Switch>
          {/* <Route path="/login" component={ AuthPage } /> */}
          <Route
            path="/login"
            render={ props => {
              if (process.env.NODE_ENV !== 'production') {
                return <AuthPage />;
              }
              return null;
            } }
          />
          
          <Route path="/terms" exact render={ props => <TermsPage { ...props } /> } />
          <Route
            path="/"
            render={ props => {
              // console.log('KeycloakService.getToken()', KeycloakService.getToken())
              // console.log('KeycloakService.isLoggedIn()', KeycloakService.isLoggedIn())
              if (KeycloakService.isLoggedIn()) {
                return <PageContainer { ...{props, user} } />;
              }else{
                KeycloakService.doLogin();
              }
              return null;
            } }
          />
        </Switch>
      </Fragment>
    );
  }
}

export default hot(App);
