export const PAGE_DATA_REQUEST = 'app/PageContainer/PAGE_DATA';
export const PAGE_CLEAR = 'app/PageContainer/PAGE_CLEAR';
export const PAGES_CLEAR = 'app/PageContainer/PAGES_CLEAR';
export const PAGE_UPDATE = 'app/PageContainer/PAGE_UPDATE';
export const PAGE_SETTINGS_REQUEST = 'app/PageContainer/PAGE_SETTINGS_REQUEST';
export const PAGE_SETTINGS_SAVE = 'app/PageContainer/PAGE_SETTINGS_SAVE';
export const PAGE_SETTINGS_UPDATE = 'app/PageContainer/PAGE_SETTINGS_UPDATE';
export const PAGE_SETTINGS_CLEAR = 'app/PageContainer/PAGE_SETTINGS_CLEAR';
export const PAGE_PARAMS_UPDATE = 'app/PageContainer/PAGE_PARAMS_UPDATE';
export const PAGE_PARAMS_CLEAR = 'app/PageContainer/PAGE_PARAMS_CLEAR';
export const PENDING_REGISTER_USERS = 'app/PageContainer/PENDING_REGISTER_USERS';
export const PENDING_REGISTER_USERS_SUCCESS = 'app/PageContainer/PENDING_REGISTER_USERS_SUCCESS';

export const API_PAGE_SETTINGS_PATH = '/api/user/pageSettings';
export const API_PENDING_REGISTER_USERS = '/api/register/countPendingUsers';
export const API_MONTHLY_REPORT_MAIL = '/api/report/generateMonthlyReportMail';