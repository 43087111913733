import { createAction } from 'redux-actions';
import { createFormAction } from 'redux-form-saga';
import { ALERT_OPTIONS_REQUEST } from 'Pages/AlertsPage/constants';
import {
  ALERT_OPTIONS_CLEAR,
  ALERT_MODAL_OPEN,
  ALERT_MODAL_CLOSE,
  COMPONENT_OPTIONS_REQUEST,
} from './constants';

export const alertOptionsRequest = createFormAction(ALERT_OPTIONS_REQUEST);
export const alertOptionsClear = createAction(ALERT_OPTIONS_CLEAR);
export const alertModalOpen = createAction(ALERT_MODAL_OPEN);
export const alertModalClose = createAction(ALERT_MODAL_CLOSE);
export const alertComponentOptionsRequest = createAction(COMPONENT_OPTIONS_REQUEST);
