import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.Alerts.title',
    defaultMessage: 'Alerts',
  },
  noActivities: {
    id: 'app.components.Alerts.noActivities',
    defaultMessage: 'No recent Activities available.',
  },
  noAlerts: {
    id: 'app.components.Alerts.noAlerts',
    defaultMessage: 'You have not setup any alert.',
  },
  tabs: {
    activity: {
      id: 'app.components.Alerts.tabs.activity',
      defaultMessage: 'Activities',
    },
    management: {
      id: 'app.components.Alerts.tabs.management',
      defaultMessage: 'Manage Alerts',
    },
    automatedActivities: {
      id: 'app.components.Alerts.tabs.automatedActivities',
      defaultMessage: 'Automated Activities',
    },
  },
  notifications: {
    delete: {
      success: {
        title: {
          id: 'app.components.Notifications.messages.Alerts.success.delete.title',
          defaultMessage: 'Delete Successful',
        },
        body: {
          id: 'app.components.Notifications.messages.Alerts.success.delete.body',
          defaultMessage: 'Alert has been successfully deleted',
        },
      },
      error: {
        title: {
          id: 'app.components.Notifications.messages.Alerts.errors.delete.title',
          defaultMessage: 'Delete Failed',
        },
        body: {
          id: 'app.components.Notifications.messages.Alerts.errors.delete.body',
          defaultMessage: 'There was an error delete alert. Please try again.',
        },
      },
    },
    toggleActive: {
      success: {
        title: {
          id: 'app.components.Notifications.messages.Alerts.success.toggleActive.title',
          defaultMessage: 'Alert Updated',
        },
        body: {
          set: {
            id: 'app.components.Notifications.messages.Alerts.success.toggleActive.body',
            defaultMessage: 'Activities for this alert have been enabled.',
          },
          remove: {
            id: 'app.components.Notifications.messages.Alerts.success.toggleActive.body',
            defaultMessage: 'Activities for this alert have been disabled.',
          },
        },
      },
      error: {
        title: {
          id: 'app.components.Notifications.messages.Alerts.errors.toggleActive.title',
          defaultMessage: 'Alert Update Failed',
        },
        body: {
          set: {
            id: 'app.components.Notifications.messages.Alerts.errors.toggleActive.body',
            defaultMessage: `There was an error enabling activities for this alert. Please try again or
            contact an administrator for assistance`,
          },
          remove: {
            id: 'app.components.Notifications.messages.Alerts.errors.toggleActive.body',
            defaultMessage: `There was an error disabling activities for this alert. Please try again or
            contact an administrator for assistance`,
          },
        },
      },
    },
    createAlert: {
      success: {
        title: {
          id: 'app.components.Notifications.messages.PageHeaderContainer.success.createAlert.title',
          defaultMessage: 'New Alert Created',
        },
        body: {
          id: 'app.components.Notifications.messages.PageHeaderContainer.success.createAlert.title',
          defaultMessage: `Alert for page { name } created successfully`
        },
      },
      error: {
        title: {
          id: 'app.components.Notifications.messages.PageHeaderContainer.error.createAlert.title',
          defaultMessage: 'Alert Failed',
        },
        body: {
          id: 'app.components.Notifications.messages.PageHeaderContainer.error.createAlert.title',
          defaultMessage: `Failed creating alert for page { name }. Please try again or contact an 
        administrator if the issue persists.`,
        },
      },
    },
    dataRequest: {
      error: {
        title: {
          id: 'app.components.Notifications.messages.Alerts.errors.dataRequest.title',
          defaultMessage: 'Alerts Failed',
        },
        body: {
          id: 'app.components.Notifications.messages.Alerts.errors.dataRequest.body',
          defaultMessage: 'There was an error fetching alerts for this page.',
        },
      },
    },
    alertOptionsRequest: {
      error: {
        body: {
          id: 'app.components.Notifications.messages.Alerts.errors.alertOptionsRequest.body',
          defaultMessage: 'There was an error fetching alert options for this component.',
        },
      },
    },
    markAsViewed: {
      error: {
        title: {
          id: 'app.components.Notifications.messages.Alerts.error.markAsViewed.title',
          defaultMessage: 'Alerts Failed',
        },
        body: {
          id: 'app.components.Notifications.messages.Alerts.error.markAsViewed.body',
          defaultMessage: 'There was an error marking your selected alerts. Please try again.',
        },
      },
    },
    timeout: {
      error: {
        title: {
          id: 'app.components.Notifications.messages.Alerts.error.timeout.title',
          defaultMessage: 'Session Timeout',
        },
        body: {
          id: 'app.components.Notifications.messages.Alerts.error.timeout.body',
          defaultMessage: 'Session Timeout, please relogin again.',
        },
      },
    },
  },
  alert: {
    id: 'app.components.Alerts.alert',
    defaultMessage: `Alert me when the { metric } for { target } { operator } by { value } in a 
    { dateRange } range.`,
  },
  activity: {
    id: 'app.components.Alerts.activity',
    defaultMessage: '{ metric } for { target } { operator } by { value } { dateRange }.',
  },
  toggleAlert: {
    id: 'app.components.Alerts.alert.toggle',
    defaultMessage: 'Toggle alert',
  },
});

export const modalMessages = defineMessages({
  title: {
    id: 'app.containers.Alerts.modal.title',
    defaultMessage: 'Delete Alert',
  },
  message: {
    id: 'app.containers.Alerts.modal.message',
    defaultMessage: `Are you sure you want to delete this alert? You won't be able to undo this
     action.`,
  },
});

export const dateRangeFieldMessages = defineMessages({
  dateRange: defineMessages({
    future: {
      MONTHLY: {
        id: 'app.components.Alerts.Metrics.dateRange.future.monthly',
        defaultMessage: 'monthly',
      },
      QUARTERLY: {
        id: 'app.components.Alerts.Metrics.dateRange.future.quarterly',
        defaultMessage: 'quarterly',
      },
      ROLLING3MONTHS: {
        id: 'app.components.Alerts.Metrics.dateRange.future.rolling3months',
        defaultMessage: 'rolling three months',
      },
    },
    past: {
      MONTHLY: {
        id: 'app.components.Alerts.Metrics.dateRange.past.monthly',
        defaultMessage: 'month{plural}',
      },
      QUARTERLY: {
        id: 'app.components.Alerts.Metrics.dateRange.past.quarterly',
        defaultMessage: 'quarter{plural}',
      },
      ROLLING3MONTHS: {
        id: 'app.components.Alerts.Metrics.dateRange.past.rolling3months',
        defaultMessage: 'rolling three months',
      },
    },
  }),
});

export const operatorFieldMessages = defineMessages({
  operator: defineMessages({
    future: {
      INCREASE: {
        id: 'app.components.Alerts.Metrics.operators.future.increases',
        defaultMessage: 'increases',
      },
      DECREASE: {
        id: 'app.components.Alerts.Metrics.operators.future.decreases',
        defaultMessage: 'decreases',
      },
    },
    past: {
      INCREASE: {
        id: 'app.components.Alerts.Metrics.operators.past.increased',
        defaultMessage: 'increased',
      },
      DECREASE: {
        id: 'app.components.Alerts.Metrics.operators.past.decreased',
        defaultMessage: 'decreased',
      },
    },
    INCREASE: {
      id: 'app.components.Alerts.Metrics.operators.INCREASE',
      defaultMessage: '{ metric } up by { value }',
    },
    DECREASE: {
      id: 'app.components.Alerts.Metrics.operators.DECREASE',
      defaultMessage: '{ metric } down by { value }',
    },
  }),
});

export const alertActionsMessages = defineMessages({
  markAllRead: {
    id: 'app.components.Alerts.actions.markAllRead',
    defaultMessage: 'Mark All as Read',
  },
  markRead: {
    id: 'app.components.Alerts.actions.markRead',
    defaultMessage: 'Mark as Read',
  },
  markUnread: {
    id: 'app.components.Alerts.actions.markUnread',
    defaultMessage: 'Mark as Unread',
  },
  markOpened: {
    id: 'app.components.Alerts.actions.markOpened',
    defaultMessage: 'Mark as Opened',
  },
});
