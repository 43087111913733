export const USER_REQUEST = 'app/UserContainer/USER_REQUEST';
export const USER_FAIL = 'app/UserContainer/USER_FAIL';
export const USER_LOGOUT_REQUEST = 'app/AuthPage/USER_LOGOUT_REQUEST';
export const APP_LOGOUT = 'app/data/APP_LOGOUT';
export const APP_LOGIN = 'app/data/APP_LOGIN';
export const APP_USER_REPORT = 'app/data/APP_USER_REPORT';

export const API_USER_INFO = '/api/user/getInfo';
export const API_USER_CONSENT = '/api/user/acceptConsent';
export const API_USER_PAGE_SETTINGS = '/api/user/pageSettings';
export const API_GET_LOGOUT = '/api/data/getLogout';
export const API_GET_LOGIN = '/api/data/getLogin';
export const API_GET_USERREPORT = '/api/data/getUserInfo';
