import { handleActions } from 'redux-actions';
import { focusRouteModalClose, focusRouteModalOpen } from './actions';

export const initialState = {
  open: false,
};

const focusRouteModalReducer = handleActions({
  [focusRouteModalOpen]: () => ({ open: true }),
  [focusRouteModalClose]: () => ({ open: false }),
}, initialState);

export default focusRouteModalReducer;
