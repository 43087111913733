import { handleActions } from 'redux-actions';
import { shareStateModalClose, shareStateModalOpen } from './actions';

const initialState = {
  open: false,
};

const shareStateModalReducer = handleActions({
  [shareStateModalOpen]: () => ({ open: true }),
  [shareStateModalClose]: () => ({ open: false }),
}, initialState);

export default shareStateModalReducer;
