import { createAction } from 'redux-actions';
import {
  REMOVE_HISTORY_NOTIFICATION,
  REMOVE_LATEST_NOTIFICATION,
  REMOVE_NOTIFICATION,
  SHOW_NOTIFICATION,
  CLEAR_CURRENT_NOTIFICATIONS,
  CLEAR_HISTORY_NOTIFICATIONS,
} from './constants';


const showNotification = createAction(SHOW_NOTIFICATION);
export const removeLatestNotification = createAction(REMOVE_LATEST_NOTIFICATION);
export const clearCurrentNotifications = createAction(CLEAR_CURRENT_NOTIFICATIONS);
export const removeNotification = createAction(REMOVE_NOTIFICATION);
export const clearHistoryNotifications = createAction(CLEAR_HISTORY_NOTIFICATIONS);
export const removeHistoryNotification = createAction(REMOVE_HISTORY_NOTIFICATION);

export default showNotification;
