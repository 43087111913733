import { createSelector } from 'reselect';
import updateObject from 'Utils/updateObject';

export const getUser = state => state.user;

export const makeSelectUser = createSelector(
  getUser,
  userState => {
    let shortName = '';
    if (userState.name) {
      const [firstLetter, lastLetter] = userState.name.split(' ');
      shortName = `${firstLetter.charAt(0).toUpperCase()}${lastLetter ? lastLetter.charAt(0).toUpperCase() : ''}`; 
    }
    return updateObject(userState, { shortName });
  }
);

export const makeSelectUserName = createSelector(
  getUser,
  userState => userState.name
);

export const makeSelectUserCnum = createSelector(
  getUser,
  userState => userState.cnum
);

export const makeSelectUserGroups = createSelector(
  getUser,
  userState => userState.group,
);

export const makeSelectUserEmail = createSelector(
  getUser,
  userState => userState.email,
);

export const makeSelectUserConsent = createSelector(
  getUser,
  userState => userState.acceptedConsent,
);
