import React, { PureComponent } from 'react';
import { element } from 'prop-types';
import { intlShape } from 'react-intl';

export default class LanguageProviderWrapper extends PureComponent {
  static propTypes = {
    children: element.isRequired,
  };

  static contextTypes = {
    intl: intlShape,
  };

  render() {
    const { children } = this.props;
    const { intl } = this.context;
    const formatMessageRef = intl.formatMessage;
    intl.formatMessage = (...rest) => {
      if (!rest.length) {
        return console.warn(`[React Intl] No message provided`);
      }
      const [msg = {}] = rest;
      if (!msg.id) {
        return console.warn(`[React Intl] Invalid message type. Please provide an object like { id, defaultMessage }`);
      }
      return formatMessageRef(...rest);
    };
    return React.Children.only(children);
  }
}
